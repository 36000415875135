import { Box, Button, Fade, Paper, Tooltip, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { ResponsivePie } from '@nivo/pie';
import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AppThemeContext } from '../../contexts/Providers';
import { styles } from '../../theme';
import { PageHeader } from '../common/AppLayout';
import { Appointment } from '../page-specific/scheduler/models';
import './gridLayout.scss';

const useStyles = makeStyles({
	mixBox: {
		'& div:not(:last-child)': {
			marginBottom: '2rem',
		},
	},
});

const BOX_WIDTH = 12;
const BOX_HEIGHT = 12;
const MAX_PER_ROW = 20;
const MAX_PER_WEEK = 75;

const patientToolTip = (appointment: Appointment) => {
	return (
		<div>
			<Typography variant="caption" display="block">
				{`Patient name: ${appointment.patient.firstname} ${appointment.patient.lastname}`}
			</Typography>
			<Typography variant="caption">
				Deadline: {appointment.deadline ? appointment.deadline : ''}
			</Typography>
		</div>
	);
};

const allocatedTooltip = () => {
	return (
		<div>
			<Typography variant="caption" display="block">
				Allokerad
			</Typography>
		</div>
	);
};

const getWeek = (dateInput: Date) => {
	let date = new Date(dateInput.getTime());
	date.setHours(0, 0, 0, 0);
	// Thursday in current week decides the year.
	date.setDate(date.getDate() + 3 - ((date.getDay() + 6) % 7));
	// January 4 is always in week 1.
	var week1 = new Date(date.getFullYear(), 0, 4);
	// Adjust to Thursday in week 1 and count number of weeks from date to week1.
	return (
		1 +
		Math.round(
			((date.getTime() - week1.getTime()) / 86400000 -
				3 +
				((week1.getDay() + 6) % 7)) /
				7
		)
	);
};

const NEXT_WEEK_NR = getWeek(
	new Date(new Date().setDate(new Date().getDate()))
);

const drawSVGDots = (
	appointments: Array<Appointment>,
	colorfunc: (priority: string) => string
) => {
	return (
		<svg
			width={MAX_PER_ROW * 15}
			height={Math.ceil(appointments.length / MAX_PER_ROW) * 15}
			style={{ padding: '0.25rem' }}
		>
			{appointments.map((item, aIndex) => (
				<Tooltip
					title={
						item.appointment_id === ''
							? allocatedTooltip()
							: patientToolTip(item)
					}
					key={aIndex}
				>
					<rect
						width={BOX_WIDTH}
						height={BOX_HEIGHT}
						x={(aIndex % MAX_PER_ROW) * 15}
						y={Math.floor(aIndex / MAX_PER_ROW) * 15}
						fill={colorfunc(item.priority)}
						fillOpacity={item.appointment_id === '' ? 0.3 : 1.0}
						stroke={
							item.appointment_id === '' ? colorfunc('EMERGENCY') : undefined
						}
					/>
				</Tooltip>
			))}
		</svg>
	);
};

const drawSVGContainer = (
	appointments: Array<Appointment>,
	color: string,
	label: string
) => {
	return (
		<div key={label} style={{ margin: '1rem' }}>
			<Typography variant="subtitle1">{`${label}, ${appointments.length} stycken`}</Typography>
			{drawSVGDots(appointments, () => color)}
		</div>
	);
};

const order = [
	{ color: 'red', priority: 'EMERGENCY' },
	{ color: 'orange', priority: 'DOUBLE_PRIORITY' },
	{ color: 'yellow', priority: 'SINGLE_PRIORITY' },
	{ color: 'green', priority: 'NO_PRIORITY' },
];

const priorityToNum = (priority: string) => {
	if (priority === 'EMERGENCY') return 0;
	else if (priority === 'DOUBLE_PRIORITY') return 1;
	else if (priority === 'SINGLE_PRIORITY') return 2;
	else if (priority === 'NO_ PRIORITY') return 3;
	else return 4;
};

const prioritySortOrder = (a: Appointment, b: Appointment) => {
	if (priorityToNum(a.priority) < priorityToNum(b.priority)) return -1;
	else if (priorityToNum(a.priority) > priorityToNum(b.priority)) return 1;
	else return 0;
};

const WEEK_EMERGENCY_DISTR = [8, 10, 11, 10, 8];
const reserved: Appointment = {
	appointment_id: '',
	patient: {
		patient_id: '',
		firstname: '',
		lastname: '',
		birth_day: new Date(2000, 1, 1),
		gender: 'F',
	},
	priority: 'EMERGENCY',
	room: {
		equipment: [],
		name: '',
		room_id: '',
	},
	examination: {
		duration_in_minutes: 60,
		id: '',
		name: '',
	},
};

type WeekData = Array<Array<Appointment>>;

const distribute = (appointments: Array<Appointment>) => {
	const getAccDistrLimit = (count: number) =>
		WEEK_EMERGENCY_DISTR.slice(0, count).reduce((s, n) => s + n, 0);
	const res = appointments
		.filter((item) => item.priority === 'EMERGENCY')
		.slice(0, getAccDistrLimit(WEEK_EMERGENCY_DISTR.length))
		.reduce<WeekData>(
			(acc, item, currentIndex) => {
				if (currentIndex < getAccDistrLimit(1)) acc[0].push(item);
				else if (currentIndex < getAccDistrLimit(2)) acc[1].push(item);
				else if (currentIndex < getAccDistrLimit(3)) acc[2].push(item);
				else if (currentIndex < getAccDistrLimit(4)) acc[3].push(item);
				else if (currentIndex < getAccDistrLimit(5)) acc[4].push(item);
				return acc;
			},
			[[], [], [], [], []]
		)
		.map((item, index) => {
			const iterations = WEEK_EMERGENCY_DISTR[index] - item.length;
			for (let j = 0; j < iterations; j++) {
				item.push(reserved);
			}
			return item;
		});

	// Done with emergency
	const nonEmergency = appointments.filter(
		(item) => item.priority !== 'EMERGENCY'
	);

	let ind = 0;
	const res2 = res.map((item) => {
		const itemsToPick = MAX_PER_WEEK - item.length;
		const row = item.concat(nonEmergency.slice(ind, ind + itemsToPick));
		ind += itemsToPick;
		return row;
	});

	return res2;
};

/**
 * @return {null}
 */
function Referral() {
	const [figureData, setFigureData] = useState<Array<Appointment>>([]);
	const [showPlanned, setShowPlanned] = useState(false);
	const [weekSelected, setWeekSelected] = useState(-1);
	const [weekData, setWeekData] = useState<WeekData>([]);

	const { palette } = useContext(AppThemeContext);
	const classes = useStyles();
	const { t } = useTranslation();

	useEffect(() => {
		axios
			.get<Array<Appointment>>('/referral-distribution?limit=500')
			.then((resp) => {
				const sortedResp = resp.data.sort((a, b) => {
					if (a.deadline && b.deadline && a.deadline < b.deadline) return -1;
					else if (a.deadline && b.deadline && a.deadline > b.deadline)
						return 1;
					else return 0;
				});
				setFigureData(sortedResp);
				setWeekData(distribute(sortedResp));
			});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const priorityColor = (priority: string) => {
		if (priority === 'EMERGENCY') {
			return palette.colors[4];
		} else if (priority === 'DOUBLE_PRIORITY') return palette.colors[3];
		else if (priority === 'SINGLE_PRIORITY') return palette.colors[2];
		else if (priority === 'NO_PRIORITY') return palette.colors[1];
		else return palette.colors[0];
	};

	const drawSVGMix = (appointments: Array<Appointment>, index: number) => {
		return (
			<Paper
				style={{ width: 'fit-content' }}
				elevation={index === weekSelected ? 5 : 1}
				key={index}
			>
				{drawSVGDots(appointments, priorityColor)}
				<Button size="small" onClick={() => setWeekSelected(index)}>{`${t(
					'week'
				)} ${NEXT_WEEK_NR + index}`}</Button>
			</Paper>
		);
	};

	const createGraphData = (appointments: Array<Appointment>) => {
		return appointments.reduce<Array<DistributionGraphDataPoint>>(
			(acc, appointment) => {
				const fIndex = acc.findIndex(
					(item) => item.id === appointment.priority
				);
				if (fIndex === -1)
					acc.push({
						id: appointment.priority,
						value: 1,
						label: appointment.priority,
						color: priorityColor(appointment.priority),
					});
				else acc[fIndex].value += 1;
				return acc;
			},
			[]
		);
	};

	if (figureData.length > 0 && weekData.length > 0) {
		return (
			<div style={styles.mainContent}>
				<PageHeader title={t('patient')} />
				<Paper
					style={{
						display: 'grid',
						gridTemplateColumns: 'auto auto auto',
						margin: '0.8rem',
						padding: '1rem',
					}}
				>
					<Typography variant="h6">Alla remisser</Typography>
					<Typography variant="h6">{showPlanned ? 'Planering' : ''}</Typography>
					<Typography variant="h6">
						{weekSelected > -1
							? `Fördelning ${t('week')} ${NEXT_WEEK_NR + weekSelected}`
							: ''}
					</Typography>
					<div>
						{order.map((item) =>
							drawSVGContainer(
								figureData.filter(
									(appointment) => appointment.priority === item.priority
								),
								priorityColor(item.priority),
								t(item.priority)
							)
						)}
						<Button
							variant="contained"
							sx={{ margin: '1rem' }}
							onClick={() => setShowPlanned(true)}
							disabled={showPlanned}
						>
							Planera
						</Button>
					</div>
					<Fade in={showPlanned} timeout={1400}>
						<Box style={{ marginTop: '2rem' }} className={classes.mixBox}>
							{weekData.map((wd, index) => drawSVGMix(wd, index))}
						</Box>
					</Fade>

					<Fade in={weekSelected > -1} timeout={1400}>
						<div>
							<div style={{ width: 300, height: 300, marginTop: '2rem' }}>
								{weekSelected > -1 && (
									<DistributionGraph
										data={createGraphData(weekData[weekSelected])}
									/>
								)}
							</div>
							{weekData.length > 0 && weekSelected > -1 && (
								<WeekdayDistribution
									appointments={weekData[weekSelected]}
									dayDistr={[10, 13, 9, 11, 7]}
									colorFunc={priorityColor}
									emergencies={WEEK_EMERGENCY_DISTR[weekSelected]}
								/>
							)}
						</div>
					</Fade>
				</Paper>
			</div>
		);
	} else return <></>;
}

type DistributionGraphDataPoint = {
	id: string;
	label: string;
	value: number;
	color: string;
};

function DistributionGraph(props: { data: Array<DistributionGraphDataPoint> }) {
	const { t } = useTranslation();
	return (
		<ResponsivePie
			data={props.data}
			colors={{ datum: 'data.color' }}
			margin={{ top: 10, right: 10, bottom: 10, left: 10 }}
			innerRadius={0.5}
			padAngle={0.7}
			cornerRadius={3}
			activeOuterRadiusOffset={8}
			borderWidth={1}
			borderColor={{ from: 'color', modifiers: [['darker', 0.2]] }}
			enableArcLinkLabels={false}
			tooltip={(d) => {
				return (
					<Box
						style={{
							backgroundColor: 'rgba(0,0,0,0.85)',
							display: 'flex',
							padding: '0.5rem',
							alignItems: 'center',
						}}
					>
						<div
							style={{
								width: BOX_WIDTH,
								height: BOX_HEIGHT,
								backgroundColor: d.datum.color,
								marginRight: '0.25rem',
							}}
						/>
						<Typography variant="caption" sx={{ color: '#fff' }}>
							{`${t(d.datum.label.toString())} - ${d.datum.value}`}
						</Typography>
					</Box>
				);
			}}
		/>
	);
}

type WeekdayDistributionProps = {
	appointments: Array<Appointment>;
	dayDistr: Array<number>;
	colorFunc: (priority: string) => string;
	emergencies: number;
};

function WeekdayDistribution(props: WeekdayDistributionProps) {
	const weekDays = ['Måndag', 'Tisdag', 'Onsdag', 'Torsdag', 'Fredag'];
	const nonEmergency = props.appointments.filter(
		(item) => item.appointment_id !== ''
	);

	// Accumulate appointment distribute over the week
	// THis is used to determine which indices from appoint to be drawn from each day
	const accDistr = props.dayDistr.map((_, index) => {
		return props.dayDistr.slice(0, index + 1).reduce((s, n) => s + n, 0);
	});

	// Evenly spread out allocations, if not evently distributed, monday and friday will take more
	const minEmPerDay = Math.floor(props.emergencies / weekDays.length);
	const leftOver = props.emergencies % weekDays.length;
	const firstDay = Math.ceil(leftOver / 2);
	const lastDay = Math.floor(leftOver / 2);

	return (
		<div style={{ display: 'grid', gridTemplateColumns: 'fit-content auto' }}>
			{weekDays.map((weekDay, index) => {
				const start = index === 0 ? 0 : accDistr[index - 1];
				const end = accDistr[index];
				let perDay = minEmPerDay;
				if (index === 0) perDay += firstDay;
				if (index === weekDays.length - 1) perDay += lastDay;
				const nonAllocatedAppointments = nonEmergency.slice(start, end);
				const numEmergencies = nonAllocatedAppointments.reduce(
					(s, a) => (a.priority === 'EMERGENCY' ? s + 1 : s),
					0
				);
				// reduce allocated emergencies for actual emergencies.
				const allocatedAppointments = Array(
					Math.max(0, perDay - numEmergencies)
				).fill(reserved);
				return (
					<React.Fragment key={index}>
						<Typography key={index} variant="caption">
							<strong>{weekDay}</strong>
						</Typography>
						{drawSVGDots(
							allocatedAppointments
								.concat(nonEmergency.slice(start, end))
								.sort(prioritySortOrder),
							props.colorFunc
						)}
					</React.Fragment>
				);
			})}
		</div>
	);
}

export default Referral;
