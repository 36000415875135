export const APPOINTMENTCAUSE_QUERY = `
  ftv_appointmentcause {
    id
    name
  }
`;

export const PATIENTAGEGROUP_QUERY = `
  ftv_patientagegroup {
    patientagegroupid
    lowerlimit
    upperlimit
  }
`;

export const PAYMENTCLASS_QUERY = `
  ftv_paymentclass {
    id
    name
  }
`;

export const CLINIC_QUERY = `
  ftv_clinic(where: {region: {_in: ["Ost", "Vast"]}}) {
    id
    name
    region
  }
`;

export const VISITS_QUERY = `
  query visitsQuery($start: date, $end: date, $clinics: [Int!], $agegroups: [Int!], $paymentclasses: [Int!], $appointmentcauses: [Int!]) {
    ftv_get_plannedvisit(args: {end_in: $end, start_in: $start}, where: {agegroupid: {_in: $agegroups}, appointmentcause_id: {_in: $appointmentcauses}, clinic_id: {_in: $clinics}, paymentclass_id: {_in: $paymentclasses}}) {
      clinic_id
      agegroupid
      count
    }
  }
`;
