import { ResponsiveBar } from '@nivo/bar';
import { graphTheme } from '../../common/GraphGlobalSettings';
import chroma from 'chroma-js';

/**
 * Pie chart with tooltip
 * @param {Array} data array of data used for drawing graph
 * @param {Array} palette array of color
 */
// export const PieChart = (props: {data: Array<any>, palette: Array<string>}) => {
// 	const {data, palette} = props;
// 	return (
// 	<ResponsivePie
// 		data={data}
// 		margin={{ top: 30, right: 30, bottom: 80, left: 30 }}
// 		innerRadius={0.25}
// 		padAngle={0.7}
// 		cornerRadius={3}
// 		colors={palette}
// 		borderWidth={1}
// 		borderColor={{ from: 'color', modifiers: [['darker', 2]] }}
// 		// radialLabelsSkipAngle={5}
// 		// radialLabelsTextXOffset={2}
// 		// radialLabelsTextColor="#333333"
// 		// radialLabelsLinkOffset={0}
// 		// radialLabelsLinkDiagonalLength={16}
// 		// radialLabelsLinkHorizontalLength={0}
// 		// radialLabelsLinkStrokeWidth={1}
// 		// radialLabelsLinkColor={{ from: 'color' }}
// 		// radialLabel={(d) => `${d.label} ${d.value.toFixed(1)}`}
// 		// slicesLabelsSkipAngle={15}
// 		// slicesLabelsTextColor="#333333"
// 		// enableSlicesLabels={false}
// 		animate={true}
// 		// motionStiffness={90}
// 		// motionDamping={15}
// 		legends={[
// 			{
// 				anchor: 'bottom',
// 				direction: 'row',
// 				translateY: 56,
// 				itemWidth: 80,
// 				itemHeight: 18,
// 				itemTextColor: '#999',
// 				symbolSize: 18,
// 				symbolShape: 'circle',
// 				effects: [
// 					{
// 						on: 'hover',
// 						style: {
// 							itemTextColor: '#000',
// 						},
// 					},
// 				],
// 			},
// 		]}
// 	/>
// 	)
// };

/**
 * Sorted bar-chart with tooltip
 * @param {Array} data array of data used for drawing
 * @param {Array} keys array of keys
 * @param {Array} palette array of colors
 * @param {String} selectedClinic
 */
// export const RankingChart = ({ data, keys, palette, selectedClinic }) => (
// 	<ResponsiveBar
// 		data={data.map((item) => {
// 			return { ...item, value: item.value * 100.0 };
// 		})}
// 		keys={keys}
// 		indexBy="clinic"
// 		margin={{ top: 20, right: 10, bottom: 20, left: 80 }}
// 		padding={0.3}
// 		layout="horizontal"
// 		enableGridY={false}
// 		borderColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
// 		axisTop={null}
// 		axisRight={null}
// 		axisBottom={null}
// 		labelFormat={(d) => `${d.toFixed(1)}%`}
// 		animate={true}
// 		motionStiffness={90}
// 		motionDamping={15}
// 		colors={(col) =>
// 			selectedClinic === col.indexValue ? palette[1] : palette[0]
// 		}
// 		labelTextColor={(d) =>
// 			chroma.contrast(d.color, '#000') > 4.5 ? '#000' : '#fff'
// 		}
// 	/>
// );

interface StaffPersonGraphProps {
	data: Array<any>;
	keys: Array<string>;
	indexBy: string;
	palette: Array<string>;
}

export const StaffPersonGraph = (props: StaffPersonGraphProps) => {
	const { data, keys, indexBy, palette } = props;
	return (
		<ResponsiveBar
			data={data}
			keys={keys}
			indexBy={indexBy}
			margin={{ top: 30, right: 150, bottom: 100, left: 100 }}
			padding={0.3}
			borderColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
			axisBottom={{
				tickSize: 5,
				tickPadding: 10,
				tickRotation: 45,
			}}
			axisLeft={{
				tickSize: 5,
				tickPadding: 10,
				tickRotation: 0,
				tickValues: 5,
				legend: 'arbetstid i timmar',
				legendOffset: -80,
				legendPosition: 'middle',
			}}
			labelSkipWidth={12}
			labelSkipHeight={12}
			enableLabel={false}
			labelTextColor={(data) =>
				chroma.contrast(data.color, '#000') <= 4.5 ? '#fff' : '#000'
			}
			legends={[
				{
					dataFrom: 'keys',
					anchor: 'bottom-right',
					direction: 'column',
					justify: false,
					translateX: 150,
					translateY: 0,
					itemsSpacing: 2,
					itemWidth: 150,
					itemHeight: 20,
					itemDirection: 'left-to-right',
					itemOpacity: 0.85,
					symbolSize: 15,
					effects: [
						{
							on: 'hover',
							style: {
								itemOpacity: 1,
							},
						},
					],
				},
			]}
			animate={true}
			theme={graphTheme}
			colors={palette}
		/>
	);
};
