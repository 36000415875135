import { useState, useEffect, useContext } from 'react';

import {
	Paper,
	Card,
	CardContent,
	CardHeader,
	FormControl,
	RadioGroup,
	FormControlLabel,
	Radio,
	Fade,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

import './gridLayout.scss';
import {
	PageHeader,
	FormLabel,
	GraphContainer,
	HelpText,
} from '../common/AppLayout';
import {
	PatientRiskGraph,
	ChordGraph,
	SankeyGraph,
} from '../page-specific/patient-risk/PatientRiskGraph';
import { styles } from '../../theme';
import { ClinicSelector, ClinicSelectorProps } from '../common/ClinicSelector';
import { DateSelector } from '../common/DateRangeSelector';
import {
	AppThemeContext,
	ClinicInformationContext,
} from '../../contexts/Providers';
import { ftvRiskPalette } from '../common/GraphGlobalSettings';

const data = [
	{
		clinic: 'Asarum',
		typeA: 399,
		typeB: 430,
		typeC: 289,
		// typeD: 89
	},
	{
		clinic: 'Bräkne-Hoby',
		typeA: 401,
		typeB: 511,
		typeC: 223,
		// typeD: 59
	},
	{
		clinic: 'Karlshamn',
		typeA: 801,
		typeB: 1233,
		typeC: 913,
		// typeD: 340
	},
	{
		clinic: 'Ronneby',
		typeA: 301,
		typeB: 532,
		typeC: 244,
		// typeD: 104
	},
	{
		clinic: 'Sölvesborg',
		typeA: 709,
		typeB: 991,
		typeC: 911,
		// typeD: 401
	},
	{
		clinic: 'Jämjö',
		typeA: 639,
		typeB: 849,
		typeC: 441,
		// typeD: 499
	},
	{
		clinic: 'Karlskrona',
		typeA: 830,
		typeB: 1201,
		typeC: 1100,
		// typeD: 900
	},
	{
		clinic: 'Lyckeby',
		typeA: 511,
		typeB: 749,
		typeC: 631,
		// typeD: 198
	},
	{
		clinic: 'Nättraby',
		typeA: 439,
		typeB: 749,
		typeC: 341,
		// typeD: 104
	},
	{
		clinic: 'Olofström',
		typeA: 539,
		typeB: 949,
		typeC: 741,
		// typeD: 381
	},
	{
		clinic: 'Rödeby',
		typeA: 439,
		typeB: 749,
		typeC: 341,
		// typeD: 104
	},
].sort((a, b) => {
	if (a.clinic < b.clinic) return -1;
	else return 1;
});

const keysSwe = ['riskgrupp0', 'riskgrupp1', 'riskgrupp2'];

function PatientRisk() {
	const clinics = useContext(ClinicInformationContext);
	const [graphData, setGraphData] = useState<Array<any>>([]);
	const [selectedClinics, setSelectedClinics] = useState<Array<any>>(clinics);
	const [date, setDate] = useState(new Date());
	const [ageGroup, setAgeGroup] = useState('adult');
	const { t } = useTranslation();
	const { palette } = useContext(AppThemeContext);

	useEffect(() => {
		const tmpData: Array<any> = [];
		selectedClinics.forEach((item) => {
			const index = data
				.map((dataItem: any) => dataItem.clinic)
				.indexOf(item.label);
			if (index !== -1) tmpData.push(data[index]);
		});

		// translate typeA -> typA
		setGraphData(
			tmpData
				.map((item: any) => {
					return {
						clinic: item.clinic,
						riskgrupp0: item.typeA,
						riskgrupp1: item.typeB,
						riskgrupp2: item.typeC,
					};
				})
				.sort((a, b) => (a.clinic < b.clinic ? -1 : 1))
		);
	}, [selectedClinics]);

	if (clinics && clinics.length) {
		// clinic selector props
		const clinicProps: ClinicSelectorProps = {
			options: clinics,
			updateSelection: setSelectedClinics,
			placeholder: t('select clinics'),
			value: selectedClinics,
		};

		/**
		 * Set state variables for patient age-group radio group
		 * @param {object} event
		 */
		const handleRadio = (event: any) => {
			setAgeGroup(event.target.value);
		};

		const mainGraphProps = {
			id: 'graph',
			height: '33rem',
			title: selectedClinics.length ? 'Riskgruppsfördelning' : '',
		};

		const riskGraphPalette =
			palette.paletteName === 'sownder'
				? [
						palette.colors[0],
						palette.colors[1],
						palette.colors[3],
						palette.colors[4],
				  ]
				: ftvRiskPalette;

		return (
			<div style={styles.mainContent}>
				<PageHeader title={t('risk-groups')} />

				<div id="gridColSplit">
					<div id="mainGrid">
						<GraphContainer {...mainGraphProps}>
							{selectedClinics.length ? (
								<PatientRiskGraph
									data={graphData}
									palette={riskGraphPalette}
									keys={keysSwe}
								/>
							) : (
								HelpText('Ingen klinik vald')
							)}
						</GraphContainer>

						<Fade in={selectedClinics.length ? true : false} timeout={1000}>
							<Paper style={{ height: 300 }}>
								{selectedClinics.length ? (
									<ChordGraph palette={riskGraphPalette} keys={keysSwe} />
								) : null}
							</Paper>
						</Fade>

						<Fade in={selectedClinics.length ? true : false} timeout={1000}>
							<Paper style={{ height: 300 }}>
								{selectedClinics.length ? (
									<SankeyGraph palette={riskGraphPalette} />
								) : null}
							</Paper>
						</Fade>
					</div>
					<div id="settings">
						<Card>
							<CardHeader title={t('settings')} />
							<CardContent style={{ overflow: 'visible' }}>
								<FormControl>
									<FormLabel required>{t('date')}</FormLabel>
									<DateSelector date={date} onChange={setDate} />

									<FormLabel required>{t('age-groups')}</FormLabel>
									<RadioGroup
										name="ageGroup"
										row
										onChange={handleRadio}
										value={ageGroup}
									>
										<FormControlLabel
											value="children"
											control={<Radio color="primary" />}
											label="Barn"
											labelPlacement="end"
										/>
										<FormControlLabel
											value="adult"
											control={<Radio color="primary" />}
											label="Vuxna"
											labelPlacement="end"
										/>
									</RadioGroup>

									<FormLabel required>{t('clinics')}</FormLabel>
									<ClinicSelector {...clinicProps} />
								</FormControl>
							</CardContent>
						</Card>
					</div>
				</div>
			</div>
		);
	} else return null;
}

export default PatientRisk;
