import { ResponsiveBar } from '@nivo/bar';
import { ResponsivePie } from '@nivo/pie';
import chroma from 'chroma-js';

import { graphTheme } from '../../common/GraphGlobalSettings';

interface SVTChartProps {
	data: Array<any>;
	keys: Array<string>;
	palette: Array<string>;
	cb?: (data: any) => void;
	indexBy: string;
}

export const STVChart = (props: SVTChartProps) => {
	const { data, keys, palette, cb, indexBy } = props;
	return (
		<ResponsiveBar
			data={data}
			keys={keys}
			indexBy={indexBy}
			margin={{ top: 50, right: 150, bottom: 150, left: 100 }}
			padding={0.3}
			colors={palette}
			borderColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
			axisTop={null}
			axisRight={null}
			axisBottom={{
				tickSize: 5,
				tickPadding: 5,
				tickRotation: 45,
				legendPosition: 'middle',
				legendOffset: 32,
			}}
			axisLeft={{
				tickSize: 5,
				tickPadding: 5,
				tickRotation: 0,
				legend: 'Antal timmar',
				legendPosition: 'middle',
				legendOffset: -60,
			}}
			labelSkipWidth={12}
			labelSkipHeight={12}
			labelTextColor={(d) => {
				return chroma.contrast(d.color, '#ffffff') < 4.5 ? '#000' : '#fff';
			}}
			legends={[
				{
					dataFrom: 'keys',
					anchor: 'bottom-right',
					direction: 'column',
					justify: false,
					translateX: 120,
					translateY: 0,
					itemsSpacing: 2,
					itemWidth: 100,
					itemHeight: 20,
					itemDirection: 'left-to-right',
					itemOpacity: 0.85,
					symbolSize: 20,
					effects: [
						{
							on: 'hover',
							style: {
								itemOpacity: 1,
							},
						},
					],
				},
			]}
			animate={true}
			theme={graphTheme}
			onClick={(data) => cb && cb(data)}
		/>
	);
};

interface SVTPieProps {
	data: Array<any>;
	palette: Array<any>;
}

export const STVPie = (props: SVTPieProps) => {
	const { data, palette } = props;
	return (
		<ResponsivePie
			data={data}
			margin={{ top: 40, right: 80, bottom: 80, left: 80 }}
			innerRadius={0.5}
			padAngle={0.7}
			cornerRadius={3}
			colors={palette}
			borderWidth={1}
			borderColor={{ from: 'color', modifiers: [['darker', 0.2]] }}
			animate={true}
		/>
	);
};
