import { ClinicData } from './PatientFrisktandvardGraph';

export const clinicsGroup: Array<ClinicData> = [
	{
		clinic: 'Asarum',
		A1: 94,
		A2: 81,
		A3: 53,
		A4: 33,
		A5: 56,
		A6: 50,
		A7: 65,
		A8: 43,
		A9: 10,
		A10: 12,
		sum: 0,
	},
	{
		clinic: 'Bräkne-Hoby',
		A1: 55,
		A2: 32,
		A3: 23,
		A4: 23,
		A5: 29,
		A6: 32,
		A7: 10,
		A8: 8,
		A9: 6,
		A10: 10,
		sum: 0,
	},
	{
		clinic: 'Karlshamn',
		A1: 655,
		A2: 432,
		A3: 323,
		A4: 223,
		A5: 219,
		A6: 312,
		A7: 190,
		A8: 83,
		A9: 16,
		A10: 101,
		sum: 0,
	},
	{
		clinic: 'Ronneby',
		A1: 123,
		A2: 132,
		A3: 80,
		A4: 33,
		A5: 88,
		A6: 67,
		A7: 92,
		A8: 73,
		A9: 19,
		A10: 52,
		sum: 0,
	},
	{
		clinic: 'Sölvesborg',
		A1: 321,
		A2: 221,
		A3: 84,
		A4: 128,
		A5: 81,
		A6: 89,
		A7: 101,
		A8: 18,
		A9: 67,
		A10: 90,
		sum: 0,
	},
	{
		clinic: 'Jämjö',
		A1: 51,
		A2: 52,
		A3: 76,
		A4: 34,
		A5: 87,
		A6: 64,
		A7: 54,
		A8: 71,
		A9: 50,
		A10: 109,
		sum: 0,
	},
	{
		clinic: 'Karlskrona',
		A1: 403,
		A2: 319,
		A3: 245,
		A4: 195,
		A5: 85,
		A6: 101,
		A7: 81,
		A8: 54,
		A9: 55,
		A10: 23,
		sum: 0,
	},
	{
		clinic: 'Lyckeby',
		A1: 89,
		A2: 75,
		A3: 55,
		A4: 36,
		A5: 31,
		A6: 56,
		A7: 79,
		A8: 32,
		A9: 41,
		A10: 19,
		sum: 0,
	},
	{
		clinic: 'Nättraby',
		A1: 121,
		A2: 152,
		A3: 120,
		A4: 86,
		A5: 45,
		A6: 34,
		A7: 30,
		A8: 28,
		A9: 25,
		A10: 21,
		sum: 0,
	},
	{
		clinic: 'Olofström',
		A1: 281,
		A2: 301,
		A3: 251,
		A4: 198,
		A5: 89,
		A6: 75,
		A7: 65,
		A8: 89,
		A9: 101,
		A10: 89,
		sum: 0,
	},
	{
		clinic: 'Rödeby',
		A1: 102,
		A2: 91,
		A3: 81,
		A4: 85,
		A5: 89,
		A6: 51,
		A7: 41,
		A8: 35,
		A9: 31,
		A10: 40,
		sum: 0,
	},
];
