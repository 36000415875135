import { useContext, useState } from 'react';
import {
	Dialog,
	DialogTitle,
	DialogContent,
	DialogContentText,
	Typography,
	Radio,
	DialogActions,
	Button,
	Box,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import {
	AppThemeContext,
	allPalettes,
	PaletteName,
} from '../../contexts/Providers';

const ColorBox = styled(Box)(() => ({
	width: '2rem',
	height: '2rem',
}));

interface ThemeDialogProps {
	open: boolean;
	closeWindow: () => void;
}

const str2PaletteName = (str: string) => {
	if (str === 'sownder') return 'sownder';
	else return 'ftv';
};

const TITLE = ['FTV', 'Sownder'];

export default function ThemeDialog(props: ThemeDialogProps) {
	const themeContext = useContext(AppThemeContext);
	const palette = themeContext.palette;

	const [userSelection, setUserSelection] = useState<PaletteName>(
		palette.paletteName
	);

	const updateChoice = () => {
		if (userSelection !== palette.paletteName) {
			themeContext.update && themeContext.update(userSelection);
			props.closeWindow();
		}
	};

	return (
		<Dialog open={props.open}>
			<DialogTitle>Theme selection</DialogTitle>
			<DialogContent>
				<DialogContentText>Välj tema nedan.</DialogContentText>
				<div
					style={{
						marginTop: '1rem',
						display: 'grid',
						gap: '1rem',
						gridTemplateColumns: 'auto auto auto',
					}}
				>
					{TITLE.map((text) => (
						<Typography
							key={`title-${text}`}
							sx={{ gridColumnStart: 1 }}
							variant="subtitle2"
						>
							{text}
						</Typography>
					))}
					{allPalettes.map((pal, index) => (
						<div
							key={`palette-row-${index}`}
							style={{
								display: 'flex',
								gridColumnStart: 2,
								gridRowStart: index + 1,
							}}
						>
							{pal.colors.map((color) => (
								<ColorBox key={color} sx={{ backgroundColor: color }} />
							))}
						</div>
					))}

					{TITLE.map((str) => str.toLowerCase()).map((item, index) => (
						<div
							style={{
								gridColumnStart: 3,
								display: 'flex',
								alignItems: 'center',
								gridRowStart: index + 1,
							}}
							key={`${item}-${index}`}
						>
							<Radio
								checked={item === userSelection}
								value={item}
								onChange={() => setUserSelection(str2PaletteName(item))}
							/>
							<Typography variant="subtitle2">Välj</Typography>
						</div>
					))}
				</div>
			</DialogContent>
			<DialogActions>
				<Button
					variant="contained"
					size="small"
					color="secondary"
					onClick={() => props.closeWindow()}
				>
					Avbryt
				</Button>
				<Button
					variant="contained"
					size="small"
					disabled={userSelection === palette.paletteName}
					onClick={() => updateChoice()}
				>
					Ok
				</Button>
			</DialogActions>
		</Dialog>
	);
}
