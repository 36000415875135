import { gql } from 'graphql-tag';

export const INCOME_AGGREG_QUERY = gql`
	query IncomeAggregView($name: [String!]) {
		ftv_incomeaggregview(where: { name: { _in: $name } }) {
			adults
			avgincome
			monthduration
			monthincome
			name
			profession
		}
	}
`;

export const INCOME_SINGLECLINIC_QUERY = gql`
	query SingleClinicQuery($name: String!) {
		adult: ftv_incomeview(
			where: { name: { _eq: $name }, adults: { _eq: true } }
		) {
			abbrev
			adults
			avgincome
			clinicid
			monthduration
			monthincome
			name
			profession
		}
		children: ftv_incomeview(
			where: { name: { _eq: $name }, adults: { _eq: false } }
		) {
			abbrev
			adults
			avgincome
			clinicid
			monthduration
			monthincome
			name
			profession
		}
	}
`;

export const SCHEDULE_TYPES_QUERY = gql`
	query schedule_types {
		ftv_scheduletype {
			scheduletext
			schedulemaintype
		}
	}
`;

export const SCHEDULEWORK_QUERY = gql`
	query schedulework($clinics: [String!], $month: Int!, $year: Int!) {
		ftv_workschedulebyclinicview(
			where: {
				month: { _eq: $month }
				clinicname: { _in: $clinics }
				year: { _eq: $year }
			}
		) {
			clinicname
			duration
			month
			profession
			scheduletext
			year
		}
	}
`;

export const SCHEDULEWORK_SINGLE_CLINIC_QUERY = gql`
	query scheduleworksingleclinic($clinic: String!, $month: Int!, $year: Int!) {
		ftv_workscheduleview(
			where: {
				clinicname: { _eq: $clinic }
				year: { _eq: $year }
				month: { _eq: $month }
			}
		) {
			clinicid
			clinicname
			duration
			fullname
			month
			profession
			region
			scheduletext
			staffabbrev
			year
		}
		names: ftv_workscheduleview(
			where: {
				clinicname: { _eq: $clinic }
				year: { _eq: $year }
				month: { _eq: $month }
			}
			distinct_on: staffabbrev
		) {
			staffabbrev
		}
	}
`;

export const STV_CLINICS_QUERY = gql`
	query STVClinics {
		ftv_clinic(where: { region: { _eq: "Ovriga" } }) {
			region
			name
			id
		}
	}
`;

export const STVVIEW_QUERY = gql`
	query stvview($month: Int!, $clinics: [numeric!]) {
		ftv_stvview(where: { month: { _eq: $month }, klinik: { _in: $clinics } }) {
			administration
			barn
			chefsadm
			handledning
			klinik
			klinikutveckling
			kons
			kursgivning
			month
			clinic: name
			patienttid
			samverkan
			utbildning
			utv
			vuxna
			year
		}
	}
`;

export const StaffQuery = gql`
	query StaffQuery($year: Int!, $month: Int!, $clinic: numeric!) {
		ftv_stv(
			where: {
				year: { _eq: $year }
				month: { _eq: $month }
				klinik: { _eq: $clinic }
			}
		) {
			adm
			barn
			chefsadm
			handledning
			klinik
			klinikutveckling
			kons
			kursgivning
			month
			namn
			patienttid
			samverkan
			utb
			utv
			vuxna
			year
		}
	}
`;
