import { ChangeEvent, useEffect, useState } from 'react';
import Select, { StylesConfig } from 'react-select';
import { Checkbox } from '@mui/material';
import chroma from 'chroma-js';
import { baseColors } from '../../theme';
import { AgeRangeSelection, ClinicOption } from './types/Page';
import { useTranslation } from 'react-i18next';

const eastClinicBg = chroma(baseColors.main).alpha(0.25).hex();
const westClinicBg = chroma(baseColors.darkBlue).alpha(0.25).hex();
const eastClinicTextColor = baseColors.main;
const westClinicTextColor = baseColors.darkBlue;

/**
 * Sets the label color, label background and option colors from React multi seleect
 * Only styles clinic names based on regions: `Vast` and `Ost`
 */

const colorStyles: StylesConfig<ClinicOption, true> = {
	option: (styles, data) => {
		return {
			...styles,
			color: data.data.region
				? data.data.region === 'Vast'
					? westClinicTextColor
					: eastClinicTextColor
				: '#000',
		};
	},
	multiValue: (styles, data) => ({
		...styles,
		backgroundColor: data.data.region
			? data.data.region === 'Vast'
				? westClinicBg
				: eastClinicBg
			: '#E6E6E6',
	}),
	multiValueLabel: (styles, data) => ({
		...styles,
		color:
			data.data.region === 'Vast' ? westClinicTextColor : eastClinicTextColor,
		':hover': {
			color: '#000',
		},
	}),
	multiValueRemove: (styles, data) => ({
		...styles,
		color:
			data.data.region === 'Vast' ? westClinicTextColor : eastClinicTextColor,
		':hover': {
			backgroundColor:
				data.data.region === 'Vast' ? westClinicTextColor : eastClinicTextColor,
			color: '#fff',
		},
	}),
};

/**
 * Sorts by region first West before East. If equal then sort alphabetically by clinicName (label)
 * returns int
 */
const clinicSortOrder = (a: ClinicOption, b: ClinicOption) => {
	if (a.region < b.region) return 1;
	else if (a.region > b.region) return -1;
	else {
		return a.label < b.label ? -1 : 1;
	}
};

/**
 * Sorts two Clinic objects based on label
 * @param {Object} a Clinic object
 * @param {Object} b Another Clinic object
 * returns Object
 */
export const labelSort = (a: AgeRangeSelection, b: AgeRangeSelection) =>
	a.label < b.label ? -1 : 1;

export interface ClinicSelectorProps {
	value: Array<ClinicOption>;
	options: Array<ClinicOption>;
	updateSelection: (sel: Array<ClinicOption>) => void;
	closeMenuOnSelect?: boolean;
	placeholder: string;
}

/**
 * ClinicSelector
 * User selectable list of clinics with 2 checkboxes. Checkboxes are for filtering and visual markers for clinic-regions
 * @param {Array} : value, selection list
 * @param {Array} : default, initial (list) values for the selector
 * @param {Array} : options, all selectable options
 * @param {function} : updateSelection, a callback function
 */
export function ClinicSelector(props: ClinicSelectorProps) {
	const { t } = useTranslation();

	const [state, setState] = useState<{
		allWestSelected: boolean;
		allEastSelected: boolean;
		currentSelection: Array<ClinicOption>;
	}>({
		allWestSelected: false,
		allEastSelected: false,
		currentSelection: props.value,
	});

	/**
	 * A helper function that counts distribution of "Vast" and "Ost" clinic regions
	 * @param {Array} selection List of clinics
	 */
	const countWestAndWest = (
		selection: Array<ClinicOption>
	): { numWest: number; numEast: number; other: number } => {
		const tmp = { numWest: 0, numEast: 0, other: 0 };
		selection.forEach((item: ClinicOption) => {
			if (item.region === 'Vast') tmp.numWest += 1;
			else if (item.region === 'Ost') tmp.numEast += 1;
			else tmp.other += 1;
		});
		return tmp;
	};

	useEffect(() => {
		// super(props);
		const selection = props.value ? props.value.sort(clinicSortOrder) : [];
		const allWestClinics = props.options
			.filter((item) => item.region === 'Vast')
			.sort(clinicSortOrder);
		const allEastClinics = props.options
			.filter((item) => item.region === 'Ost')
			.sort(clinicSortOrder);
		const numWest = allWestClinics.length;
		const numEast = allEastClinics.length;

		const tmp = countWestAndWest(selection);

		setState({
			currentSelection: selection,
			allWestSelected:
				tmp.numWest === numWest &&
				(tmp.numEast === 0 || tmp.numEast === numEast),
			allEastSelected:
				tmp.numEast === numEast &&
				(tmp.numWest === 0 || tmp.numWest === numWest),
		});
	}, [props.options, props.value]);

	const handleWestClick = (event: ChangeEvent<HTMLInputElement>) => {
		let newSelection: Array<ClinicOption> = [];
		if (event.target.checked === true) {
			newSelection = state.allEastSelected
				? props.options
				: props.options
						.filter((item) => item.region === 'Vast')
						.sort(clinicSortOrder);
			setState({
				...state,
				allWestSelected: true,
				currentSelection: newSelection,
			});
		} else {
			newSelection = state.currentSelection.filter(
				(item) => item.region !== 'Vast'
			);
			setState({
				...state,
				allWestSelected: false,
				currentSelection: newSelection,
			});
		}
		props.updateSelection(newSelection); // callback-function
	};

	const handleEastClick = (event: ChangeEvent<HTMLInputElement>) => {
		let newSelection = [];
		if (event.target.checked === true) {
			newSelection = state.allWestSelected
				? props.options
				: props.options.filter((item) => item.region === 'Ost');
			setState({
				...state,
				allEastSelected: true,
				currentSelection: newSelection,
			});
		} else {
			newSelection = state.currentSelection.filter(
				(item) => item.region !== 'Ost'
			);
			setState({
				...state,
				allEastSelected: false,
				currentSelection: newSelection,
			});
		}
		props.updateSelection(newSelection); // callback function
	};

	// Changes selection
	const changeSelection = (opts: Array<ClinicOption>) => {
		const tmp = opts ? countWestAndWest(opts) : { numWest: 0, numEast: 0 };
		const { numWest, numEast } = tmp;

		setState({
			...state,
			allWestSelected:
				tmp.numWest === numWest &&
				(tmp.numEast === 0 || tmp.numEast === numEast),
			allEastSelected:
				tmp.numEast === numEast &&
				(tmp.numWest === 0 || tmp.numWest === numWest),
			currentSelection: opts ? opts.sort(clinicSortOrder) : [],
		});
		props.updateSelection(opts ? opts : []);
	};

	return (
		<div>
			<Checkbox
				color="primary"
				onChange={handleWestClick}
				checked={state.allWestSelected}
				value="AllWest"
			/>
			Väst
			<Checkbox
				color="primary"
				onChange={handleEastClick}
				checked={state.allEastSelected}
				value="AllEast"
			/>
			Öst
			<Select<ClinicOption, true>
				isMulti
				value={state.currentSelection}
				options={props.options ? props.options.sort(clinicSortOrder) : []}
				onChange={(newValue) =>
					changeSelection(
						newValue.map((item) => ({
							value: item.value,
							id: item.id,
							region: item.region,
							label: item.label,
						}))
					)
				}
				styles={colorStyles}
				closeMenuOnSelect={
					props.closeMenuOnSelect ? props.closeMenuOnSelect : false
				}
				placeholder={props.placeholder}
				noOptionsMessage={() => t('no options')}
				menuPortalTarget={document.querySelector('body')}
			/>
		</div>
	);
}
