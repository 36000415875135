import { CircularProgress, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

const Spinner = () => {
	const { t } = useTranslation();
	return (
		<div style={{ textAlign: 'center', marginTop: '1rem' }}>
			<CircularProgress size="5rem" />
			<Typography variant="subtitle2">{t('loading')} ...</Typography>
		</div>
	);
};

export default Spinner;
