const swedishTranslation = {
	// Spinner
	loading: 'Laddar',
	// // Sidebar
	patient: 'Patient',
	patients: 'Patienter',
	visits: 'Besök',
	'queue-patients': 'Köpatienter',
	recall: 'Revision',
	'measure-statistics': 'Åtgärdsstatistik',
	epidemiology: 'Epidemiologi',
	'dental-healthcare': 'Frisktandvård',
	'dental-healthcare-contract': 'Frisktandvårdsavtal',
	poll: 'Enkät',
	'risk-groups': 'Riskgrupper',

	staff: 'Personal',
	'time worked': 'Arbetad tid',
	'time worked clinic-based': 'Arbetad tid klinikvis',
	'schedulable care-time': 'Bokningsbar vårdtid',
	'performed care-time': 'Utförd vårdtid',
	administration: 'Administration',
	meeting: 'Möte',
	'therapy meeting': 'Terapi möte',
	income: 'Intäkt',

	'ATV-work': 'ATV Arbetad tid',
	'ATV-work2': 'ATV Arbetad tid tdl/hyg',

	'STV-work': 'STV Arbetad tid',

	economy: 'Ekonomi',
	'production revenue': 'Produktionsintäkt',
	'treatment revenue': 'Behandlingsintäkt',

	planning: 'Planering',
	scheduler: 'Tidbok',
	simulation: 'Simulering',
	settings: 'Inställningar',

	'log out': 'Logga ut',

	// UserInfo
	name: 'Namn',

	// // Page Specific
	// Patients
	'patient-view': 'Patientvy',
	'patients per clinic': 'Patienter per klinik',

	// Patient Visit
	'patient-visit': 'Patientbesök',

	// Queue-patients
	'queue-patient': 'Köpatienter',
	'region average': 'Regionsnitt',
	'queue size': 'Köstorlek',
	'queue length': 'Kötid',

	// Staff-view
	'staff-view': 'Personalvy',
	'choose occupation': 'Välj yrkesgrupp',
	adults: 'vuxna',
	bookable: 'bookningsbar',
	chefadmin: 'chefsadministration',
	children: 'barn',
	clinicdevelopment: 'klinikutveckling',
	education: 'utbildning',
	misc: 'övrigt',
	patienttime: 'patienttid',
	supervisetime: 'handledningstid',
	therapymeeting: 'terapimöte',
	workedtime: 'arbetad tid',

	'patient-survey': 'Patientenkät',

	// Economy-view
	'economy-view': 'Ekonomivy',

	// Settings-view
	'settings-view': 'Inställningsvy',

	// // Generic components
	// Graph
	graph: 'Graf',
	'number-of-patients': 'antal patienter',
	sum: 'Summa',

	// Settings
	regions: 'Regioner',
	'select regions': 'Välj regioner',
	clinics: 'Kliniker',
	'select clinics': 'Välj kliniker',
	'select clinic': 'Välj klinik',
	'age-groups': 'Åldersgrupper',
	'select age-groups': 'Välj åldersgrupper',
	'causes for call': 'Kallelseorsaker',
	'select causes for call': 'Välj kallelseorsaker',
	'charging-classes': 'Debiteringsklasser',
	'select charging-classes': 'Välj debiteringsklasser',
	west: 'Väst',
	east: 'Öst',
	'add age-group': 'Lägg till åldersgrupp',

	// Trend
	trend: 'Trend',

	// CurrentNumber
	'current number': 'Tillfälligt antal',

	// CurrentDistribtuion
	'current distribution': 'Tillfällig fördelning',

	// RadioButtonsGroup
	'graph style': 'Grafstil',
	cumulative: 'Kumulativ',
	comparative: 'Komparativ',

	// DateRangeSelector
	'date-interval': 'Datumintervall',
	date: 'Datum',
	start: 'Start',
	end: 'Slut',

	// MultiSelectField
	'no options': 'Inga val',

	// AgerangeDialog.js
	from: 'Från',
	to: 'Till',
	cancel: 'Avbryt',
	add: 'Lägg till',
	unlimited: 'Obegränsat',

	// NivoCharts.js
	clinic: 'Klinik',
	total: 'Totalt',

	// PageError
	'oops!': 'Oj!',
	error: 'Error',
	'the requested page does not exist': 'Den begärda sidan finns inte',
	'something went wrong': 'Något gick fel',
	'internal server error': 'Internt serverfel',
	'go back': 'Gå tillbaka',
	'start page': 'Startsida',
	apollo401: 'Åtkomsttoken har löpt ut',
	apollo404: 'Kunde inte ansluta till apollo servern',
	apollo500: 'Internt apollo server error',

	// simulation
	postop: 'PostOP simulering',
	'waiting list': 'Väntelista',
	'surgery program': 'Operationsprogram',
	'load graph': 'Belastningsgraf',
	'patient flow graph': 'Patientflödesgraf',
	gynecology: 'Gynekologi',
	urology: 'Urologi',
	surgery: 'Kirurgi',
	orthopedics: 'Ortopedi',
	maintenance: 'Uppehåll',
	'operating room': 'Operationssal',
	minutes: 'Minuter',
	'minutes end': 'Minuter, slutar',
	'number of beds': 'Antal sängplatser',
	frequency: 'Antal',
	time: 'Tid',
	'max load': 'Maximal belastning',
	below: 'Under',

	// referal
	referral: 'Remiss',
	referrals: 'Remisser',

	// examination
	examination: 'Undersökning',
	examinations: 'Undersökningar',

	// referral
	EMERGENCY: 'Akut',
	DOUBLE_PRIORITY: 'Dubbel prioriterad',
	SINGLE_PRIORITY: 'Enkel prioriterad',
	NO_PRIORITY: 'Ingen prioritet',
	week: 'Vecka',

	// scheduler queue
	'overview referrals': 'Översikt remisser',

	'incoming referrals': 'Tillkomna remisser',
	'unscheduled referrals': 'Icke-schemalagda remisser',
	'waiting time': 'Väntetid',

	'care guarantee 90 days': 'Vårdgaranti 90 dagar',

	'number of referrals': 'Antal remisser',
	'number of days': 'Antal dagar',

	Combined: 'Kombinerad',
	'No Priority': 'Ingen prioritet',
	'Single Priority': 'Enkel prioritet',
	'Double Priority': 'Dubbel prioritet',
	Emergency: 'Akut',
};

export default swedishTranslation;
