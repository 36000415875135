import { ResponsiveBar } from '@nivo/bar';
import { ResponsiveLine, Serie } from '@nivo/line';

import { graphTheme, tooltipTheme } from '../../common/GraphGlobalSettings';
import chroma from 'chroma-js';
import { BarChartData } from '../patient-number/PatientNumberGraph';

interface PatientProcedureGraphProps {
	data: Array<BarChartData>;
	keys: Array<string>;
	palette: Array<string>;
	isRelative: boolean;
	cb: (data: any) => void;
}

export function PatientProcedureGraph(props: PatientProcedureGraphProps) {
	const tooltip = (data: any) => {
		return (
			<div style={tooltipTheme}>
				<div>{data.indexValue}</div>
				<div>Åtgärdsserie {data.id}</div>
				<div>
					Antal {props.isRelative ? `${data.value.toFixed(2)}%` : data.value}
				</div>
			</div>
		);
	};

	return (
		<ResponsiveBar
			data={props.data}
			keys={props.keys}
			indexBy="clinic"
			margin={{ top: 10, right: 130, bottom: 80, left: 100 }}
			padding={0.3}
			groupMode="stacked"
			colors={props.palette}
			borderColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
			axisBottom={{
				tickSize: 5,
				tickPadding: 5,
				tickRotation: 45,
			}}
			// maxValue={props.isRelative ? 100 : 'auto'}
			axisLeft={{
				tickSize: 5,
				tickPadding: 0,
				tickRotation: 0,
				legend: !props.isRelative
					? 'Riskgruppsfördelning i antal'
					: 'Riskgrupppsfördelning i procent',
				legendPosition: 'middle',
				legendOffset: -60,
				format: (data) => (props.isRelative ? `${data}%` : data),
			}}
			label={(d) =>
				props.isRelative ? `${Number(d.value).toFixed(2)}%` : d.formattedValue
			}
			labelSkipWidth={24}
			labelSkipHeight={24}
			labelTextColor={(d) => {
				return chroma.contrast(d.color, '#ffffff') < 4.5 ? '#000' : '#fff';
			}}
			legends={[
				{
					dataFrom: 'keys',
					anchor: 'bottom-right',
					direction: 'column',
					justify: false,
					translateX: 120,
					translateY: 0,
					itemsSpacing: 2,
					itemWidth: 100,
					itemHeight: 20,
					itemDirection: 'left-to-right',
					itemOpacity: 0.85,
					symbolSize: 20,
					effects: [
						{
							on: 'hover',
							style: {
								itemOpacity: 1,
							},
						},
					],
				},
			]}
			animate={true}
			tooltip={tooltip}
			theme={graphTheme}
			onClick={(data) => props.cb(data)}
		/>
	);
}

interface TreatmentTimelineChartProps {
	data: Array<Serie>;
	palette: Array<string>;
	dateRange: { start: Date; end: Date };
}

/**
 * Displays a linechart diagram
 * @param {Array} data for Nivo chart
 */
export const TreatmentTimelineChart = (props: TreatmentTimelineChartProps) => {
	const { data, palette, dateRange } = props;
	const numberOfDays =
		(dateRange.end.getTime() - dateRange.start.getTime()) / (1000 * 3600 * 24); // number of days

	const tooltip = (data: any) => {
		// todo: rename data
		return (
			<div style={tooltipTheme}>
				<div>{data.point.data.xFormatted}</div>
				<div>Åtgärd {data.point.serieId}</div>
				<div>Antal {data.point.data.y}</div>
			</div>
		);
	};

	return (
		<ResponsiveLine
			data={data}
			margin={{ top: 10, right: 110, bottom: 50, left: 60 }}
			xFormat="time:%Y-%m-%d"
			xScale={{
				type: 'time',
				format: '%Y-%m-%d',
				precision: 'day',
			}}
			yScale={{ type: 'linear', stacked: false, min: 'auto', max: 'auto' }}
			axisBottom={{
				format: '%b %d',
				tickValues: numberOfDays < 95 ? 'every week' : 'every month',
				legend: 'Tid',
				legendOffset: -12,
			}}
			axisLeft={{
				tickSize: 5,
				tickPadding: 5,
				tickRotation: 0,
				legend: 'Antal',
				legendOffset: -40,
				legendPosition: 'middle',
			}}
			colors={palette}
			pointSize={10}
			pointColor={{ theme: 'background' }}
			pointBorderWidth={2}
			pointBorderColor={{ from: 'serieColor' }}
			pointLabel="y"
			pointLabelYOffset={-12}
			useMesh={true}
			legends={[
				{
					anchor: 'bottom-right',
					direction: 'column',
					justify: false,
					translateX: 100,
					translateY: 0,
					itemsSpacing: 0,
					itemDirection: 'left-to-right',
					itemWidth: 80,
					itemHeight: 20,
					itemOpacity: 0.75,
					symbolSize: 12,
					symbolShape: 'circle',
					symbolBorderColor: 'rgba(0, 0, 0, .5)',
					effects: [
						{
							on: 'hover',
							style: {
								itemBackground: 'rgba(0, 0, 0, .03)',
								itemOpacity: 1,
							},
						},
					],
				},
			]}
			theme={graphTheme}
			tooltip={(data) => tooltip(data)}
		/>
	);
};
