import { useState, useEffect, useContext } from 'react';
import {
	Card,
	CardContent,
	CardHeader,
	FormControl,
	Fade,
	TextField,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';
import { DatePicker } from '@mui/lab';
import './gridLayout.scss';
import {
	PageHeader,
	FormLabel,
	GraphContainer,
	HelpText,
} from '../common/AppLayout';
import StaffWorkGraph from '../page-specific/staff-work/StaffWorkGraph';
import { StaffPersonGraph } from '../page-specific/staff-work/Charts';
import { styles } from '../../theme';
import { useLazyQuery, useQuery } from '@apollo/client';
import { ClinicSelector } from '../common/ClinicSelector';
import {
	AppThemeContext,
	ClinicInformationContext,
} from '../../contexts/Providers';
import { ScheduleQueryResponse } from '../common/types/Staff';
import {
	SCHEDULEWORK_QUERY,
	SCHEDULEWORK_SINGLE_CLINIC_QUERY,
	SCHEDULE_TYPES_QUERY,
} from '../queries/StaffQueries';
import {
	BaseOption,
	BaseOptionType,
	BaseOptionGroup,
} from '../common/types/Page';

function StaffWork() {
	const { t } = useTranslation();
	const clinics = useContext(ClinicInformationContext);
	const { palette } = useContext(AppThemeContext);

	const [scheduleGroups, setScheduleGroups] = useState<Array<BaseOptionGroup>>(
		[]
	);
	const [selectedDate, handleDateChange] = useState(new Date());
	const [data, setData] = useState<{ tdl: Array<any>; hyg: Array<any> }>({
		tdl: [],
		hyg: [],
	});
	const [singleClinicData, setSingleClinicData] = useState<Array<any>>([]);
	const [selectedClinics, setSelectedClinics] = useState(clinics);
	const [selectedScheduleOptions, setSelectedScheduleOptions] = useState<
		Array<BaseOption>
	>([]);
	const [selectSingleClinic, setSelectedSingleClinic] = useState<
		string | undefined
	>();

	// Loads all schedule types
	useQuery(SCHEDULE_TYPES_QUERY, {
		onCompleted: (data) => {
			const tmp: Array<BaseOptionType> = data.ftv_scheduletype
				.map((item: ScheduleQueryResponse) => {
					return {
						value: item.scheduletext,
						label: item.scheduletext,
						type: item.schedulemaintype,
					};
				})
				.filter(
					(item: BaseOptionType) =>
						item.value !== 'arbetad tid' && item.value !== 'patienttid'
				);

			const groups: Array<BaseOptionGroup> = [
				{
					label: 'bokningsbar vårdtid',
					options: tmp.filter((item) => item.type === 'bokningsbar vårdtid'),
				},
				{
					label: 'övrig tid',
					options: tmp.filter((item) => item.type === 'övrig tid'),
				},
			];
			setScheduleGroups(groups);
			setSelectedScheduleOptions(groups.map((group) => group.options).flat()); // Select everything by default
		},
	});

	// Fetches main data
	const [getScheduleWork] = useLazyQuery(SCHEDULEWORK_QUERY, {
		onCompleted: (data) => {
			const tmpTdl: Array<any> = [];
			const tmpHyg: Array<any> = [];
			selectedClinics.forEach((clinic: any) => {
				const clinicName = clinic.label;
				const tmpTdlObj: { [key: string]: any } = { clinic: clinicName };
				const tmpHygObj: { [key: string]: any } = { clinic: clinicName };
				const splitStage = data.ftv_workschedulebyclinicview.filter(
					(item: any) => item.clinicname === clinicName
				);

				splitStage
					.filter((item: any) => item.profession === 'tdl')
					.forEach((item: any) => {
						tmpTdlObj[item.scheduletext] = item.duration;
					});
				let tdlPSum = 0; // partial sum for tdl
				Object.entries(tmpTdlObj).forEach(([key, value]) => {
					if (
						key !== 'arbetad tid' &&
						key !== 'clinic' &&
						key !== 'patienttid'
					) {
						tdlPSum += value;
					}
				});
				tmpTdlObj['sum'] = tdlPSum;
				tmpTdl.push(tmpTdlObj);

				splitStage
					.filter((item: any) => item.profession === 'hyg')
					.forEach((item: any) => {
						tmpHygObj[item.scheduletext] = item.duration;
					});
				let hygPSum = 0; // partial sum for hyg
				Object.entries(tmpHygObj).forEach(([key, value]) => {
					if (key !== 'arbetad tid' && key !== 'clinic' && key !== 'patienttid')
						hygPSum += value;
				});
				tmpHygObj['sum'] = hygPSum;
				tmpHyg.push(tmpHygObj);
			});
			setData({ tdl: tmpTdl, hyg: tmpHyg });
		},
		fetchPolicy: 'network-only',
	});

	const [getSingleClinic] = useLazyQuery(SCHEDULEWORK_SINGLE_CLINIC_QUERY, {
		onCompleted: (data) => {
			const tmp: Array<any> = [];
			data.names.forEach((name: any) => {
				const staffAbbrev = name.staffabbrev;
				const tmpObj: { [key: string]: any } = { staffabbrev: staffAbbrev };
				data.ftv_workscheduleview
					.filter((item: any) => item.staffabbrev === staffAbbrev)
					.forEach((item: any) => {
						tmpObj[item.scheduletext] = item.duration;
						tmpObj['fullname'] = item.fullname;
					});
				tmp.push(tmpObj);
			});
			setSingleClinicData(tmp);
		},
		fetchPolicy: 'network-only',
	});

	useEffect(() => {
		if (
			selectedClinics &&
			selectedClinics.length &&
			selectedScheduleOptions &&
			selectedScheduleOptions.length
		) {
			const params = {
				variables: {
					clinics: selectedClinics.map((item: any) => item.value),
					year: selectedDate ? selectedDate.getUTCFullYear() : null,
					month: selectedDate ? selectedDate.getUTCMonth() + 1 : 1,
				},
			};
			getScheduleWork(params);
		}
	}, [selectedClinics, selectedDate, selectedScheduleOptions, getScheduleWork]);

	const handleClickBar = (data: any) => {
		getSingleClinic({
			variables: {
				clinic: data,
				year: selectedDate ? selectedDate.getUTCFullYear() : 2019,
				month: selectedDate ? selectedDate.getUTCMonth() + 1 : 1,
			},
		});
		setSelectedSingleClinic(data);
	};

	if (scheduleGroups.length && clinics.length) {
		const necessarySelections =
			selectedClinics &&
			selectedClinics.length > 0 &&
			selectedScheduleOptions &&
			selectedScheduleOptions.length > 0;

		return (
			<div style={styles.mainContent}>
				<PageHeader title="Bokningsbar vårdtid" />

				<div id="gridColSplit">
					<div id="mainGrid">
						<GraphContainer id="graph" height={400}>
							{necessarySelections &&
							data &&
							data.tdl &&
							data.tdl.length > 0 ? (
								<StaffWorkGraph
									data={data.tdl}
									keys={selectedScheduleOptions.map((item) => item.label)}
									palette={palette.colors}
									cb={handleClickBar}
								/>
							) : (
								HelpText('Ingen klinik och tidsart vald')
							)}
						</GraphContainer>

						<Fade in={necessarySelections && selectSingleClinic !== undefined}>
							<GraphContainer
								id={'graph'}
								height={500}
								title={selectSingleClinic ? selectSingleClinic : undefined}
								subheader={selectSingleClinic ? 'Alla tidsarter' : undefined}
							>
								{necessarySelections &&
								selectedClinics !== null &&
								singleClinicData ? (
									<StaffPersonGraph
										data={singleClinicData}
										indexBy={'staffabbrev'}
										keys={scheduleGroups[0].options
											.concat(scheduleGroups[1].options)
											.map((group) => group.value)}
										palette={palette.colors}
									/>
								) : (
									<></>
								)}
							</GraphContainer>
						</Fade>
					</div>

					<div id="settings">
						<Card>
							<CardHeader title={t('settings')} />
							<CardContent>
								<FormControl>
									<FormLabel required>{'Välj månad och år'}</FormLabel>
									<DatePicker
										openTo="year"
										views={['year', 'month']}
										label="År och månad"
										value={selectedDate}
										onChange={(date) =>
											handleDateChange(date === null ? new Date() : date)
										}
										renderInput={(params) => <TextField {...params} />}
									/>

									<FormLabel required>{t('clinics')}</FormLabel>
									<ClinicSelector
										options={clinics}
										value={selectedClinics}
										updateSelection={setSelectedClinics}
										placeholder={t('select clinics')}
									/>

									<FormLabel required>Tidsart</FormLabel>
									<Select
										isMulti={true}
										closeMenuOnSelect={false}
										options={scheduleGroups}
										value={selectedScheduleOptions}
										onChange={(opts: any) => setSelectedScheduleOptions(opts)}
										placeholder={'Välj tidsart'}
										noOptionsMessage={() => t('no options')}
										menuPortalTarget={document.querySelector('body')}
									/>
								</FormControl>
							</CardContent>
						</Card>
					</div>
				</div>
			</div>
		);
	}
	return null;
}

export default StaffWork;
