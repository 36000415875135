import { ResponsiveBar } from '@nivo/bar';
import { altColors as palette } from '../../../theme';

const data = [
	{ date: 'Mån', A: 8141, B: 7101 },
	{ date: 'Tis', A: 7381, B: 491 },
	{ date: 'Ons', A: 5799, B: 5811 },
	{ date: 'Tor', A: 6924, B: 8409 },
	{ date: 'Fre', A: 7500, B: 5864 },
].map((item) => {
	return { ...item, B: item.A * 0.68 };
});

export default function KPPGraph() {
	return (
		<ResponsiveBar
			data={data}
			keys={['A', 'B']}
			indexBy="date"
			margin={{ top: 50, right: 60, bottom: 50, left: 60 }}
			padding={0.3}
			groupMode="grouped"
			// valueScale={{ type: 'linear' }}
			// indexScale={{ type: 'band', round: true }}
			colors={[palette.yellow.css(), palette.red.css()]}
			// borderColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
			axisBottom={{
				tickSize: 5,
				tickPadding: 5,
				tickRotation: 0,
				legend: 'v. 44',
				legendPosition: 'middle',
				legendOffset: 32,
			}}
			axisLeft={{
				tickSize: 5,
				tickPadding: 5,
				tickRotation: 0,
				legend: 'Krona per patient',
				legendPosition: 'middle',
				legendOffset: -40,
			}}
			enableLabel={false}
			// labelSkipWidth={12}
			// labelSkipHeight={12}
			// labelTextColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
			legends={[
				{
					dataFrom: 'keys',
					anchor: 'bottom-right',
					direction: 'column',
					justify: false,
					translateX: 120,
					translateY: 0,
					itemsSpacing: 2,
					itemWidth: 100,
					itemHeight: 20,
					itemDirection: 'left-to-right',
					itemOpacity: 0.85,
					symbolSize: 20,
					effects: [
						{
							on: 'hover',
							style: {
								itemOpacity: 1,
							},
						},
					],
				},
			]}
		/>
	);
}
