import { baseColors } from '../../theme';
/**
 * Constants used for various graphs
 */

export const nivoColors = [
	'#E8C1A0',
	'#F47560',
	'#F1E15B',
	'#E8A838',
	'#61CDBB',
	'#97E3D5',
];

export const ftvRiskPalette = [
	baseColors.lightBlue,
	baseColors.lightGreen,
	baseColors.orange,
	baseColors.red,
];

/**
 * Font sizes for Nivo charts
 */
export const graphTheme = {
	fontSize: 16,
	legends: {
		text: {
			fontSize: 14,
		},
	},
	labels: {
		text: {
			fontSize: 14,
		},
	},
	axis: {
		legend: {
			text: {
				fontStyle: 'italic',
				fontSize: 14,
			},
		},
	},
	tooltip: {
		container: {
			padding: 0,
			margin: 0,
		},
	},
};

// Tooltip theme
export const tooltipTheme = {
	color: '#fff',
	padding: '0.3rem',
	backgroundColor: '#333',
};
