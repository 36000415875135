import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {
	Collapse,
	IconButton,
	LinearProgress,
	Typography,
} from '@mui/material';
import {
	TableContainer,
	Table,
	TableHead,
	TableBody,
	TableRow,
	TableCell,
	Paper,
	Button,
} from '@mui/material';
import {
	KeyboardArrowUp,
	KeyboardArrowDown,
	PriorityHigh,
} from '@mui/icons-material';
import { Appointment } from '../page-specific/scheduler/models';
import { Timeline } from '../page-specific/scheduler-activities/Timeline';
import { styles } from '../../theme';
import { PageHeader } from '../common/AppLayout';
import '../page-specific/scheduler-activities/SchedulerActivities.scss';

const dateStr = (d: Date, dispTime: boolean = false): string => {
	const date: string = `${d.getFullYear()}-${(d.getMonth() + 1)
		.toString()
		.padStart(2, '0')}-${d.getDate().toString().padStart(2, '0')}`;
	if (dispTime)
		return `${date} ${d.getHours().toString().padStart(2, '0')}:${d
			.getMinutes()
			.toString()
			.padStart(2, '0')}`;
	else return date;
};

const priority_str = (pr_str: string) => {
	let color = '#000';
	let str: string = 'Ej prioriterad';
	let show = false;

	switch (pr_str) {
		case 'DOUBLE_PRIORITY':
			str = 'Dubbelt prioriterad';
			color = 'orange';
			show = true;
			break;
		case 'SINGLE_PRIORITY':
			str = 'Enkel prioriterad';
			color = 'yellow';
			show = true;
			break;
		case 'EMERGENCY':
			str = 'Akut';
			color = 'red';
			show = true;
			break;
	}
	return (
		<React.Fragment>
			<PriorityHigh sx={{ opacity: show ? 1 : 0 }} htmlColor={color} />
			{str}
		</React.Fragment>
	);
};

interface RowProps {
	appointment: Appointment;
	index: number;
}

const now = new Date();

function Scheduler() {
	const suggest = new Date(new Date().getTime() + 1000 * 3600 * 24 * 15);
	const hour = Math.floor(Math.random() * (17 - 7 + 1) + 7);
	return (
		<div style={{ width: '100%' }}>
			<Typography variant="h6" component="div" style={{ display: 'block' }}>
				Schemaläggning
			</Typography>

			<Typography variant="body2">
				Nästa lediga tid är {dateStr(suggest)} kl{' '}
				{`${hour.toString().padStart(2, '0')}:00`}
			</Typography>
			<Typography variant="body2">Utförare: Anna, Stina, Kalle</Typography>
			<Typography variant="body2">Rum: Rum 1</Typography>
			<div style={{ gap: '0.5rem', display: 'flex', margin: '1rem auto' }}>
				<Button variant="contained">Boka</Button>
				<Button variant="contained">Manuell bokning</Button>
			</div>
		</div>
	);
}

function Row(props: RowProps) {
	const { appointment, index } = props;
	const [open, setOpen] = useState<boolean>(false);

	return (
		<React.Fragment>
			<TableRow
				key={`s-${index}`}
				className="t-row"
				onClick={() => setOpen(!open)}
			>
				<TableCell padding="none">
					<IconButton size="small" onClick={() => setOpen(!open)}>
						{open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
					</IconButton>
				</TableCell>
				<TableCell component="th" scope="row">
					{appointment.appointment_id}
				</TableCell>
				<TableCell>{priority_str(appointment.priority)}</TableCell>
				<TableCell>{appointment.patient.patient_id}</TableCell>
				<TableCell>{appointment.examination.name}</TableCell>
				<TableCell>
					{appointment.queue_entered &&
						dateStr(appointment.queue_entered, true)}
				</TableCell>
				<TableCell>
					{appointment.schedule ? (
						dateStr(appointment.schedule, true)
					) : (
						<em>Ej Schemalagd</em>
					)}
				</TableCell>
				<TableCell>
					{appointment.deadline && dateStr(appointment.deadline)}
				</TableCell>
			</TableRow>
			<TableRow key={`col-${index}`}>
				<TableCell colSpan={8} padding="none">
					<Collapse in={open} timeout="auto">
						<div className="row-info">
							<div>
								<Typography variant="h6" component="div">
									Patient
								</Typography>
								<Typography variant="body2">
									id: {appointment.patient.patient_id}
								</Typography>
								<Typography variant="body2">
									namn:{' '}
									{`${appointment.patient.firstname} ${appointment.patient.lastname}`}
								</Typography>
								<Typography variant="body2">
									födelsedatum: {dateStr(appointment.patient.birth_day)}
								</Typography>
								<Typography variant="body2">
									ålder:{' '}
									{new Date(
										now.getTime() - appointment.patient.birth_day.getTime()
									).getUTCFullYear() - 1970}
								</Typography>
							</div>

							<div>
								<Typography variant="h6" component="div">
									Undersökning
								</Typography>
								<Typography variant="body2">
									id: {appointment.examination.id}
								</Typography>
								<Typography variant="body2">
									Namn: {appointment.examination.name}
								</Typography>
								<Typography variant="body2">
									Tidsåtgång: {appointment.examination.duration_in_minutes}{' '}
									minuter
								</Typography>
							</div>

							<div>
								<Typography variant="h6" component="div">
									Rum
								</Typography>
								<Typography variant="body2">
									Rumsnummer: {appointment.room.room_id}
								</Typography>
								<Typography variant="body2">
									Namn: {appointment.room.name}
								</Typography>
								<Typography variant="body2">
									Utrustning: {appointment.room.equipment}
								</Typography>
							</div>

							<div
								style={{
									gridColumn: '1/-1',
									display: 'flex',
									justifyContent: 'center',
								}}
							>
								{appointment.queue_entered &&
								appointment.schedule &&
								appointment.deadline ? (
									<Timeline
										now={new Date()}
										scheduled={appointment.schedule}
										created={appointment.queue_entered}
										deadline={appointment.deadline}
									/>
								) : (
									<Scheduler />
								)}
							</div>
						</div>
					</Collapse>
				</TableCell>
			</TableRow>
		</React.Fragment>
	);
}

type ITableData = {
	activities: Array<Appointment>;
	nonScheduled: Array<Appointment>;
	emergency: Array<Appointment>;
	double: Array<Appointment>;
	single: Array<Appointment>;
	no: Array<Appointment>;
};

export default function SchedulerActivities() {
	const [tableData, setTableData] = useState<ITableData>({
		activities: [],
		nonScheduled: [],
		emergency: [],
		double: [],
		single: [],
		no: [],
	});
	const [loaded, setLoaded] = useState<boolean>(false);

	const conv = (item: Appointment) => {
		const schedule = item.schedule && new Date(item.schedule);
		const deadline = item.deadline && new Date(item.deadline);
		const queue_entered = item.queue_entered && new Date(item.queue_entered);
		const birth_day: Date =
			item.patient &&
			item.patient.birth_day &&
			new Date(item.patient.birth_day);
		return {
			...item,
			schedule: schedule,
			deadline: deadline,
			queue_entered: queue_entered,
			patient: { ...item.patient, birth_day: birth_day },
		};
	};

	useEffect(() => {
		axios
			.all([
				axios.get('/appointments?limit=10&type=EMERGENCY'),
				axios.get('/appointments?limit=10&type=DOUBLE_PRIORITY'),
				axios.get('/appointments?limit=10&type=SINGLE_PRIORITY'),
				axios.get('/appointments?limit=20&type=NO_PRIORITY'),
			])
			.then(
				axios.spread((emergency, double, single, no) => {
					setTableData({
						...tableData,
						emergency: emergency.data.map(conv),
						double: double.data.map(conv),
						single: single.data.map(conv),
						no: no.data.map(conv),
					});
					setLoaded(true);
				})
			);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const tables = [
		tableData.emergency,
		tableData.double,
		tableData.single,
		tableData.no,
	];

	return (
		<div style={styles.mainContent}>
			<PageHeader title="Undersökningar" />
			<div style={{ margin: '0.8rem' }}>
				{loaded ? (
					<>
						{tables.map((a, i) => (
							<TableContainer
								key={i}
								elevation={4}
								component={Paper}
								style={{ marginBottom: '1rem' }}
							>
								<Table size="small">
									<TableHead>
										<TableRow sx={{ backgroundColor: '#444', color: '#fff' }}>
											<TableCell />
											<TableCell sx={{ color: 'inherit' }}>ID</TableCell>
											<TableCell sx={{ color: 'inherit' }}>Prioritet</TableCell>
											<TableCell sx={{ color: 'inherit' }}>Patient</TableCell>
											<TableCell sx={{ color: 'inherit' }}>
												Undersökning
											</TableCell>
											<TableCell sx={{ color: 'inherit' }}>Inkom</TableCell>
											<TableCell sx={{ color: 'inherit' }}>
												Schemalagd
											</TableCell>
											<TableCell sx={{ color: 'inherit' }}>Deadline</TableCell>
										</TableRow>
									</TableHead>

									<TableBody>
										{a.map((item, index) => (
											<Row key={index} appointment={item} index={index} />
										))}
									</TableBody>
								</Table>
							</TableContainer>
						))}
					</>
				) : (
					<LinearProgress />
				)}
			</div>
		</div>
	);
}
