import { useState, useEffect, useContext } from 'react';

import {
	Card,
	CardContent,
	CardHeader,
	FormControl,
	CircularProgress,
	Fade,
} from '@mui/material';
import { addMonths } from 'date-fns/esm';
import { useTranslation } from 'react-i18next';
import { useLazyQuery } from '@apollo/client';
import gql from 'graphql-tag';
import chroma from 'chroma-js';

import './gridLayout.scss';
import {
	PageHeader,
	FormLabel,
	GraphContainer,
	HelpText,
} from '../common/AppLayout';
import { PatientQueueGraph } from '../page-specific/patient-queue/PatientQueueGraph';
import { styles, baseColors } from '../../theme';
import { ClinicSelector, ClinicSelectorProps } from '../common/ClinicSelector';
import { DateRangeSelector } from '../common/DateRangeSelector';
import {
	AppThemeContext,
	ClinicInformationContext,
} from '../../contexts/Providers';
import { ClinicOption } from '../common/types/Page';

const QUEUESNAPSHOT_QUERY = gql`
	query Snapshot(
		$start: date!
		$end: date!
		$startShadow: date!
		$endShadow: date!
		$clinics: [Int!]
	) {
		ftv_queuepatientsnapshotview(
			where: { day: { _gte: $start, _lte: $end }, clinicid: { _in: $clinics } }
			order_by: { day: asc }
		) {
			avg_wait
			clinicid
			count
			day
			std_wait
		}
		shadow: ftv_queuepatientsnapshotview(
			where: {
				day: { _gte: $startShadow, _lte: $endShadow }
				clinicid: { _in: $clinics }
			}
			order_by: { day: asc }
		) {
			avg_wait
			clinicid
			count
			day
			std_wait
		}
	}
`;

const processGraphData = (arr: Array<any>, yDesiredKey: string): Array<any> => {
	const output: Array<any> = [];
	arr.forEach((clinic) => {
		const newItem = {
			id: clinic.label,
			data: clinic.data.map((datapoint: any) => {
				return { ...datapoint, x: datapoint.day, y: datapoint[yDesiredKey] };
			}),
		};
		output.push(newItem);
	});
	return output;
};

const ALPHA_LEVEL = 0.35;
const ftvPalette = [
	baseColors.darkBlue,
	chroma(baseColors.darkBlue).alpha(ALPHA_LEVEL).hex(),
	baseColors.orange,
	chroma(baseColors.orange).alpha(ALPHA_LEVEL).hex(),
	baseColors.lightGreen,
	chroma(baseColors.lightGreen).alpha(ALPHA_LEVEL).hex(),
	baseColors.orange,
	chroma(baseColors.orange).alpha(ALPHA_LEVEL).hex(),
	baseColors.lightBlue,
	chroma(baseColors.lightBlue).alpha(ALPHA_LEVEL).hex(),
	baseColors.red,
	chroma(baseColors.red).alpha(ALPHA_LEVEL).hex(),
	baseColors.purple,
	chroma(baseColors.purple).alpha(ALPHA_LEVEL).hex(),
];

interface PatientQueueGraphData {
	id: number;
	label: string;
	data: Array<{
		avg_wait: number;
		clinicid: number;
		count: number;
		day: string;
		std_wait: number;
	}>;
}

function PatientQueue() {
	const clinics = useContext(ClinicInformationContext);
	const [selectedClinics, setSelectedClinics] =
		useState<Array<ClinicOption>>(clinics);
	const [dateRange, setDateRange] = useState({
		start: addMonths(new Date(), -3),
		end: new Date(),
	});
	const [graphData, setGraphData] = useState<Array<PatientQueueGraphData>>([]);
	const { t } = useTranslation();
	const { palette } = useContext(AppThemeContext);

	// Get main-line
	const [getGraphData, { loading: snapshot_loading }] = useLazyQuery(
		QUEUESNAPSHOT_QUERY,
		{
			onCompleted: (data) => {
				const result: Array<any> = [];
				clinics &&
					clinics.forEach((clinic: any) => {
						const arr = data.ftv_queuepatientsnapshotview.filter(
							(item: any) => item.clinicid === clinic.id
						);
						const shadowArr = data.shadow.filter(
							(item: any) => item.clinicid === clinic.id
						);
						if (arr.length > 0) {
							// pushing main graph data
							result.push({
								id: clinic.id,
								label: clinic.label,
								data: arr,
							});
							// pushing shadow graph data
							result.push({
								id: clinic.id,
								label: `${clinic.label}-fp`,
								data: shadowArr.map((item: any) => {
									const d = addMonths(new Date(Date.parse(item.day)), 12);
									return { ...item, day: d.toISOString().split('T')[0] }; // splitting into ISO without time and timezone
								}),
							});
						}
					});
				setGraphData(result);
			},
		}
	);

	useEffect(() => {
		getGraphData({
			variables: {
				start: dateRange.start,
				end: dateRange.end,
				startShadow: addMonths(dateRange.start, -12),
				endShadow: addMonths(dateRange.end, -12),
				clinics: selectedClinics.map((clinic: any) => clinic.id),
			},
		});
	}, [selectedClinics, dateRange, getGraphData]);

	const graphLoading = () => (
		<div style={{ position: 'relative' }}>
			<CircularProgress size={200} style={{ marginLeft: '40%' }} />
		</div>
	);

	const graphPalette =
		palette.paletteName === 'sownder'
			? palette.colors.flatMap((item) => [
					item,
					chroma(item).saturate(1).alpha(0.35).hex(),
			  ])
			: ftvPalette;

	// Render
	if (clinics && clinics.length) {
		const clinicProps: ClinicSelectorProps = {
			options: clinics,
			updateSelection: setSelectedClinics,
			placeholder: t('select clinics'),
			value: selectedClinics,
		};

		const queueSizeProps = {
			id: 'graph',
			title: selectedClinics.length ? 'Kölängd' : '',
			subheader: selectedClinics.length ? 'Antal patienter' : '',
		};

		return (
			<div style={styles.mainContent}>
				<PageHeader title={t('queue-patient')} />

				<div id="gridColSplit">
					<div id="mainGrid">
						<GraphContainer {...queueSizeProps}>
							{
								// Spinner if loading, graph if data exists, else null
								selectedClinics.length ? (
									snapshot_loading ? (
										graphLoading()
									) : (
										<PatientQueueGraph
											data={processGraphData(graphData, 'count')}
											yLabel={'Antal patienter'}
											palette={graphPalette}
										/>
									)
								) : (
									HelpText('Ingen klinik vald')
								)
							}
						</GraphContainer>

						<Fade in={graphData.length ? true : false}>
							<GraphContainer
								id="graph"
								title="Kötid"
								subheader="Antal dagar i kö"
							>
								{
									// Spinner if loading, graph if data exists, else null
									snapshot_loading ? (
										graphLoading()
									) : (
										<PatientQueueGraph
											data={processGraphData(graphData, 'avg_wait')}
											yLabel={'Antal dagar'}
											palette={graphPalette}
										/>
									)
								}
							</GraphContainer>
						</Fade>
					</div>

					<div id="settings">
						<Card>
							<CardHeader title={t('settings')} />
							<CardContent>
								<FormControl style={{ width: '100%' }}>
									<FormLabel required>{t('date-interval')}</FormLabel>
									<DateRangeSelector
										onChange={(s) =>
											setDateRange({ start: s.start, end: s.end })
										}
										startDate={dateRange.start}
										endDate={dateRange.end}
									/>

									<FormLabel required>{t('clinics')}</FormLabel>
									<ClinicSelector {...clinicProps} />
								</FormControl>
							</CardContent>
						</Card>
					</div>
				</div>
			</div>
		);
	}
	return null;
}

export default PatientQueue;
