import { withTranslation } from 'react-i18next';
import { ResponsiveBar } from '@nivo/bar';
import chroma from 'chroma-js';
import { nivoColors, graphTheme } from '../../common/GraphGlobalSettings';
import { CartesianMarkerProps } from '@nivo/core';

const guideLine = 30.0 / 37;

const createMarker = (
	legendText: string,
	value: number,
	legendPos?: string
): CartesianMarkerProps => ({
	axis: 'y',
	value: value,
	lineStyle: { stroke: 'rgba(0, 0, 0, 0.5)', strokeWidth: 2 },
	legend: legendText,
	legendPosition:
		legendPos && legendPos === 'top-left' ? legendPos : 'bottom-left',
	textStyle: {
		fill: '#000',
		fontStyle: 'italic',
		fontSize: 16,
	},
});

interface GraphProps {
	data: Array<any>;
	keys: Array<string>;
	palette: Array<string>;
	cb?: (data: any) => void;
	misc?: boolean;
}
/**
 *
 * @param {Array} data Array of data used for drawing graph
 * @param {Array} keys array of keys
 * @param {Array} palette array of colors
 * @param {func} cb callback functino
 */
const Graph = (props: GraphProps) => {
	const { data, keys, palette, cb, misc } = props;

	/**
	 * Normalises the data
	 * @param {Array} data sent from Nivo
	 */
	const processData = (data: any) => {
		const tmp: Array<any> = [];
		data.forEach((item: any) => {
			const multiplier = (1.0 / item['sum']) * 100.0;
			const tmpObj: any = { clinic: item.clinic };
			Object.entries(item).forEach(([key, value]) => {
				if (key !== 'arbetad tid' && key !== 'clinic' && key !== 'patienttid')
					tmpObj[key] = Number(value) * multiplier;
			});
			tmp.push(tmpObj);
		});

		return tmp;
	};

	const miscProcess = (data: any) => {
		const tmp: Array<any> = [];
		data.forEach((item: any) => {
			let pSum = 0;
			Object.entries(item).forEach(([key, value]) => {
				if (keys.includes(key)) pSum += Number(value);
			});
			const multiplier = 100.0 / pSum;

			const tmpObj: any = { clinic: item.clinic };
			Object.entries(item).forEach(([key, value]) => {
				if (key !== 'arbetad tid' && key !== 'clinic' && key !== 'patienttid')
					tmpObj[key] = Number(value) * multiplier;
			});
			tmp.push(tmpObj);
		});

		return tmp;
	};

	/**
	 * Calls a call-back function
	 * @param {Object} data used by Nivo
	 */
	const handleClick = (data: any) => {
		cb && cb(data.data.clinic);
	};

	return (
		<ResponsiveBar
			data={misc ? miscProcess(data) : processData(data)}
			keys={keys}
			indexBy={'clinic'}
			margin={{ top: 8, right: 100, bottom: 100, left: 100 }}
			padding={0.3}
			maxValue={109}
			defs={[
				{
					id: 'dots',
					type: 'patternDots',
					background: 'inherit',
					color: nivoColors[1],
					size: 4,
					padding: 1,
					stagger: true,
				},
				{
					id: 'lines',
					type: 'patternLines',
					background: 'inherit',
					color: chroma(nivoColors[1]).darken(1).hex(),
					rotation: 30,
					lineWidth: 3,
					spacing: 6,
				},
			]}
			borderColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
			axisTop={null}
			axisRight={null}
			axisBottom={{
				tickSize: 5,
				tickPadding: 10,
				tickRotation: 45,
			}}
			axisLeft={{
				tickSize: 5,
				tickPadding: 10,
				tickRotation: 0,
				format: (d) => `${d}%`,
				tickValues: 5,
				legend: 'arbetstid i procent',
				legendOffset: -80,
				legendPosition: 'middle',
			}}
			labelSkipWidth={12}
			labelSkipHeight={12}
			enableLabel={false}
			labelTextColor={(data) =>
				chroma.contrast(data.color, '#000') <= 4.5 ? '#fff' : '#000'
			}
			legends={[
				{
					dataFrom: 'keys',
					anchor: 'top-right',
					direction: 'row',
					justify: false,
					translateX: 80,
					translateY: -10,
					itemsSpacing: 2,
					itemWidth: 150,
					itemHeight: 20,
					itemDirection: 'left-to-right',
					itemOpacity: 0.85,
					symbolSize: 15,
					effects: [
						{
							on: 'hover',
							style: {
								itemOpacity: 1,
							},
						},
					],
				},
			]}
			animate={true}
			markers={[
				createMarker(
					'Mål: andel bokningsbar vårdtid',
					guideLine * 100.0,
					'top-left'
				),
				createMarker('Mål: utförd vårdtid', 76.3),
			]}
			theme={graphTheme}
			colors={palette}
			tooltipLabel={(data) => {
				data.formattedValue =
					Number.parseFloat(data.formattedValue).toFixed(2) + '%';
				return data.id.toString();
			}}
			onClick={(d) => (cb ? handleClick(d) : null)}
		/>
	);
};

export default withTranslation()(Graph);
