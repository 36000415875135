import { useState, useEffect, useContext } from 'react';
import {
	Card,
	CardContent,
	CardHeader,
	FormControl,
	Fade,
	TextField,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';
import { DatePicker } from '@mui/lab';
import './gridLayout.scss';
import {
	PageHeader,
	FormLabel,
	GraphContainer,
	HelpText,
} from '../common/AppLayout';
import StaffWorkGraph from '../page-specific/staff-work/StaffWorkGraph';
import { altColors, styles } from '../../theme';
import { useLazyQuery, useQuery } from '@apollo/client';
import { ClinicSelector } from '../common/ClinicSelector';
import {
	AppThemeContext,
	ClinicInformationContext,
} from '../../contexts/Providers';
import { baseColors } from '../../theme';
import { ScheduleQueryResponse } from '../common/types/Staff';
import {
	SCHEDULEWORK_QUERY,
	SCHEDULE_TYPES_QUERY,
} from '../queries/StaffQueries';
import {
	BaseOption,
	BaseOptionType,
	BaseOptionGroup,
} from '../common/types/Page';

const ftvMainPalette = [
	baseColors.lightBlue,
	baseColors.darkBlue,
	baseColors.main,
	baseColors.lightGreen,
	baseColors.red,
];

const ftvMiscPalette = [
	baseColors.red,
	baseColors.pink,
	baseColors.purple,
	baseColors.shiffer,
	baseColors.darkGreen,
];

const sownderMainPalette = [
	altColors.blue.hex(),
	altColors.light_green.hex(),
	altColors.dark_green.hex(),
	altColors.yellow.hex(),
	altColors.brown.hex(),
];

const sownderMiscPalette = [
	altColors.red.hex(),
	altColors.purple.hex(),
	altColors.cyan.hex(),
	altColors.yellow.hex(),
	altColors.blue.hex(),
];

function StaffWork() {
	const { t } = useTranslation();
	const clinics = useContext(ClinicInformationContext);
	const { palette } = useContext(AppThemeContext);

	const [graphData, setGraphData] = useState<{
		tdl: Array<any>;
		hyg: Array<any>;
	}>({ tdl: [], hyg: [] });
	const [selectedDate, handleDateChange] = useState(new Date());
	const [selectedClinics, setSelectedClinics] = useState(clinics);
	const [selectedScheduleOptions, setSelectedScheduleOptions] = useState<
		Array<BaseOption>
	>([]);
	const [scheduleGroups, setScheduleGroups] = useState<Array<BaseOptionGroup>>(
		[]
	);

	// Loads all schedule types
	useQuery(SCHEDULE_TYPES_QUERY, {
		onCompleted: (data) => {
			const tmp: Array<BaseOptionType> = data.ftv_scheduletype
				.map((item: ScheduleQueryResponse) => {
					return {
						value: item.scheduletext,
						label: item.scheduletext,
						type: item.schedulemaintype,
					};
				})
				.filter(
					(item: BaseOptionType) =>
						item.value !== 'arbetad tid' && item.value !== 'patienttid'
				);

			const groups: Array<BaseOptionGroup> = [
				{
					label: 'bokningsbar vårdtid',
					options: tmp
						.filter((item) => item.type === 'bokningsbar vårdtid')
						.map((item) => ({ label: item.label, value: item.value })),
				},
				{
					label: 'övrig tid',
					options: [{ label: 'all övrig tid', value: 'all övrig tid' }],
				},
			];

			setScheduleGroups(groups);
			setSelectedScheduleOptions(groups.map((group) => group.options).flat()); // Select everything by default
		},
	});

	// Fetches main data
	const [getScheduleWork] = useLazyQuery(SCHEDULEWORK_QUERY, {
		onCompleted: (data) => {
			const tmpTdl: Array<any> = [];
			const tmpHyg: Array<any> = [];
			selectedClinics.forEach((clinic: any) => {
				const clinicName = clinic.label;
				const tmpTdlObj: { [key: string]: any } = { clinic: clinicName };
				const tmpHygObj: { [key: string]: any } = { clinic: clinicName };
				const splitStage = data.ftv_workschedulebyclinicview.filter(
					(item: any) => item.clinicname === clinicName
				);

				splitStage
					.filter((item: any) => item.profession === 'tdl')
					.forEach((item: any) => {
						tmpTdlObj[item.scheduletext] = item.duration;
					});
				let tdlPSum = 0; // partial sum for tdl
				let tdlOvrigSum = 0;
				Object.entries(tmpTdlObj).forEach(([key, value]) => {
					if (
						key !== 'arbetad tid' &&
						key !== 'clinic' &&
						key !== 'patienttid'
					) {
						tdlPSum += value;
						if (
							key !== 'patienttid vuxna' &&
							key !== 'patienttid barn' &&
							key !== 'terapimöte' &&
							key !== 'utbildning'
						)
							tdlOvrigSum += value;
					}
				});
				tmpTdlObj['sum'] = tdlPSum;
				tmpTdlObj['all övrig tid'] = tdlOvrigSum;
				tmpTdl.push(tmpTdlObj);

				splitStage
					.filter((item: any) => item.profession === 'hyg')
					.forEach((item: any) => {
						tmpHygObj[item.scheduletext] = item.duration;
					});
				let hygPSum = 0; // partial sum for hyg
				let hygOvrigSum = 0;
				Object.entries(tmpHygObj).forEach(([key, value]) => {
					if (
						key !== 'arbetad tid' &&
						key !== 'clinic' &&
						key !== 'patienttid'
					) {
						hygPSum += value;
						if (
							key !== 'patienttid vuxna' &&
							key !== 'patienttid barn' &&
							key !== 'terapimöte' &&
							key !== 'utbildning'
						)
							hygOvrigSum += value;
					}
				});
				tmpHygObj['sum'] = hygPSum;
				tmpHygObj['all övrig tid'] = hygOvrigSum;
				tmpHyg.push(tmpHygObj);
			});

			setGraphData({ tdl: tmpTdl, hyg: tmpHyg });
		},
		fetchPolicy: 'network-only', // on page swap, without this setting graphql tries to get cached data that doesn't exist
	});

	useEffect(() => {
		const params = {
			variables: {
				clinics: selectedClinics.map((item: any) => item.value),
				year: selectedDate ? selectedDate.getUTCFullYear() : undefined,
				month: selectedDate ? selectedDate.getUTCMonth() + 1 : 0,
			},
		};
		getScheduleWork(params);
	}, [selectedClinics, selectedDate, getScheduleWork]);

	if (scheduleGroups.length && clinics.length) {
		const necessarySelections =
			selectedClinics &&
			selectedClinics.length > 0 &&
			selectedScheduleOptions &&
			selectedScheduleOptions.length > 0;

		const sharedTdlGraphProps = {
			id: 'graph',
			title:
				selectedClinics &&
				selectedClinics.length &&
				graphData.tdl &&
				graphData.tdl.length
					? 'Tandläkare'
					: undefined,
		};

		const graphPalettes =
			palette.paletteName === 'ftv'
				? { main: ftvMainPalette, misc: ftvMiscPalette }
				: { main: sownderMainPalette, misc: sownderMiscPalette };

		return (
			<div style={styles.mainContent}>
				<PageHeader title="Arbetad tid" />

				<div id="gridColSplit">
					<div id="mainGrid">
						<GraphContainer
							{...sharedTdlGraphProps}
							subheader={necessarySelections ? 'Alla tidsarter' : null}
						>
							{graphData.tdl &&
							graphData.tdl.length &&
							selectedScheduleOptions.length ? (
								<StaffWorkGraph
									data={graphData.tdl}
									keys={selectedScheduleOptions.map((item) => item.label)}
									palette={graphPalettes.main}
									misc={false}
								/>
							) : (
								HelpText('Ingen klinik och tidsart vald')
							)}
						</GraphContainer>

						<Fade
							in={
								graphData.hyg !== null &&
								graphData.hyg.length > 0 &&
								selectedScheduleOptions.length > 0
							}
							timeout={1500}
						>
							<GraphContainer
								id="graph"
								title={'Tandhygienist'}
								subheader={necessarySelections ? 'Alla tidsarter' : null}
							>
								{graphData.hyg &&
								graphData.hyg.length &&
								selectedScheduleOptions.length ? (
									<StaffWorkGraph
										data={graphData.hyg}
										keys={selectedScheduleOptions.map((item) => item.label)}
										palette={graphPalettes.main}
										misc={false}
									/>
								) : null}
							</GraphContainer>
						</Fade>

						<Fade
							in={
								graphData.tdl !== null &&
								graphData.tdl.length > 0 &&
								selectedScheduleOptions.length > 0
							}
							timeout={1500}
						>
							<GraphContainer
								{...sharedTdlGraphProps}
								subheader={necessarySelections ? 'All övrig tid' : null}
							>
								{graphData.tdl &&
								graphData.tdl.length &&
								selectedScheduleOptions.length ? (
									<StaffWorkGraph
										data={graphData.tdl}
										keys={scheduleGroups
											.filter((group) => group.label === 'övrig tid')
											.map((group) => group.label)}
										palette={graphPalettes.misc}
										cb={undefined}
										misc={true}
									/>
								) : (
									HelpText('Ingen klinik och tidsart vald')
								)}
							</GraphContainer>
						</Fade>

						<Fade
							in={
								graphData.hyg !== null &&
								graphData.hyg.length > 0 &&
								selectedScheduleOptions.length > 0
							}
							timeout={1500}
						>
							<GraphContainer
								{...sharedTdlGraphProps}
								title={'Tandhygienist'}
								subheader={necessarySelections ? 'All övrig tid' : null}
							>
								{graphData.hyg &&
								graphData.hyg.length &&
								selectedScheduleOptions.length ? (
									<StaffWorkGraph
										data={graphData.hyg}
										keys={scheduleGroups
											.filter((group) => group.label === 'övrig tid')
											.map((group) => group.label)}
										palette={graphPalettes.misc}
										cb={undefined}
										misc={true}
									/>
								) : (
									HelpText('Ingen klinik och tidsart vald')
								)}
							</GraphContainer>
						</Fade>
					</div>

					<div id="settings">
						<Card>
							<CardHeader title={t('settings')} />
							<CardContent>
								<FormControl>
									<FormLabel required>{'Välj år och månad'}</FormLabel>
									<DatePicker
										openTo="year"
										views={['year', 'month']}
										label="År och månad"
										value={selectedDate}
										inputFormat="yyyy MMMM"
										onChange={(date) =>
											handleDateChange(date === null ? new Date() : date)
										}
										renderInput={(params) => <TextField {...params} />}
									/>
									<FormLabel required>{t('clinics')}</FormLabel>
									<ClinicSelector
										options={clinics}
										value={selectedClinics}
										updateSelection={setSelectedClinics}
										placeholder={t('select clinics')}
									/>

									<FormLabel required>Tidsart</FormLabel>
									<Select
										isMulti={true}
										closeMenuOnSelect={false}
										options={scheduleGroups}
										value={selectedScheduleOptions}
										onChange={(opts) =>
											setSelectedScheduleOptions(opts as Array<BaseOption>)
										}
										placeholder={'Välj tidsart'}
										noOptionsMessage={() => t('no options')}
										menuPortalTarget={document.querySelector('body')}
									/>
								</FormControl>
							</CardContent>
						</Card>
					</div>
				</div>
			</div>
		);
	}
	return null;
}

export default StaffWork;
