import { useState, useEffect, useContext } from 'react';

import {
	Card,
	CardContent,
	CardHeader,
	FormControl,
	FormControlLabel,
	RadioGroup,
	Radio,
	Fade,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { addMonths } from 'date-fns/esm';

import './gridLayout.scss';
import {
	PageHeader,
	FormLabel,
	GraphContainer,
	HelpText,
} from '../common/AppLayout';
import { styles } from '../../theme';
import { ClinicSelector, ClinicSelectorProps } from '../common/ClinicSelector';
import { DateRangeSelector } from '../common/DateRangeSelector';
import PatientRecallGraph, {
	DivergingGraph,
} from '../page-specific/patient-recall/PatientRecallGraph';
import {
	AppThemeContext,
	ClinicInformationContext,
} from '../../contexts/Providers';
import { ClinicOption } from '../common/types/Page';
import { ftvRiskPalette } from '../common/GraphGlobalSettings';

const keysSwe = ['riskgrupp19+0', 'riskgrupp0', 'riskgrupp1', 'riskgrupp2'];

const randomize = (value: number) => {
	return Math.floor(value) * (Math.random() * (1.2 - 0.8) + 0.85);
};

const planned = [
	{
		clinic: 'Asarum',
		typeA: randomize(28.3),
		typeB: randomize(19.2),
		typeC: randomize(13.1),
		typeD: randomize(23),
	},
	{
		clinic: 'Bräkne-Hoby',
		typeA: randomize(27.8),
		typeB: randomize(17.1),
		typeC: randomize(13.9),
		typeD: randomize(23),
	},
	{
		clinic: 'Karlshamn',
		typeA: randomize(20.7),
		typeB: randomize(19.3),
		typeC: randomize(12.5),
		typeD: randomize(23),
	},
	{
		clinic: 'Ronneby',
		typeA: randomize(23.2),
		typeB: randomize(17.3),
		typeC: randomize(14.1),
		typeD: randomize(23),
	},
	{
		clinic: 'Sölvesborg',
		typeA: randomize(23.4),
		typeB: randomize(17.1),
		typeC: randomize(14.2),
		typeD: randomize(23),
	},
	{
		clinic: 'Jämjö',
		typeA: randomize(25.7),
		typeB: randomize(19.4),
		typeC: randomize(14.9),
		typeD: randomize(23),
	},
	{
		clinic: 'Karlskrona',
		typeA: randomize(23.8),
		typeB: randomize(17.9),
		typeC: randomize(12.9),
		typeD: randomize(23),
	},
	{
		clinic: 'Lyckeby',
		typeA: randomize(26.1),
		typeB: randomize(18.8),
		typeC: randomize(11.2),
		typeD: randomize(23),
	},
	{
		clinic: 'Nättraby',
		typeA: randomize(28.7),
		typeB: randomize(20.8),
		typeC: randomize(13.1),
		typeD: randomize(23),
	},
	{
		clinic: 'Olofström',
		typeA: randomize(28.9),
		typeB: randomize(18.1),
		typeC: randomize(12.3),
		typeD: randomize(23),
	},
	{
		clinic: 'Rödeby',
		typeA: randomize(29.3),
		typeB: randomize(17.1),
		typeC: randomize(11.0),
		typeD: randomize(23),
	},
];

const actual = [
	{
		clinic: 'Asarum',
		typeA: 28.3,
		typeB: 19.2,
		typeC: 13.1,
		// typeD: 12.8
		typeD: randomize(24),
	},
	{
		clinic: 'Bräkne-Hoby',
		typeA: 27.8,
		typeB: 17.1,
		typeC: 13.9,
		// typeD: 14.1
		typeD: randomize(24),
	},
	{
		clinic: 'Karlshamn',
		typeA: 20.7,
		typeB: 19.3,
		typeC: 12.5,
		// typeD: 13.8
		typeD: randomize(24),
	},
	{
		clinic: 'Ronneby',
		typeA: 23.2,
		typeB: 17.3,
		typeC: 14.1,
		// typeD: 15.2
		typeD: randomize(24),
	},
	{
		clinic: 'Sölvesborg',
		typeA: 23.4,
		typeB: 17.1,
		typeC: 14.2,
		// typeD: 12.3
		typeD: randomize(24),
	},
	{
		clinic: 'Jämjö',
		typeA: 25.7,
		typeB: 19.4,
		typeC: 14.9,
		// typeD: 12.3
		typeD: randomize(24),
	},
	{
		clinic: 'Karlskrona',
		typeA: 23.8,
		typeB: 17.9,
		typeC: 12.9,
		// typeD: 13.7
		typeD: randomize(24),
	},
	{
		clinic: 'Lyckeby',
		typeA: 26.1,
		typeB: 18.8,
		typeC: 11.2,
		// typeD: 14.8
		typeD: randomize(24),
	},
	{
		clinic: 'Nättraby',
		typeA: 28.7,
		typeB: 20.8,
		typeC: 13.1,
		// typeD: 12.8
		typeD: randomize(24),
	},
	{
		clinic: 'Olofström',
		typeA: 28.9,
		typeB: 18.1,
		typeC: 12.3,
		// typeD: 12.8
		typeD: randomize(24),
	},
	{
		clinic: 'Rödeby',
		typeA: 29.3,
		typeB: 17.1,
		typeC: 11.0,
		// typeD: 13.7
		typeD: randomize(24),
	},
];

function PatientRecall() {
	const clinics = useContext(ClinicInformationContext);
	const [selectedClinics, setSelectedClinics] =
		useState<Array<ClinicOption>>(clinics);
	const [dateRange, setDateRange] = useState({
		start: addMonths(new Date(), -3),
		end: new Date(),
	});
	const [graphData, setGraphData] = useState<{
		actual: Array<any>;
		planned: Array<any>;
	}>({
		actual: [],
		planned: [],
	}); // consists of both planned and actual recall graph data
	const [ageGroup, setAgeGroup] = useState('adult'); // adult or child
	const [clickSelection, setClickSelection] = useState<{
		key: string | undefined;
		group: string | undefined;
	}>({ key: undefined, group: undefined });

	const { t } = useTranslation();
	const { palette } = useContext(AppThemeContext);

	useEffect(() => {
		const tmpActual: Array<any> = [];
		const tmpPlanned: Array<any> = [];
		selectedClinics.forEach((item) => {
			const plannedIndex = planned.findIndex(
				(element: any) => element.clinic === item.label
			);
			if (plannedIndex !== -1) tmpPlanned.push(planned[plannedIndex]);

			const index = actual
				.map((dataItem) => dataItem.clinic)
				.indexOf(item.label);
			if (index !== -1) tmpActual.push(actual[index]);
		});

		// translate typeA -> typA
		setGraphData({
			actual: tmpActual.map((item: any) => {
				return {
					clinic: item.clinic,
					'riskgrupp19+0': item.typeD,
					riskgrupp0: item.typeA,
					riskgrupp1: item.typeB,
					riskgrupp2: item.typeC,
				};
			}),
			planned: tmpPlanned.map((item: any) => {
				return {
					clinic: item.clinic,
					'riskgrupp19+0': item.typeD,
					riskgrupp0: item.typeA,
					riskgrupp1: item.typeB,
					riskgrupp2: item.typeC,
				};
			}),
		});
	}, [selectedClinics]);

	/**
	 * Set state variables for patient age-group radio group
	 * @param {object} event
	 */
	const handleRadio = (event: any) => {
		setAgeGroup(event.target.value);
	};

	// Callback function, sets the selectedkey
	const onClickBar = (key: any, group: any) => {
		setClickSelection({ key: key, group: group });
	};

	if (clinics && clinics.length) {
		// clinic selector props
		const clinicProps: ClinicSelectorProps = {
			options: clinics,
			updateSelection: setSelectedClinics,
			placeholder: t('select clinics'),
			value: selectedClinics,
		};

		const actualRecallProps = {
			id: 'graph',
			title: selectedClinics.length ? 'Faktisk revision' : '',
		};

		const riskGraphPalette =
			palette.paletteName === 'sownder'
				? [
						palette.colors[0],
						palette.colors[1],
						palette.colors[3],
						palette.colors[4],
				  ]
				: ftvRiskPalette;

		return (
			<div style={styles.mainContent}>
				<PageHeader title={t('recall')} />

				<div id="gridColSplit">
					<div id="mainGrid">
						<GraphContainer {...actualRecallProps}>
							{selectedClinics.length ? (
								<PatientRecallGraph
									data={graphData.actual}
									keys={keysSwe}
									palette={riskGraphPalette}
									onClick={onClickBar}
									group={'actual'}
								/>
							) : (
								HelpText('Ingen klinik vald')
							)}
						</GraphContainer>

						<Fade in={selectedClinics.length > 0} timeout={1500}>
							<GraphContainer id="graph" title="Planerad revision">
								{selectedClinics.length ? (
									<PatientRecallGraph
										data={graphData.planned}
										keys={keysSwe}
										palette={riskGraphPalette}
										onClick={onClickBar}
										group={'planned'}
									/>
								) : null}
							</GraphContainer>
						</Fade>

						<Fade in={clickSelection.group ? true : false} timeout={1000}>
							<GraphContainer
								id="graph"
								title={
									clickSelection.group === 'actual'
										? 'Faktisk revision'
										: 'Planerad revision'
								}
								subheader={clickSelection.key}
							>
								{selectedClinics.length &&
								clickSelection.key &&
								clickSelection.group ? (
									<DivergingGraph
										data={
											clickSelection.group === 'actual'
												? graphData.actual
												: graphData.planned
										}
										keys={keysSwe}
										palette={
											clickSelection.group === 'actual'
												? [
														riskGraphPalette[
															keysSwe.indexOf(clickSelection.key)
														],
												  ]
												: [
														riskGraphPalette[
															keysSwe.indexOf(clickSelection.key)
														],
												  ]
										}
										selectedKey={clickSelection.key}
									/>
								) : null}
							</GraphContainer>
						</Fade>
					</div>

					<div id="settings">
						<Card>
							<CardHeader title={t('settings')} />
							<CardContent>
								<FormControl style={{ width: '100%' }}>
									<FormLabel required>{t('date-interval')}</FormLabel>
									<DateRangeSelector
										onChange={(s) =>
											setDateRange({ start: s.start, end: s.end })
										}
										startDate={dateRange.start}
										endDate={dateRange.end}
									/>

									<FormLabel required>{t('age-groups')}</FormLabel>
									<RadioGroup
										name="ageGroup"
										row
										onChange={handleRadio}
										value={ageGroup}
									>
										<FormControlLabel
											value="children"
											control={<Radio color="primary" />}
											label="3-6 år"
											labelPlacement="end"
										/>
										<FormControlLabel
											value="adult"
											control={<Radio color="primary" />}
											label="7+ år"
											labelPlacement="end"
										/>
									</RadioGroup>

									<FormLabel required>{t('clinics')}</FormLabel>
									<ClinicSelector {...clinicProps} />
								</FormControl>
							</CardContent>
						</Card>
					</div>
				</div>
			</div>
		);
	}
	return null;
}

export default PatientRecall;
