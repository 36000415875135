import { Fragment } from 'react';
import { BarTooltipProps, ResponsiveBar } from '@nivo/bar';
import { PointSymbolProps, ResponsiveLine } from '@nivo/line';

import { graphTheme } from '../../common/GraphGlobalSettings';
import chroma from 'chroma-js';
import { CartesianMarkerProps } from '@nivo/core';

interface GraphData {
	id: string;
	yes: number;
	no: number;
	description: string;
}

interface PatientSurveyGraphProps {
	data: Array<GraphData>;
	palette: Array<string>;
	avg: number;
}

/**
 * Draws a grouped bar-chart
 * @param {Array} data Array of data, used for drawing graph
 * @param {Array} palette Array of colors
 */
export const PatientSurveyGraph = (props: PatientSurveyGraphProps) => {
	const { data, palette } = props;
	const legends = [
		'Delaktighet',
		'Kostnadsinformation',
		'Bemötande',
		'Information',
		'Råd',
	];

	/**
	 * Normalize data, with 2 decimal points
	 */
	function dataProcess(data: Array<GraphData>) {
		return data.map((item) => {
			const sum = item.yes + item.no;
			return {
				id: item.id,
				ja: parseFloat(((item.yes / sum) * 100).toFixed(1)),
				nej: parseFloat(((item.no / sum) * 100).toFixed(1)),
			};
		});
	}

	function displayTooltip(tooltipData: BarTooltipProps<any>) {
		const { yes, no, description } = data[tooltipData.index];
		return (
			<div
				style={{
					fontSize: 14,
					backgroundColor: '#444',
					padding: '0.3rem',
					color: 'fff',
					width: 400,
				}}
			>
				<h4 style={{ margin: 0, color: '#eee' }}>
					Fråga {tooltipData.data.id.split('Q')[1]}
				</h4>
				<div style={{ fontStyle: 'italic', color: '#fff' }}>{description}</div>
				<div style={{ backgroundColor: palette[0] }}>Ja: {yes}</div>
				<div style={{ color: '#fff', backgroundColor: palette[1] }}>
					Nej: {no}
				</div>
				<div style={{ color: '#fff' }}>Totalt: {yes + no}</div>
			</div>
		);
	}

	return (
		<ResponsiveBar
			data={dataProcess(data)}
			keys={['ja', 'nej']}
			indexBy="id"
			margin={{ top: 50, right: 70, bottom: 70, left: 100 }}
			padding={0.3}
			groupMode="grouped"
			colors={palette}
			maxValue={100} // should never be over 100%
			axisBottom={{
				tickPadding: 15,
				tickRotation: 0,
				format: (d) => {
					const index = parseInt(d.split('Q')[1]);
					return (
						<Fragment>
							<tspan>Fråga {index}</tspan>
							<tspan x={0} dy={16} fill="#888">
								{legends[index - 1]}
							</tspan>
						</Fragment>
					);
				},
			}}
			axisLeft={{
				tickPadding: 5,
				tickRotation: 0,
				legendOffset: -60,
				legendPosition: 'middle',
				format: (value) => value + '%',
				legend: 'Procent',
			}}
			label={(value) => value.formattedValue + '%'}
			labelTextColor={(d) =>
				chroma.contrast(d.color, '#fff') < 4.5 ? '#000' : '#fff'
			}
			legends={[
				{
					dataFrom: 'keys',
					anchor: 'bottom-right',
					direction: 'column',
					justify: false,
					translateX: 100,
					translateY: 0,
					itemsSpacing: 2,
					itemWidth: 100,
					itemHeight: 20,
					itemDirection: 'left-to-right',
					itemOpacity: 0.85,
					symbolSize: 20,
					effects: [
						{
							on: 'hover',
							style: {
								itemOpacity: 1,
							},
						},
					],
				},
			]}
			animate={true}
			theme={graphTheme}
			// tooltip={displayTooltip}
			tooltip={(d) => displayTooltip(d)}
		/>
	);
};

const customSymbol = (symb: PointSymbolProps) => (
	<g>
		<circle
			fill={symb.color}
			r={symb.size / 2}
			stroke={symb.color}
			strokeWidth={symb.borderWidth}
		/>
	</g>
);

const createMarker = (
	legendText: string,
	value: number,
	color: string
): CartesianMarkerProps => ({
	axis: 'y',
	value: value,
	lineStyle: { stroke: color, strokeWidth: 1 },
	legend: `${legendText} ${value.toFixed(2)}`,
	legendPosition: 'top-left',
	textStyle: {
		fill: color,
		fontStyle: 'italic',
		fontSize: 14,
	},
});

interface AnsHistoryChartProps {
	data: Array<{ id: string; data: Array<{ [key: string]: any }> }>;
	palette: Array<string>;
}

/**
 * Draws a line chart
 * @param {Array} data Array of data
 * @param {Array} palette Array of colors in string-format
 */
export const AnsHistoryChart = (props: AnsHistoryChartProps) => {
	const { data, palette } = props;
	return (
		<ResponsiveLine
			data={data}
			margin={{ top: 20, right: 100, bottom: 50, left: 60 }}
			xFormat="time:%Y-%m-%d"
			xScale={{ type: 'time', format: '%Y-%m-%d', precision: 'day' }}
			yScale={{ type: 'linear', stacked: false }}
			curve="monotoneX"
			axisBottom={{
				format: '%Y-%m-%d',
				tickValues: 'every 4 days',
			}}
			axisLeft={{
				tickValues: [1, 5, 10, 15], // hard-coded
				tickSize: 5,
				tickPadding: 5,
				tickRotation: 0,
				legend: 'Antal',
				legendPosition: 'middle',
				legendOffset: -40,
			}}
			enableGridX={false}
			colors={palette}
			lineWidth={1}
			pointSize={4}
			pointColor={palette[1]}
			pointBorderWidth={1}
			pointBorderColor={palette[1]}
			pointSymbol={customSymbol}
			enablePointLabel={true}
			pointLabelYOffset={-6}
			pointLabel={(d) => d.y as string}
			useMesh={true}
			enableArea={true}
			areaOpacity={0.5}
			markers={[
				createMarker('snitt', 6.5, palette[0]),
				createMarker('snitt', 3.25, palette[1]),
			]}
			// theme={graphTheme}
			legends={[
				{
					anchor: 'bottom-right',
					direction: 'column',
					justify: false,
					translateX: 100,
					translateY: 0,
					itemsSpacing: 2,
					itemDirection: 'left-to-right',
					itemWidth: 80,
					itemHeight: 12,
					itemOpacity: 0.75,
					symbolSize: 12,
					symbolShape: 'circle',
					symbolBorderColor: 'rgba(0, 0, 0, .5)',
					effects: [
						{
							on: 'hover',
							style: {
								itemBackground: 'rgba(0, 0, 0, .03)',
								itemOpacity: 1,
							},
						},
					],
				},
			]}
		/>
	);
};
