export enum Profession {
	BMA = 'BMA',
	DOCTOR = 'DOCTOR',
}

export interface Task {
	start: number;
	end: number;
	duration: number;
	profession: Profession;
}

export interface Activity {
	id: string;
	pname: string;
	pnumber: string;
	type: string;
	tasks: Array<Task>;
}

export const ExaminationsData = {
	EKO: {
		// remove this after demo!
		id: '1',
		pname: 'Maria Andersson',
		pnumber: '710202-6643',
		type: 'Undersökning A',
		tasks: [
			{
				profession: Profession.BMA,
				duration: 30,
			} as Task,
			{
				profession: Profession.DOCTOR,
				duration: 15,
			} as Task,
			{
				profession: Profession.BMA,
				duration: 30,
			} as Task,
		],
	} as Activity,
	Carotis: {
		id: '2',
		pname: 'Anna Johansson',
		pnumber: '880301-2155',
		type: 'Undersökning B',
		tasks: [
			{
				profession: Profession.BMA,
				duration: 60,
			} as Task,
			{
				profession: Profession.DOCTOR,
				duration: 15,
			} as Task,
		],
	} as Activity,
	Kärl: {
		id: '3',
		pname: 'Karl Karlsson',
		pnumber: '880313-8513',
		type: 'Undersökning C',
		tasks: [
			{
				profession: Profession.BMA,
				duration: 90,
			} as Task,
			{
				profession: Profession.DOCTOR,
				duration: 15,
			} as Task,
		],
	} as Activity,
	AProv: {
		id: '4',
		pname: 'Elisabeth Nilsson',
		pnumber: '630109-4220',
		type: 'Undersökning D',
		tasks: [
			{
				profession: Profession.BMA,
				duration: 45,
			} as Task,
			{
				profession: Profession.DOCTOR,
				duration: 15,
			} as Task,
		],
	} as Activity,
	ABT: {
		id: '5',
		pname: 'Johan Eriksson',
		pnumber: '971213-7224',
		type: 'Undersökning E',
		tasks: [
			{
				profession: Profession.BMA,
				duration: 30,
			} as Task,
			{
				profession: Profession.DOCTOR,
				duration: 10,
			} as Task,
		],
	} as Activity,
	LTER: {
		id: '6',
		pname: 'Per Larsson',
		pnumber: '641218-4894',
		type: 'Undersökning F',
		tasks: [
			{
				profession: Profession.BMA,
				duration: 60,
			} as Task,
			{
				profession: Profession.DOCTOR,
				duration: 10,
			} as Task,
		],
	} as Activity,
	PeriferaTryck: {
		id: '7',
		pname: 'Birgitta Olsson',
		pnumber: '911211-4591',
		type: 'Undersökning G',
		tasks: [
			{
				profession: Profession.BMA,
				duration: 60,
			} as Task,
			{
				profession: Profession.DOCTOR,
				duration: 10,
			} as Task,
		],
	} as Activity,
	Spirometri: {
		id: '8',
		pname: 'Nils Persson',
		pnumber: '780225-4743',
		type: 'Undersökining H',
		tasks: [
			{
				profession: Profession.BMA,
				duration: 75,
			} as Task,
			{
				profession: Profession.DOCTOR,
				duration: 15,
			} as Task,
		],
	} as Activity,
	TEE: {
		id: '9',
		pname: 'Mikael Svensson',
		pnumber: '700323-3967',
		type: 'Undersökning I',
		tasks: [
			{
				profession: Profession.DOCTOR,
				duration: 60,
			} as Task,
		],
	} as Activity,
	StressEKO: {
		id: '10',
		pname: 'Jan Gustafsson',
		pnumber: '640328-5189',
		type: 'Undersöking J',
		tasks: [
			{
				profession: Profession.DOCTOR,
				duration: 90,
			} as Task,
		],
	} as Activity,
};
