import { useTranslation } from 'react-i18next';

import './gridLayout.scss';
import { PageHeader } from '../common/AppLayout';
import { styles } from '../../theme';

// class Economy extends Component {
function Economy() {
	const { t } = useTranslation();

	return (
		<div>
			<div style={styles.mainContent}>
				<PageHeader title={t('economy-view')} />
			</div>
		</div>
	);
}

export default Economy;
