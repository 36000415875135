import { ResponsiveBar } from '@nivo/bar';
import { ResponsiveLine, Serie } from '@nivo/line';
import { graphTheme, tooltipTheme } from '../../common/GraphGlobalSettings';
import chroma from 'chroma-js';

export interface ClinicGroup {
	[key: string]: any;
}

export interface ClinicData extends ClinicGroup {
	clinic: string;
	sum: number;
}

interface PatientFrisktandvardGraphProps {
	isRelative: boolean;
	palette: Array<string>;
	data: Array<ClinicData>;
	cb: (data: any) => void;
	keys: Array<string>;
}

export function PatientFrisktandvardGraph(
	props: PatientFrisktandvardGraphProps
) {
	const normalizeData = (data: Array<ClinicData>) => {
		return data.map((item) => {
			const tmpObj: ClinicData = {
				clinic: item.clinic,
				sum: item.sum,
			};
			Object.entries(item).forEach(([key, value]) => {
				if (key !== 'clinic' && key !== 'sum')
					tmpObj[key] = ((value / item.sum) * 100.0).toFixed(2);
			});
			return tmpObj;
		});
	};

	const tooltip = (data: any) => {
		return (
			<div style={tooltipTheme}>
				<div>ATV {data.indexValue}</div>
				{props.isRelative ? (
					<div>{data.value.toFixed(2)}%</div>
				) : (
					<div>Antal {data.value}</div>
				)}
			</div>
		);
	};

	return (
		<ResponsiveBar
			data={props.isRelative ? normalizeData(props.data) : props.data}
			keys={props.keys}
			indexBy="clinic"
			margin={{ top: 20, right: 100, bottom: 140, left: 100 }}
			padding={0.3}
			groupMode="stacked"
			colors={props.palette}
			label={(d) =>
				props.isRelative ? `${Number(d.value).toFixed(2)}%` : d.formattedValue
			}
			borderColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
			axisBottom={{
				tickSize: 5,
				tickPadding: 5,
				tickRotation: 45,
			}}
			axisLeft={{
				tickSize: 5,
				tickPadding: 5,
				tickRotation: 0,
				legend: 'Antal',
				legendPosition: 'middle',
				legendOffset: -60,
			}}
			labelSkipWidth={24}
			labelSkipHeight={24}
			labelTextColor={(d) => {
				return chroma.contrast(d.color, '#ffffff') < 4.5 ? '#000' : '#fff';
			}}
			animate={true}
			theme={graphTheme}
			tooltip={tooltip}
			onClick={(data) => props.cb(data)}
		/>
	);
}

interface PatientFrisktandvardTimeLineProps {
	data: Array<Serie>;
	palette: Array<string>;
}

export const PatientFrisktandvardTimeLine = (
	props: PatientFrisktandvardTimeLineProps
) => {
	const tooltip = (data: any) => {
		return (
			<div style={tooltipTheme}>
				<div>Datum {data.point.data.x.toISOString().split('T')[0]}</div>
				<div>Antal {data.point.data.y}</div>
			</div>
		);
	};

	return (
		<ResponsiveLine
			data={props.data}
			margin={{ top: 20, right: 130, bottom: 50, left: 100 }}
			xFormat="time:%Y-%m-%d"
			xScale={{
				type: 'time',
				format: '%Y-%m-%d',
				precision: 'day',
			}}
			yScale={{ type: 'linear', stacked: true, min: 'auto', max: 'auto' }}
			axisBottom={{
				format: '%d %b',
				tickSize: 5,
				tickPadding: 5,
				tickRotation: 0,
				legendOffset: 36,
				legendPosition: 'middle',
			}}
			axisLeft={{
				tickSize: 5,
				tickPadding: 5,
				tickRotation: 0,
				legend: 'Antal',
				legendOffset: -60,
				legendPosition: 'middle',
			}}
			colors={props.palette}
			pointSize={10}
			pointColor={{ theme: 'background' }}
			pointBorderWidth={2}
			pointBorderColor={{ from: 'serieColor' }}
			pointLabel="y"
			pointLabelYOffset={-12}
			useMesh={true}
			legends={[
				{
					anchor: 'bottom-right',
					direction: 'column',
					justify: false,
					translateX: 100,
					translateY: 0,
					itemsSpacing: 0,
					itemDirection: 'left-to-right',
					itemWidth: 80,
					itemHeight: 20,
					itemOpacity: 0.75,
					symbolSize: 12,
					symbolShape: 'circle',
					symbolBorderColor: 'rgba(0, 0, 0, .5)',
					effects: [
						{
							on: 'hover',
							style: {
								itemBackground: 'rgba(0, 0, 0, .03)',
								itemOpacity: 1,
							},
						},
					],
				},
			]}
			theme={graphTheme}
			tooltip={tooltip}
		/>
	);
};
