import { Card, CardContent, Typography } from '@mui/material';
import { styles } from '../../theme';

const COLORS = ['#b9dbad', '#69827a', '#cb8a36'];

const Splash: React.FunctionComponent = () => {
	return (
		<div style={styles.mainContent}>
			<Card style={{ marginLeft: '0.8rem' }}>
				<CardContent style={{ margin: 10 }}>
					<div id="splash-info">
						<div style={{ backgroundColor: COLORS[0] }} className="box">
							<Typography variant="h5" className="title">
								Analysera
							</Typography>
							<Typography variant="body2" paragraph>
								I realtid visualiseras statistik och identifierade nyckeltal i
								ett behagligt gränssnitt.
							</Typography>
							<Typography variant="body2" paragraph>
								Tidsfönster som ska visas och andra parametrar som ska ställas
								in, kan man själv välja.
							</Typography>
						</div>

						<div style={{ backgroundColor: COLORS[1] }} className="box">
							<Typography variant="h5" className="title">
								Prediktera
							</Typography>
							<Typography variant="body2" paragraph>
								Prediktera erbjuder flera intelligenta tjänster. Ett exempel:
								Baserad på information som patient själv lämnar vid anmälan om
								intresse att bli patient hos folktandvården, kan vi utföra
								preliminära prediktioner om tillhörande riskgrupp.
							</Typography>
							<Typography variant="body2" paragraph>
								Med preliminär riskgrupp, kan vi sedan gora bättre
								prioriteringar och simulera bättre estimat om framtida
								resursbehov.
							</Typography>
						</div>

						<div style={{ backgroundColor: COLORS[2] }} className="box">
							<Typography variant="h5" className="title">
								Optimera
							</Typography>
							<Typography variant="body2" paragraph>
								Varje uppgift kan delas upp i delmoment. Varje delmoment ska
								utföras av en specifik resurs. Tillgång på resurser variar över
								tid. Schemaläggning handlar om att fördela begränsade resurser
								på uppgifter och delmoment så att fler uppgifter kan utföras på
								kortast möjliga tid.
							</Typography>
							<Typography variant="body2" paragraph>
								Sownders Schemaläggningsmotor kan realtidsuppdatera.
							</Typography>
						</div>
					</div>
				</CardContent>
			</Card>
		</div>
	);
};

export default Splash;
