import { ResponsivePie } from '@nivo/pie';
import chroma from 'chroma-js';

export interface PieChartData {
	id: string;
	label: string;
	value: string | number;
}

interface PieChartProps {
	data: Array<PieChartData>;
	palette: Array<string>;
	showLegend?: true;
	cb?: (data: any) => void;
}

export const PieChart = (props: PieChartProps) => (
	<ResponsivePie
		data={props.data}
		margin={{ top: 40, right: 80, bottom: 80, left: 80 }}
		innerRadius={0.5}
		padAngle={0.7}
		cornerRadius={3}
		colors={props.palette}
		borderWidth={1}
		borderColor={{ from: 'color', modifiers: [['darker', 0.2]] }}
		animate={true}
		legends={
			props.showLegend
				? [
						{
							anchor: 'bottom',
							direction: 'row',
							translateY: 56,
							itemWidth: 80,
							itemHeight: 18,
							symbolSize: 18,
							symbolShape: 'circle',
							itemTextColor: '#111',
							effects: [
								{
									on: 'hover',
									style: {
										itemTextColor: '#000',
									},
								},
							],
						},
				  ]
				: undefined
		}
		arcLabelsSkipAngle={10}
		arcLabelsTextColor={(d) =>
			chroma.contrast(d.color, '#fff') < 4.5 ? '#000' : '#fff'
		}
		onClick={props.cb}
	/>
);
