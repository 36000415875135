import { useContext, useState } from 'react';
import { Box, Paper, Typography } from '@mui/material';
import { PointTooltipProps, ResponsiveLine } from '@nivo/line';
import axios, { AxiosResponse } from 'axios';
import { useEffect } from 'react';
import { styles } from '../../theme';
import { PageHeader } from '../common/AppLayout';
import { useTranslation } from 'react-i18next';
import { AppThemeContext } from '../../contexts/Providers';

interface TSeries {
	x: string;
	y: number;
}

interface ServerQueueResponse {
	EMERGENCY: Array<TSeries>;
	DOUBLE_PRIORITY: Array<TSeries>;
	SINGLE_PRIORITY: Array<TSeries>;
	NO_PRIORITY: Array<TSeries>;
}

interface ServerQueueLengthResponse {
	data: Array<TSeries>;
	id: string;
}

interface GraphData {
	id: string;
	data: Array<TSeries>;
	max?: number;
}

interface GraphDataState {
	queueLength: GraphData & { max: number };
	incCombined: GraphData;
	incomingEmergencyPriority: GraphData;
	incomingDoublePriority: GraphData;
	incomingSinglePriority: GraphData;
	incomingNoPriority: GraphData;
	ackCombined: GraphData;
	ackEmergencyPriority: GraphData;
	ackDoublePriority: GraphData;
	ackSinglePriority: GraphData;
	ackNoPriority: GraphData;
	incMax: number;
	ackMax: number;
}

const conv = (item: any): TSeries => {
	return { x: item.date, y: item.value };
};

const convertTimeSeries = (resp: AxiosResponse<ServerQueueResponse>) => {
	return {
		EMERGENCY: resp.data.EMERGENCY.map(conv),
		DOUBLE_PRIORITY: resp.data.DOUBLE_PRIORITY.map(conv),
		SINGLE_PRIORITY: resp.data.SINGLE_PRIORITY.map(conv),
		NO_PRIORITY: resp.data.NO_PRIORITY.map(conv),
	};
};

export default function SchedulerQueue() {
	const { t } = useTranslation();
	const defaultPalette = useContext(AppThemeContext).palette;

	const palette = [
		defaultPalette.colors[4],
		defaultPalette.colors[3],
		defaultPalette.colors[2],
		defaultPalette.colors[1],
		'#ccc',
	];

	const [graphData, setGraphData] = useState<GraphDataState>({
		queueLength: { id: '', data: [], max: 100 },
		incCombined: { id: '', data: [] },
		incomingEmergencyPriority: { id: '', data: [] },
		incomingDoublePriority: { id: '', data: [] },
		incomingSinglePriority: { id: '', data: [] },
		incomingNoPriority: { id: '', data: [] },
		ackCombined: { id: '', data: [] },
		ackEmergencyPriority: { id: '', data: [] },
		ackDoublePriority: { id: '', data: [] },
		ackSinglePriority: { id: '', data: [] },
		ackNoPriority: { id: '', data: [] },
		incMax: 0,
		ackMax: 0,
	});

	const [loaded, setLoaded] = useState(false);

	const commonOpts = {
		pointLabelYOffset: 0,
		animate: true,
		margin: {
			bottom: 50,
			left: 50,
			right: 50,
			top: 5,
		},
		enableArea: true,
		areaOpacity: 0.1,
		xFormat: 'time:%Y-%m-%d',
		enablePointLabel: true,
		pointBorderWidth: 1,
		pointBorderColor: {
			from: 'color',
			modifiers: [['darker', 0.7]],
		},
		useMesh: true,
	};

	useEffect(() => {
		axios
			.all([
				axios.get('/referral-overview'),
				axios.get('/queue-size'),
				axios.get('/queue-length'),
			])
			.then(
				axios.spread((...res) => {
					const length: AxiosResponse<ServerQueueLengthResponse> = res[2];

					// incoming referals
					const {
						EMERGENCY: incEmergency,
						DOUBLE_PRIORITY: incDoublePriority,
						SINGLE_PRIORITY: incSinglePriority,
						NO_PRIORITY: incNoPriority,
					} = convertTimeSeries(res[0]);
					const incCombined = incEmergency.map((item, index) => {
						return {
							x: item.x,
							y:
								item.y +
								incDoublePriority[index].y +
								incSinglePriority[index].y +
								incNoPriority[index].y,
						};
					});
					const incMax: number = incCombined.reduce(
						(acc: number, current: TSeries) => {
							return acc < current.y ? current.y : acc;
						},
						0
					);

					// Queue Size
					const {
						EMERGENCY: ackEmergency,
						DOUBLE_PRIORITY: ackDoublePriority,
						SINGLE_PRIORITY: ackSinglePriority,
						NO_PRIORITY: ackNoPriority,
					} = convertTimeSeries(res[1]);
					const ackCombined = ackEmergency.map((item, index) => {
						return {
							x: item.x,
							y:
								item.y +
								ackDoublePriority[index].y +
								ackSinglePriority[index].y +
								ackNoPriority[index].y,
						};
					}, 0);
					const ackMax: number = ackCombined.reduce(
						(acc: number, current: TSeries) => {
							return acc < current.y ? current.y : acc;
						},
						0
					);

					// queue-length
					const queue_length_data = length.data.data.map(conv);
					const queue_length_max: number = queue_length_data.reduce(
						(acc: number, current: TSeries) => {
							return Math.max(acc, current.y);
						},
						0
					);

					setGraphData({
						...graphData,
						incMax: incMax,
						ackMax: ackMax,
						incCombined: {
							id: 'Combined',
							data: incCombined,
						},
						incomingEmergencyPriority: {
							id: 'Emergency',
							data: incEmergency,
						},
						incomingDoublePriority: {
							id: 'Double Priority',
							data: incDoublePriority,
						},
						incomingSinglePriority: {
							id: 'Single Priority',
							data: incSinglePriority,
						},
						incomingNoPriority: {
							id: 'No Priority',
							data: incNoPriority,
						},
						ackCombined: {
							id: 'Combined',
							data: ackCombined,
						},
						ackEmergencyPriority: {
							id: 'Emergency',
							data: ackEmergency,
						},
						ackDoublePriority: {
							id: 'Double Priority',
							data: ackDoublePriority,
						},
						ackSinglePriority: {
							id: 'Single Priority',
							data: ackSinglePriority,
						},
						ackNoPriority: {
							id: 'No Priority',
							data: ackNoPriority,
						},
						queueLength: {
							id: 'queue length',
							data: queue_length_data,
							max: queue_length_max,
						},
					});
					setLoaded(true);
				})
			);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	function tooltip(data: PointTooltipProps) {
		return (
			<Paper
				style={{
					display: 'grid',
					gridTemplateColumns: 'auto 1fr auto',
					padding: '5px 0px',
				}}
			>
				<Box
					style={{
						backgroundColor: data.point.color,
						width: 12.5,
						height: 12.5,
						margin: 'auto 15px auto 10px',
					}}
				/>
				<Typography style={{ marginRight: '10px' }} variant="body1">
					{t(data.point.serieId as string)}
				</Typography>
				<Typography
					variant="body1"
					style={{ fontWeight: 'bold', marginRight: '15px' }}
				>
					{data.point.data.y}
				</Typography>
			</Paper>
		);
	}

	if (loaded) {
		return (
			<div style={styles.mainContent}>
				<PageHeader title={t('overview referrals')} />
				<div style={{ margin: '0.8rem' }}>
					<Paper style={{ height: 300, marginBottom: '1rem', padding: '1rem' }}>
						<Typography variant="h6">{t('incoming referrals')}</Typography>
						<ResponsiveLine
							{...commonOpts}
							data={[
								graphData.incomingEmergencyPriority,
								graphData.incomingDoublePriority,
								graphData.incomingSinglePriority,
								graphData.incomingNoPriority,
								graphData.incCombined,
							]}
							xScale={{
								type: 'time',
								format: '%Y-%m-%d',
								useUTC: false,
								precision: 'day',
							}}
							yScale={{
								type: 'linear',
								stacked: false,
								min: 0,
								max: graphData.incMax * 1.25,
							}}
							axisLeft={{
								legend: t('number of referrals'),
								legendOffset: -40,
								legendPosition: 'middle',
							}}
							axisBottom={{
								format: '%b %d',
								tickValues: 'every 2 week',
								tickSize: 10,
							}}
							tooltip={(data) => tooltip(data)}
							colors={palette}
							curve="monotoneX"
						/>
					</Paper>
					<Paper style={{ height: 300, marginBottom: '1rem', padding: '1rem' }}>
						<Typography variant="h6">{t('unscheduled referrals')}</Typography>
						<ResponsiveLine
							{...commonOpts}
							data={[
								graphData.ackEmergencyPriority,
								graphData.ackDoublePriority,
								graphData.ackSinglePriority,
								graphData.ackNoPriority,
								graphData.ackCombined,
							]}
							xScale={{
								type: 'time',
								format: '%Y-%m-%d',
								useUTC: false,
								precision: 'day',
							}}
							yScale={{
								type: 'linear',
								stacked: false,
								min: 0,
								max: graphData.ackMax * 1.25,
							}}
							axisLeft={{
								legend: t('number of referrals'),
								legendPosition: 'middle',
								legendOffset: -40,
							}}
							axisBottom={{
								format: '%b %d',
								tickValues: 'every 2 week',
								tickSize: 10,
							}}
							tooltip={(data) => tooltip(data)}
							colors={palette}
							curve="monotoneX"
						/>
					</Paper>
					<Paper style={{ height: 300, padding: '1rem' }}>
						<Typography variant="h6">{t('waiting time')}</Typography>
						<ResponsiveLine
							{...commonOpts}
							animate
							data={[graphData.queueLength]}
							colors={['#B8DCAB']}
							xScale={{
								type: 'time',
								format: '%Y-%m-%d',
								useUTC: false,
								precision: 'day',
							}}
							yScale={{
								type: 'linear',
								stacked: false,
								min: 0,
								max: Math.max(graphData.queueLength.max * 1.1, 100),
							}}
							axisLeft={{
								legend: t('number of days'),
								legendOffset: -40,
								legendPosition: 'middle',
								tickValues: 5,
							}}
							axisBottom={{
								format: '%b %d',
								tickValues: 'every 2 week',
								tickSize: 10,
							}}
							curve="monotoneX"
							tooltip={(data) => tooltip(data)}
							markers={[
								{
									axis: 'y',
									value: 90,
									lineStyle: {
										stroke: '#b0413e',
										strokeWidth: 2,
										strokeDasharray: 25,
									},
									legend: t('care guarantee 90 days'),
									legendOrientation: 'horizontal',
									legendPosition: 'bottom-left',
								},
							]}
						/>
					</Paper>
				</div>
			</div>
		);
	} else {
		return null;
	}
}
