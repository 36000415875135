import {
	Dialog,
	DialogContent,
	DialogTitle,
	DialogActions,
	Button,
	DialogContentText,
} from '@mui/material';

import Keycloak from 'keycloak-js';

interface LogoutDialogProps {
	keycloak: Keycloak.KeycloakInstance;
	closeWindow: () => void;
	open: boolean;
}

/**
 * Simple dialog that displays user information, and a logout-button
 * @param {Object} props
 */
const LogoutDialog = (props: LogoutDialogProps) => {
	const handleClose = () => {
		props.closeWindow();
	};

	const logout = () => {
		props.keycloak.logout();
	};

	return (
		<Dialog open={props.open} onClose={handleClose}>
			<DialogTitle>Logga ut</DialogTitle>
			<DialogContent>
				<DialogContentText>
					Är du säker på att du vill logga ut?
				</DialogContentText>
			</DialogContent>

			<DialogActions>
				<Button
					onClick={handleClose}
					color="secondary"
					variant="contained"
					size="small"
				>
					Stanna
				</Button>
				<Button
					onClick={logout}
					color="primary"
					variant="contained"
					size="small"
				>
					Logga ut
				</Button>
			</DialogActions>
		</Dialog>
	);
};

export default LogoutDialog;
