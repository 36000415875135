import { ResponsiveBar } from "@nivo/bar";
import { graphTheme } from "../../common/GraphGlobalSettings";
import chroma from "chroma-js";
import { CartesianMarkerProps } from "@nivo/core";

interface BarChartProps {
  data: Array<any>;
  markerValue: number;
  onClick?: (data: any) => void;
  palette: Array<string>;
  keys: Array<string> | null;
  leftLegendText: string;
  markerText?: string;
  indexBy?: string | null;
}

/**
 * Barchart with tooltip
 * @param (Array) data for drawing graph
 * @param (Array) keys list of keys, to show distribution
 * @param (Array or func) palette, colors used for BarChart and tooltip
 */
export const BarChart = (props: BarChartProps) => {
  const {
    data,
    markerValue,
    markerText,
    onClick,
    palette,
    keys,
    leftLegendText,
    indexBy,
  } = props;
  const marker: CartesianMarkerProps =
    markerText && markerValue
      ? {
          axis: "y",
          value: markerValue,
          legend: markerText,
        }
      : { axis: "y", value: 0 };

  return (
    <ResponsiveBar
      data={data ? data : []}
      keys={keys ? keys : []}
      indexBy={indexBy ? indexBy : "clinic"}
      margin={{ top: 30, right: 90, bottom: 100, left: 100 }}
      padding={0.3}
      colors={palette}
      labelTextColor={(d) =>
        chroma.contrast(d.color, "#fff") >= 4.5 ? "#fff" : "#000"
      }
      groupMode="grouped"
      label={(d) => (d.value as number).toFixed(0)}
      borderWidth={0}
      axisBottom={{
        tickSize: 0,
        tickPadding: 10,
        tickRotation: 45,
      }}
      axisLeft={{
        tickSize: 0,
        tickPadding: 10,
        tickRotation: 0,
        legend: leftLegendText,
        legendPosition: "middle",
        legendOffset: -70,
      }}
      labelSkipHeight={40}
      labelSkipWidth={40}
      legends={[
        {
          dataFrom: "keys",
          anchor: "bottom-right",
          direction: "column",
          justify: false,
          translateX: 100,
          translateY: 0,
          itemsSpacing: 3,
          itemWidth: 100,
          itemHeight: 20,
          itemDirection: "left-to-right",
          itemOpacity: 0.85,
          symbolSize: 20,
          effects: [
            {
              on: "hover",
              style: {
                itemOpacity: 1,
              },
            },
          ],
        },
      ]}
      animate={true}
      markers={[marker]}
      onClick={onClick && onClick}
      theme={graphTheme}
      tooltipLabel={(data) => {
        data.formattedValue =
          Number.parseFloat(data.formattedValue).toFixed(2) + "%";
        return data.id.toString();
      }}
    />
  );
};
