import { useState, useContext } from 'react';
import {
	useTheme,
	Paper,
	Typography,
	Snackbar,
	SnackbarContent,
	TextField,
	Box,
} from '@mui/material';
import {
	OperatingUnit,
	OperatingUnits,
	OperationPostOP,
	Location,
} from '../page-specific/simulation/Models';
import { OperationDialog } from '../page-specific/simulation/OperationDialog';
import { PostOpGraph } from '../page-specific/simulation/PostOpGraph';
import { PatientFlowGraph } from '../page-specific/simulation/PatientFlowGraph';
import {
	CustomOperationDragLayer,
	OperationCardContainer,
} from '../page-specific/simulation/OperationCard';
import { minutesToHM } from '../page-specific/simulation/utils';
import { CheckCircle } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { initContainerData } from '../page-specific/simulation/Data';
import { AppThemeContext } from '../../contexts/Providers';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { styles } from '../../theme';
import { PageHeader } from '../common/AppLayout';

type Team = {
	id: string;
	staff: Array<{ name: string; profession: string }>;
};

enum SchedulingType {
	ELECTIVE,
	EMERGENCY,
}

type Operation = {
	name: string;
	schedulingType: SchedulingType;
	start: string;
	roomStart: string;
	roomEnd: string;
	end: string;
	destinationAfterOp: Location;
	destinationAfterPostOp: Location;
	postOpDuration: number;
	teams: Array<{ start: string; end: string; id: string; staff: Array<Team> }>;
};

const ROOM_START = 7;

export function HMToX(HM: string) {
	return (
		HM.split(':').reduce(
			(acc, cur, index) => acc + Number(cur) * (index === 0 ? 60 : 1),
			0
		) -
		ROOM_START * 60
	);
}

export function xToHM(x: number) {
	const hour = String(Math.floor(x / 60) + ROOM_START).padStart(2, '0');
	const minute = String(Math.floor(x % 60)).padStart(2, '0');
	return `${hour}:${minute}`;
}

export const postOpPatients = (
	arr: Array<OperationPostOP | Operation>,
	XStartOffset: number
) => {
	const stop = (21 - 8) * 60;
	const res: Array<{ x: string; y: number }> = [];
	const tmp: Array<number> = [];

	// Creating Y
	for (let time = 0; time <= stop; time++) tmp.push(0);

	// incrementing Y values
	arr
		.filter((op) => {
			if (op instanceof OperationPostOP)
				return (
					op.destinationAfterOp === Location.PostOp &&
					op.operatingUnit !== OperatingUnit.Underhall
				);
			else return op.destinationAfterOp === Location.PostOp;
		})
		.forEach((op) => {
			const end = op instanceof OperationPostOP ? op.opEnd : HMToX(op.end);
			for (let t = end; t <= end + op.postOpDuration; t++)
				if (t < stop) tmp[t] += 1;
		});

	// Merging X and Y
	tmp.forEach((value, index) =>
		res.push({
			x: `2001-01-01 ${minutesToHM(index, (7 + XStartOffset) * 60)}`,
			y: value,
		})
	);

	return res;
};

const OPERATINGROOMS = ['c1', 'c2', 'c3', 'c4'];
const WAITLIST = ['gyn', 'kir', 'ort', 'uro', 'break'];
const times = [
	'08:00',
	'09:00',
	'10:00',
	'11:00',
	'12:00',
	'13:00',
	'14:00',
	'15:00',
	'16:00',
	'17:00',
	'18:00',
	'19:00',
	'20:00',
	'21:00',
];

export default function PostOp(props: { translationBase: string }) {
	const { t } = useTranslation('translation', {
		keyPrefix: `${props.translationBase}`,
	});

	const { palette } = useContext(AppThemeContext);
	const muiTm = useTheme();
	const cardPalette = [
		palette.colors[2],
		palette.colors[1],
		palette.colors[3],
		palette.colors[0],
		muiTm.palette.grey[400],
	];

	// Data, OperationCards that will be rendered on page
	const [containers, setContainers] = useState<{
		[key: string]: Array<OperationPostOP>;
	}>(initContainerData());

	const [durations, setDurations] = useState<{ [key: string]: number }>(() => {
		const func = (acc: number, op: OperationPostOP) => acc + op.opDuration;
		return {
			c1: containers.c1.reduce(func, 0),
			c2: containers.c2.reduce(func, 0),
			c3: containers.c3.reduce(func, 0),
			c4: containers.c4.reduce(func, 0),
			gyn: containers.gyn.reduce(func, 0),
			kir: containers.kir.reduce(func, 0),
			ort: containers.ort.reduce(func, 0),
			uro: containers.uro.reduce(func, 0),
			break: containers.break.reduce(func, 0),
		};
	});

	const [dialogSettings, setDialogSettings] = useState<{
		open: boolean;
		selectedOp: OperationPostOP;
		column: string;
		arrayIndex: number;
		snackOpen: boolean;
	}>({
		open: false,
		/** selected operations that will be sent to OperationDialog */
		selectedOp: containers.gyn[0],
		/** selected column  */
		column: 'c1',
		/** array index of the selected operation */
		arrayIndex: 0,

		snackOpen: false,
	});

	const [loadLimit, setLoadLimit] = useState(6);

	const handleClickOpen = (op: OperationPostOP, col: string, index: number) => {
		setDialogSettings({
			...dialogSettings,
			selectedOp: op,
			open: true,
			arrayIndex: index,
			column: col,
		});
	};

	const handleCloseDialog = (newOp: OperationPostOP, update: boolean) => {
		if (update) {
			const { column, arrayIndex } = dialogSettings;

			containers[column][arrayIndex] = newOp;

			/** Update start and end times */
			const sum = containers[column]
				.slice(0, arrayIndex)
				.reduce((acc, item) => acc + item.opDuration, 0);
			let start = sum;
			containers[column]
				.slice(arrayIndex, containers[column].length)
				.forEach((item) => {
					const end = start + item.opDuration;
					item.opStart = start;
					item.opEnd = end;
					start = end;
				});
			setDialogSettings({
				...dialogSettings,
				selectedOp: newOp,
				open: false,
				snackOpen: true,
			});
		} else setDialogSettings({ ...dialogSettings, open: false });
	};

	const moveOperation = (
		sourceKey: string,
		sourceIndex: number,
		targetKey: string,
		targetIndex: number
	) => {
		const newContainers = { ...containers };
		const newDurations = { ...durations };

		newDurations[sourceKey] -= containers[sourceKey][sourceIndex].opDuration;
		newDurations[targetKey] += containers[sourceKey][sourceIndex].opDuration;

		const movedItem = newContainers[sourceKey].splice(sourceIndex, 1);
		newContainers[targetKey].splice(targetIndex, 0, ...movedItem);

		// Recalculates all the opStart and opEnd attributes
		[sourceKey, sourceKey].forEach((key) => {
			if (OPERATINGROOMS.includes(key) && newContainers[key].length > 0) {
				newContainers[key][0].opStart = 0;
				newContainers[key][0].opEnd =
					newContainers[key][0].opStart + newContainers[key][0].opDuration;
				for (let i = 1; i < newContainers[key].length; i++) {
					newContainers[key][i].opStart = newContainers[key][i - 1].opEnd;
					newContainers[key][i].opEnd =
						newContainers[key][i].opStart + newContainers[key][i].opDuration;
				}
			}
		});
		setDurations(newDurations);
		setContainers(newContainers);
	};

	return (
		<Box
			sx={{
				...styles.mainContent,
				display: 'flex',
				flexDirection: 'column',
				'& div': { textAlign: 'center' },
			}}
		>
			<PageHeader title={t('postop')} />

			<OperationDialog
				open={dialogSettings.open}
				op={dialogSettings.selectedOp}
				onClose={handleCloseDialog}
			/>
			<DndProvider backend={HTML5Backend}>
				<CustomOperationDragLayer />

				{/* Wait list */}
				<Typography align="center" variant="h5">
					{t('waiting list')}
				</Typography>
				<Box
					sx={{
						display: 'inline-grid',
						gridTemplateColumns: 'repeat(5, auto)',
						gap: (t) => t.spacing(2),
						margin: '0 auto',
						minWidth: (t) => t.breakpoints.values.lg,
					}}
				>
					{WAITLIST.map((key, index) => (
						<Paper
							key={key}
							sx={{
								backgroundColor: (t) =>
									t.palette.mode === 'light' ? '#eee' : '#aaa',
								border: '1px solid #bbb',
							}}
						>
							<Typography variant="body2">
								{t(OperatingUnits[index])}
							</Typography>
							<Box
								sx={{
									display: 'flex',
									margin: '0.5rem',
									overflowY: 'scroll',
									height: '131px', // shows 3 cards without scrollbar
								}}
							>
								<OperationCardContainer
									containerkey={key}
									operations={containers[key]}
									vertical={true}
									palette={cardPalette}
									operationClick={(op, i) => handleClickOpen(op, key, i)}
									moveOperation={(
										sourceKey,
										sourceIndex,
										targetKey,
										targetIndex
									) =>
										moveOperation(
											sourceKey,
											sourceIndex,
											targetKey,
											targetIndex
										)
									}
									sx={{ flex: '1 0 auto' }}
									translationBase={props.translationBase}
								/>
							</Box>
							<Typography variant="body2" component={Box}>
								{containers[key].length > 0 ? `Σ ${durations[key]} ` : `Σ 0 `}
								{t('minutes').toLowerCase()}
							</Typography>
						</Paper>
					))}
				</Box>

				{/* Timelines, horizontal containers */}
				<Typography
					align="center"
					variant="h5"
					sx={{ marginTop: (t) => t.spacing(4) }}
				>
					{t('surgery program')}
				</Typography>
				<Box
					sx={{
						display: 'grid',
						gridTemplateRows: 'repeat(3, 1fr)',
						gap: 0,
						mx: 'auto',
					}}
				>
					{OPERATINGROOMS.map((key) => (
						<Box sx={{ width: (t) => t.breakpoints.values.lg }} key={key}>
							<Typography align="left" variant="subtitle2">
								{`${t('operating room')} ${key}`}
							</Typography>
							<Box
								sx={{
									backgroundColor: (t) =>
										t.palette.mode === 'light' ? '#eee' : '#aaa',
									height: '35px',
									border: '1px solid #ccc',
									borderRadius: '4px',
								}}
							>
								<OperationCardContainer
									containerkey={key}
									operations={containers[key]}
									vertical={false}
									palette={cardPalette}
									operationClick={(op, i) => handleClickOpen(op, key, i)}
									moveOperation={(
										sourceKey,
										sourceIndex,
										targetKey,
										targetIndex
									) =>
										moveOperation(
											sourceKey,
											sourceIndex,
											targetKey,
											targetIndex
										)
									}
									widthBase={muiTm.breakpoints.values.md / (times.length * 60)}
									translationBase={props.translationBase}
									sx={{
										display: 'flex',
										height: '100%',
									}}
								>
									<Box
										sx={{
											borderLeft: '1px solid #999',
											display: 'flex',
											paddingLeft: '10px',
											color: '#888',
										}}
									>
										<Typography
											sx={{ fontStyle: 'italic', my: 'auto' }}
											variant="body2"
										>
											{`${durations[key]} ${t(
												'minutes end'
											).toLowerCase()} ${minutesToHM(durations[key])}`}
										</Typography>
									</Box>
								</OperationCardContainer>
							</Box>
						</Box>
					))}
				</Box>

				{/* Timeline */}
				<Box sx={{ display: 'flex', marginLeft: '88px' }}>
					{times.map((item) => (
						<Typography
							key={item}
							variant="body2"
							sx={{ width: `${muiTm.breakpoints.values.md / times.length}px` }}
						>
							{item}
						</Typography>
					))}
				</Box>
			</DndProvider>

			<Box sx={{ marginTop: (t) => t.spacing(4) }}>
				<Box sx={{ display: 'block' }}>
					<Typography variant="h5">{t('load graph')}</Typography>
					<TextField
						label={t('number of beds')}
						value={loadLimit}
						type="number"
						onChange={(e) => {
							const newValue = parseInt(e.target.value);
							if (newValue >= 0) setLoadLimit(newValue);
						}}
					/>
				</Box>
				<Box
					sx={{
						minWidth: (t) => t.breakpoints.values.lg,
						height: '300px',
						display: 'inline-block',
					}}
				>
					<PostOpGraph
						translationBase={`${props.translationBase}.postop`}
						palette={[palette.colors[2], palette.colors[4]]}
						data={{
							id: 'postOp',
							data: postOpPatients(
								[
									...containers.c1,
									...containers.c2,
									...containers.c3,
									...containers.c4,
								],
								0
							), // concat arrays
						}}
						limit={loadLimit}
						t={t}
					/>
				</Box>
			</Box>

			<Typography align="center" variant="h5">
				{t('patient flow graph')}
			</Typography>
			<Box
				sx={{
					height: '300px',
					minWidth: (t) => t.breakpoints.values.lg,
				}}
			>
				<PatientFlowGraph
					data={[
						...containers.c1,
						...containers.c2,
						...containers.c3,
						...containers.c4,
					]}
					palette={[
						palette.colors[7],
						palette.colors[0],
						palette.colors[4],
						palette.colors[2],
						palette.colors[3],
					]}
				/>
			</Box>

			<Snackbar
				open={dialogSettings.snackOpen}
				autoHideDuration={3000}
				onClose={() => {
					setDialogSettings({ ...dialogSettings, snackOpen: false });
				}}
			>
				<SnackbarContent
					sx={{ backgroundColor: 'green', color: '#fff' }}
					message={
						<Typography component="span">
							<CheckCircle /> Operationen är uppdaterad.
						</Typography>
					}
				/>
			</Snackbar>
		</Box>
	);
}
