import DatePicker from '@mui/lab/DatePicker';
import { Box, TextField, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

const format = 'yyyy-MM-dd'; // ISO 8601

interface DateRangeSelectorProps {
	startDate: Date;
	endDate: Date;
	onChange: (s: { start: Date; end: Date }) => void;
}

/**
 * Start and enddate selection, using ISO 8601
 * @param {Date} startDate starting date
 * @param {Date} endDate end date
 * @param {function} onChange callback function
 */
export const DateRangeSelector = (props: DateRangeSelectorProps) => {
	const { startDate, endDate, onChange } = props;
	const { t } = useTranslation();

	return (
		<Box
			sx={{ '& input': { margin: 0, padding: '0.25rem', textAlign: 'right' } }}
		>
			<Typography variant="subtitle1">{t('start')}</Typography>
			<DatePicker
				value={startDate}
				mask="____-__-__"
				onChange={(date) => {
					date && onChange({ start: date, end: endDate });
				}}
				renderInput={(params) => <TextField {...params} />}
				inputFormat={format}
				maxDate={endDate}
			/>
			<Typography variant="subtitle1">{t('end')}</Typography>
			<DatePicker
				value={endDate}
				mask="____-__-__"
				onChange={(date) => {
					date && onChange({ start: startDate, end: date });
				}}
				inputFormat={format}
				renderInput={(params) => <TextField {...params} />}
				minDate={startDate}
			/>
		</Box>
	);
};

interface DateSelectorProps {
	date: Date;
	onChange: (date: Date) => void;
}
/**
 * Allows user to set a single date
 * @param {Date} date single date
 * @param {function} onChange callback function
 */
export const DateSelector = (props: DateSelectorProps) => {
	const { date, onChange } = props;
	return (
		<DatePicker
			onChange={(date) => date && onChange(date)}
			value={date}
			mask="____-__-__"
			renderInput={(params) => <TextField {...params} />}
			inputFormat={format}
		/>
	);
};
