import { Fragment } from 'react';
import { ResponsiveBar } from '@nivo/bar';
import { graphTheme } from '../../common/GraphGlobalSettings';
import chroma from 'chroma-js';
import { useTranslation } from 'react-i18next';

interface ClinicBase {
	clinic: string;
	clinid_id: number;
}

export interface BarChartData extends ClinicBase {
	[key: string]: any;
}

interface BarChartProps {
	data: Array<BarChartData>;
	keys: Array<string>;
	palette: Array<string>;
	onClick?: (data: any) => void;
}
/**
 * Barchart with tooltip
 * @param (Array) data for drawing graph
 * @param (Array) keys list of keys, to show distribution
 * @param (Array or func) palette, colors used for BarChart and tooltip
 */
export const BarChart = (props: BarChartProps) => {
	const { t } = useTranslation();
	const { data, keys, palette, onClick } = props;
	// Calculating clinic sums
	const sums: Array<number> = [];
	let labelCount = 0;
	data.forEach((c) => {
		let partialSum = 0;
		let lCount = 0;
		Object.entries(c).forEach(([key, value]) => {
			if (key !== 'clinic' && key !== 'clinic_id') {
				partialSum += value;
				lCount += 1;
			}
		});
		if (lCount > labelCount) labelCount = lCount;
		sums.push(partialSum);
	});

	const tooltipDisplay = (data: any) => {
		const clinicName = data.data.clinic;
		return (
			<div
				style={{
					fontSize: 14,
					backgroundColor: '#444',
					padding: '0.3rem',
					color: '#fff',
				}}
			>
				<h4 style={{ marginTop: 0, color: '#eee' }}>FTV {clinicName}</h4>
				<div
					style={{
						display: 'grid',
						gridTemplateColumns: '30px 50px auto',
						gridRowGap: 4,
					}}
				>
					{keys
						.slice(0)
						.reverse()
						.map((key, index) => {
							return (
								<Fragment key={key}>
									<div
										style={{
											width: 20,
											height: 20,
											backgroundColor:
												palette[(labelCount - 1 - index) % palette.length],
										}}
									/>
									<div>{key}: </div>
									<div style={{ textAlign: 'right' }}>
										{data.data[key] ? data.data[key] : 0}
									</div>
								</Fragment>
							);
						})}
				</div>
				<span style={{ marginBottom: 0 }}>{`Totalt: ${sums[data.index]}`}</span>
			</div>
		);
	};

	return (
		<ResponsiveBar
			data={data ? data : []}
			keys={keys ? keys : []}
			indexBy="clinic"
			margin={{ top: 30, right: 90, bottom: 100, left: 100 }}
			padding={0.3}
			colors={palette}
			labelTextColor={(d) =>
				chroma.contrast(d.color, '#fff') < 4.5 ? '#000' : '#fff'
			}
			borderWidth={0}
			axisBottom={{
				tickSize: 0,
				tickPadding: 10,
				tickRotation: 45,
			}}
			axisLeft={{
				tickSize: 0,
				tickPadding: 10,
				tickRotation: 0,
				legend: t('number-of-patients'),
				legendPosition: 'middle',
				legendOffset: -70,
			}}
			labelSkipHeight={40}
			legends={[
				{
					dataFrom: 'keys',
					anchor: 'bottom-right',
					direction: 'column',
					justify: false,
					translateX: 100,
					translateY: 0,
					itemsSpacing: 3,
					itemWidth: 100,
					itemHeight: 20,
					itemDirection: 'left-to-right',
					itemOpacity: 0.85,
					symbolSize: 20,
					effects: [
						{
							on: 'hover',
							style: {
								itemOpacity: 1,
							},
						},
					],
				},
			]}
			animate={true}
			tooltip={tooltipDisplay}
			onClick={onClick ? (data) => onClick(data) : void 0}
			theme={graphTheme}
		/>
	);
};
