import React, { useState, createContext } from 'react';
import gql from 'graphql-tag';
import { ThemeProvider, createTheme } from '@mui/material/styles';

import { CLINIC_QUERY as CQ } from '../components/queries/DataQueries';
import { useQuery } from '@apollo/client';
import { ClinicOption } from '../components/common/types/Page';
import { altColors, baseColors } from '../theme';

const CLINIC_QUERY = gql`
  query clinics{
    ${CQ}
  }
`;

export const ClinicInformationContext = createContext<Array<ClinicOption>>([]);

export const ClinicsContextProvider = (props: {
	children: React.ReactNode;
}) => {
	const [clinics, setClinics] = useState<Array<ClinicOption>>([]);

	useQuery(CLINIC_QUERY, {
		onCompleted: (data) => {
			if (clinics.length === 0) {
				setClinics(
					data.ftv_clinic.map((item: any) => {
						return {
							id: item.id,
							value: item.name,
							label: item.name,
							region: item.region,
						};
					})
				);
			}
		},
	});

	return (
		<>
			{clinics && clinics.length > 0 && (
				<ClinicInformationContext.Provider value={clinics}>
					{props.children}
				</ClinicInformationContext.Provider>
			)}
		</>
	);
};

export type PaletteName = 'ftv' | 'sownder';
interface AppThemeInterface {
	palette: Palette;
	update?: (paletteName: PaletteName) => void;
}

interface Palette {
	paletteName: PaletteName;
	colors: Array<string>;
}

export const allPalettes: Array<Palette> = [
	{
		paletteName: 'ftv',
		colors: [
			baseColors.darkBlue,
			baseColors.lightBlue,
			baseColors.lightGreen,
			baseColors.orange,
			baseColors.red,
			baseColors.shiffer,
			baseColors.pink,
			baseColors.purple,
		],
	},
	{
		paletteName: 'sownder',
		colors: [
			altColors.blue.hex(),
			altColors.light_green.hex(),
			altColors.dark_green.hex(),
			altColors.yellow.hex(),
			altColors.red.hex(),
			altColors.purple.hex(),
			altColors.cyan.hex(),
			altColors.brown.hex(),
		],
	},
];

export const AppThemeContext = createContext<AppThemeInterface>({
	palette: allPalettes[0],
});

export const AppThemeProvider = (props: { children: React.ReactNode }) => {
	const [palette, setPalette] = useState<Palette>(allPalettes[1]);

	const update = (paletteName: PaletteName) => {
		switch (paletteName) {
			case 'ftv':
				setPalette(allPalettes[0]);
				break;
			case 'sownder':
				setPalette(allPalettes[1]);
				break;
		}
	};
	const theme = createTheme({
		palette: {
			primary: {
				main:
					palette.paletteName === 'sownder'
						? altColors.blue.hex()
						: baseColors.main,
			},
			secondary: {
				main:
					palette.paletteName === 'sownder'
						? altColors.blue.desaturate(1).hex()
						: baseColors.purple,
			},
			background: {
				default: '#eee',
			},
		},
	});

	return (
		<AppThemeContext.Provider value={{ palette: palette, update: update }}>
			<ThemeProvider theme={theme}>{props.children}</ThemeProvider>
		</AppThemeContext.Provider>
	);
};
