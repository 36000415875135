import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Typography, Paper, Tooltip, Fade, Button } from '@mui/material';
import Draggable from 'react-draggable';
import Select from 'react-select';
import { Room } from '../page-specific/scheduler/models';
import '../page-specific/scheduler/Scheduling.scss';
import SidePanel, {
	ActivitiesOpts,
	ActivitiesSelection,
	Option,
} from '../page-specific/scheduler/SidePanel';
import { altColors as palette, styles } from '../../theme';
import KPPGraph from '../page-specific/scheduler/KPPGraph';
import { PageHeader } from '../common/AppLayout';
import {
	Profession,
	ExaminationsData,
	Activity,
	Task,
} from '../page-specific/scheduler/SchedulerData';
import Spinner from '../common/Spinner';

interface ServerStaff {
	firstname: string;
	lastname: string;
	gender: 'MALE' | 'FEMALE';
	title: string;
	qualifications: Array<string>;
}

const optConv = (item: ServerStaff) => {
	const val = `${item.firstname} ${item.lastname}`;
	return { title: item.title, label: val, value: val };
};

const TL = () => {
	const HS = [7, 8, 9, 10, 11, 12, 13, 14, 15, 16];
	const maxHeight: number = HS.length * 60 + 100;
	const axisColor = '#777';
	return (
		<svg
			height={700}
			style={{ overflow: 'visible' }}
			viewBox={`-50 0 55 ${maxHeight}`}
		>
			<line
				x1={0}
				x2={0}
				y1={0}
				y2={HS.length * 60 + 30}
				stroke={axisColor}
				strokeWidth={1.5}
			/>
			{HS.map((item, index) => (
				<React.Fragment key={`${item}-${index}`}>
					<line
						x1={-5}
						x2={0}
						y1={(item - 7) * 60}
						y2={(item - 7) * 60}
						stroke={axisColor}
						strokeWidth={1.5}
					/>
					<text
						fontSize="smaller"
						fill={axisColor}
						textAnchor="end"
						x={-10}
						y={(item - 7) * 60 + 5}
					>{`${item}:00`}</text>
				</React.Fragment>
			))}
		</svg>
	);
};

const DOCTOR_COLOR: string = palette.blue.css();
const BMA_COLOR: string = palette.light_green.css();

export default function Scheduler() {
	const [loaded, setLoaded] = useState<boolean>(false);
	const [requestedOptimization, setRequestedOptimization] =
		useState<boolean>(false);
	const [opts, setOpts] = useState<ActivitiesOpts>({
		bmas: [],
		doctor: [],
		rooms: [],
	});

	// should always be 16
	const [rooms, setRooms] = useState([
		[
			// room 1
			ExaminationsData.EKO,
			ExaminationsData.Carotis,
			ExaminationsData.Spirometri,
			ExaminationsData.LTER,
			ExaminationsData.StressEKO,
		],
		[
			// room 2
			ExaminationsData.PeriferaTryck,
			ExaminationsData.ABT,
			ExaminationsData.TEE,
			ExaminationsData.Carotis,
			ExaminationsData.AProv,
			ExaminationsData.PeriferaTryck,
		],
		[
			// room 3
			ExaminationsData.Spirometri,
			ExaminationsData.AProv,
			ExaminationsData.TEE,
			ExaminationsData.ABT,
			ExaminationsData.Kärl,
		],
	]);

	const [selection, setSelection] = useState<ActivitiesSelection>({
		bmas: [undefined, undefined, undefined],
		doctor: null,
		rooms: [],
	});
	const [showDr, setShowDr] = useState<boolean>(false);

	const drawDoctor = () => {
		return (
			// <svg height={700} viewBox={`10 0 100 ${10 * 60 + 100}`} width='100%'>
			<svg height={700} width="100%">
				{rooms
					.flat()
					.flat()
					.map((activity) => activity.tasks)
					.flat()
					.filter((val) => val.profession === Profession.DOCTOR)
					.map((item, index) => (
						<rect
							key={`rect-${item.start}-${index}`}
							x={0}
							y={item.start}
							width={'100%'}
							height={item.duration}
							fill={DOCTOR_COLOR}
						/>
					))}
			</svg>
		);
	};

	const block = (
		activity: Activity,
		doctorName: string,
		bmaName: string | undefined,
		roomName: string,
		key: string
	) => {
		const duration = activity.tasks.reduce<number>((acc, item) => {
			return acc + item.duration;
		}, 0);

		return (
			<Draggable
				defaultPosition={{ x: 0, y: activity.tasks[0].start }}
				axis="y"
				bounds={{ top: 0, left: 0 }}
				key={key}
			>
				<Paper
					style={{
						margin: 0,
						position: 'absolute',
					}}
					elevation={0}
				>
					<Tooltip
						arrow
						placement="right-end"
						title={
							<CTooltip
								doctorName={doctorName}
								bmaName={bmaName ? bmaName : ''}
								roomName={roomName}
								procedureType={
									activity.type !== undefined ? activity.type : 'unknown'
								}
								start={activity.tasks[0].start + 7 * 60}
								duration={duration}
								pname={activity.pname}
								pnumber={activity.pnumber}
							/>
						}
					>
						<div
							style={{
								width: '251px',
								border: '2px solid #000',
								borderRadius: '3px',
							}}
						>
							<Typography
								variant="caption"
								component="div"
								style={{
									float: 'right',
									margin: '0.15rem',
									color:
										activity.tasks[0].profession === Profession.DOCTOR
											? '#fff'
											: '#000',
								}}
							>
								{activity.type}
							</Typography>
							{activity.tasks.map((item, index) => (
								<div
									key={`k-${index}`}
									className={
										item.profession === Profession.DOCTOR
											? 'doctor-div'
											: 'bma-div'
									}
									style={{
										height: item.duration,
										backgroundColor:
											item.profession === Profession.BMA
												? BMA_COLOR
												: DOCTOR_COLOR,
									}}
								/>
							))}
						</div>
					</Tooltip>
				</Paper>
			</Draggable>
		);
	};

	useEffect(() => {
		axios.all([axios.get('/staff'), axios.get('/room')]).then(
			axios.spread((res1, res2) => {
				setOpts({
					...opts,
					bmas: res1.data
						.filter((item: ServerStaff) => item.title === 'BMA')
						.map(optConv),
					doctor: res1.data
						.filter((item: ServerStaff) => item.title === 'DOCTOR')
						.map(optConv),
					rooms: res2.data.map((item: Room) => {
						return {
							value: item.name,
							label: `${item.name} ${item.room_id}`,
						};
					}),
				});
			})
		);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (requestedOptimization) {
			axios
				.post(
					'/model',
					rooms.map((room) =>
						room.map((act) => ({ id: act.id, tasks: act.tasks }))
					)
				)
				.then((res) => {
					// Blindly trusts that the response order is the same as request order.
					const newData: Array<Array<Activity>> = rooms.map((room, rIndex) => {
						return room.map((act, aIndex) => {
							return {
								...act,
								tasks: act.tasks.map((task, tIndex) => {
									const tmp: Task = res.data[rIndex][aIndex].tasks[tIndex];
									return {
										...task,
										start: tmp.start,
										end: tmp.end,
									};
								}),
							};
						});
					});

					setRooms(newData);
					setLoaded(true);
					setRequestedOptimization(false);
				});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [requestedOptimization]);

	const addBMASelection = (opt: Option, index: number) => {
		const newArr = selection.bmas;
		newArr[index] = opt;
		setSelection({ ...selection, bmas: newArr });
	};

	return (
		<div style={styles.mainContent}>
			<PageHeader title="Produktionsplanering" />

			<Paper className="scheduling-main">
				<div className="schedule-panel">
					<SidePanel
						opts={opts}
						selection={selection}
						setSelection={setSelection}
						requestedOptimization={requestedOptimization}
						setRequestedOptimization={setRequestedOptimization}
					/>

					<Fade in={loaded}>
						<div style={{ width: '100%', height: 400 }}>
							<KPPGraph />
						</div>
					</Fade>
				</div>

				<div
					style={{
						display: 'grid',
						gridTemplateColumns: '55px 256px 256px 256px auto',
						gap: '1rem',
					}}
				>
					<Typography
						variant="h6"
						style={{ gridColumnStart: 2, gridColumnEnd: 5 }}
					>
						Schema
					</Typography>
					<Fade
						style={{ gridColumnStart: 1, gridRowStart: 3 }}
						in={selection.rooms.length > 0}
					>
						{TL()}
					</Fade>
					{selection.rooms.map((rItem, rIndex) => (
						<React.Fragment key={rIndex}>
							<div style={{ gridRowStart: 2, gridColumnStart: rIndex + 2 }}>
								<BMASelect
									disabledBmasOpts={selection.bmas}
									bmasOpts={opts.bmas}
									index={rIndex}
									bmasSelected={selection.bmas[rIndex]}
									roomTitle={rItem.label}
									addSelection={addBMASelection}
								/>
							</div>
							<div
								className="schedule-background"
								style={{
									gridRowStart: 3,
									gridColumnStart: rIndex + 2,
									height: 700,
								}}
							>
								{!loaded && requestedOptimization && <Spinner />}
								{loaded &&
									rIndex < rooms.length &&
									rooms[rIndex].flat().map((activity, index) =>
										block(
											activity,
											selection.doctor ? selection.doctor.label : '',
											selection.bmas[rIndex]?.label,
											// 'BMA',
											rItem.label,
											`block-${activity.id}-${index}`
										)
									)}
							</div>
						</React.Fragment>
					))}
					<Fade in={loaded} style={{ gridRowStart: 2, gridColumnStart: 5 }}>
						<div style={{ margin: 'auto' }}>
							<Button
								style={{ marginBottom: '0.5rem' }}
								onClick={() => setShowDr(!showDr)}
							>
								{showDr === false ? 'Visa' : 'Dölj'} läkare
							</Button>
						</div>
					</Fade>
					<Fade in={showDr}>
						<div
							style={{ gridRowStart: 3, gridColumnStart: 5 }}
							className="schedule-background"
						>
							{drawDoctor()}
						</div>
					</Fade>
				</div>
			</Paper>
		</div>
	);
}

interface CToolTipProps {
	roomName: string;
	bmaName: string;
	doctorName: string;
	procedureType: string;
	start: number;
	duration: number;
	pname: string;
	pnumber: string;
}

function CTooltip(props: CToolTipProps) {
	return (
		<div style={{ textAlign: 'left' }}>
			<Typography component="table">
				<tbody>
					<tr>
						<td>Utförare</td>
						<td>{props.bmaName}</td>
					</tr>
					<tr>
						<td>Rum</td>
						<td>{props.roomName}</td>
					</tr>
					<tr>
						<td>Patient</td>
						<td>{props.pname}</td>
					</tr>
					<tr>
						<td>Personnummer</td>
						<td>{props.pnumber}</td>
					</tr>
					<tr>
						<td>Typ</td>
						<td>{props.procedureType}</td>
					</tr>
					<tr>
						<td>Läkare</td>
						<td>{props.doctorName}</td>
					</tr>
					<tr>
						<td>Starttid</td>
						<td>{`${String(Math.floor(props.start / 60)).padStart(
							2,
							'0'
						)}:${String(props.start % 60).padStart(2, '0')}`}</td>
					</tr>
					<tr>
						<td>Estimerad tid</td>
						<td>{props.duration} minuter</td>
					</tr>
				</tbody>
			</Typography>
		</div>
	);
}

interface BMASelectProps {
	bmasOpts: Array<Option>;
	disabledBmasOpts: Array<Option | undefined>;
	bmasSelected: Option | undefined;
	roomTitle: string;
	index: number;
	addSelection: (opt: Option, index: number) => void;
}

function BMASelect(props: BMASelectProps) {
	return (
		<div style={{ margin: '0.5rem auto', height: '100px' }}>
			<Typography variant="subtitle1">{props.roomTitle}</Typography>
			<Select
				isOptionDisabled={(option) =>
					props.disabledBmasOpts.findIndex(
						(disabledOption) =>
							disabledOption !== undefined &&
							disabledOption.value === option.value
					) !== -1
				}
				options={props.bmasOpts}
				theme={(theme) => ({
					...theme,
					colors: {
						...theme.colors,
						primary: BMA_COLOR,
						primary25: palette.light_green.alpha(0.3).css(),
					},
				})}
				value={props.bmasSelected}
				onChange={(opt) => opt && props.addSelection(opt, props.index)}
				placeholder="Välj BMA"
			/>
		</div>
	);
}
