import { useState, useEffect, useContext } from 'react';
import {
	Card,
	CardContent,
	CardHeader,
	FormControl,
	Fade,
	RadioGroup,
	Radio,
	FormControlLabel,
	TextField,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { DatePicker } from '@mui/lab';
import Select from 'react-select';
import './gridLayout.scss';
import {
	PageHeader,
	FormLabel,
	GraphContainer,
	HelpText,
} from '../common/AppLayout';
import { BarChart } from '../page-specific/staff-income/StaffIncomeGraph';
import { altColors, styles } from '../../theme';
import { useLazyQuery } from '@apollo/client';
import { ClinicSelector } from '../common/ClinicSelector';
import {
	AppThemeContext,
	ClinicInformationContext,
} from '../../contexts/Providers';
import { baseColors } from '../../theme';
import {
	INCOME_AGGREG_QUERY,
	INCOME_SINGLECLINIC_QUERY,
} from '../queries/StaffQueries';

const graphPalettes = {
	ftv: {
		tdl: [baseColors.lightBlue, baseColors.darkBlue],
		hyg: [baseColors.pink, baseColors.purple],
	},
	sownder: {
		tdl: [altColors.light_green.hex(), altColors.dark_green.hex()],
		hyg: [altColors.purple.hex(), altColors.cyan.hex()],
	},
};

type BackendData = {
	tdl: Array<any>;
	hyg: Array<any>;
};

function StaffIncome() {
	const { t } = useTranslation();
	const clinics = useContext(ClinicInformationContext);
	const { palette } = useContext(AppThemeContext);

	const [selectedDate, handleDateChange] = useState(new Date());
	const [mainGraphData, setMainGraphData] = useState<BackendData>({
		tdl: [],
		hyg: [],
	});
	const [singleClinicGraphData, setSingleClinicGraphData] =
		useState<BackendData>({ tdl: [], hyg: [] });
	const [selectedClinics, setSelectedClinics] = useState(clinics);
	const [selectedSingleClinic, setSelectedSingleClinic] = useState(null);
	const [displayValue, setDisplayValue] = useState('avg');

	const [getIncomeAggreg] = useLazyQuery(INCOME_AGGREG_QUERY, {
		onCompleted: (data) => {
			const tdlData: Array<any> = []; // tmp
			const hygData: Array<any> = []; // tmp

			selectedClinics.forEach((clinic: any) => {
				const clinicData = data.ftv_incomeaggregview.filter(
					(item: any) => clinic.label === item.name
				);
				const tdlObj = {
					clinic: clinic.label,
					profession: 'tdl',
					adultavg: 0,
					adultincome: 0,
					adultduration: 0,
					childavg: 0,
					childincome: 0,
					childduration: 0,
				};
				const hygObj = {
					clinic: clinic.label,
					profession: 'hyg',
					adultavg: 0,
					adultincome: 0,
					adultduration: 0,
					childavg: 0,
					childincome: 0,
					childduration: 0,
				};

				// tdl
				clinicData
					.filter((item: any) => item.profession === 'tdl')
					.forEach((item: any) => {
						if (item.adults) {
							tdlObj['adultavg'] = item.avgincome;
							tdlObj['adultincome'] = item.monthincome;
							tdlObj['adultduration'] = item.monthduration;
						} else {
							tdlObj['childavg'] = item.avgincome;
							tdlObj['childincome'] = item.monthincome;
							tdlObj['childduration'] = item.monthduration;
						}
					});

				// hyg
				clinicData
					.filter((item: any) => item.profession === 'hyg')
					.forEach((item: any) => {
						if (item.adults) {
							hygObj['adultavg'] = item.avgincome;
							hygObj['adultincome'] = item.monthincome;
							hygObj['adultduration'] = item.monthduration;
						} else {
							hygObj['childavg'] = item.avgincome;
							hygObj['childincome'] = item.monthincome;
							hygObj['childduration'] = item.monthduration;
						}
					});

				tdlData.push(tdlObj);
				hygData.push(hygObj);
			});

			setMainGraphData({ tdl: tdlData, hyg: hygData });
		},
		fetchPolicy: 'network-only', // on page swap, without this setting graphql tries to get cached data that doesn't exist
	});

	const [getSingleClinic] = useLazyQuery(INCOME_SINGLECLINIC_QUERY, {
		onCompleted: (data) => {
			// tdl
			const tdlArr = data.adult
				.filter((item: any) => item.profession === 'tdl')
				.map((item: any, index: any) => {
					const child = data.children[index];
					return {
						clinic: item.name,
						clinicid: item.clinicid,
						abbrev: item.abbrev,
						adultavg: item.avgincome,
						adultduration: item.monthduration,
						adultincome: item.monthincome,
						childavg: child.avgincome,
						childduration: child.monthduration,
						childincome: child.monthincome,
					};
				});

			// hyg
			const hygArr = data.adult
				.filter((item: any) => item.profession === 'hyg')
				.map((item: any, index: any) => {
					const child = data.children[index];
					return {
						clinic: item.name,
						clinicid: item.clinicid,
						abbrev: item.abbrev,
						adultavg: item.avgincome,
						adultduration: item.monthduration,
						adultincome: item.monthincome,
						childavg: child.avgincome,
						childduration: child.monthduration,
						childincome: child.monthincome,
					};
				});

			setSingleClinicGraphData({
				...singleClinicGraphData,
				tdl: tdlArr,
				hyg: hygArr,
			});
		},
		fetchPolicy: 'network-only', // on page swap, without this setting graphql tries to get cached data that doesn't exist
	});

	useEffect(() => {
		if (selectedClinics.length > 0)
			getIncomeAggreg({
				variables: { name: selectedClinics.map((item) => item.label) },
			});
	}, [selectedClinics, getIncomeAggreg]);

	useEffect(() => {
		if (selectedSingleClinic)
			getSingleClinic({ variables: { name: selectedSingleClinic } });
	}, [selectedSingleClinic, getSingleClinic]);

	const callbackSingleClinic = (data: any) => {
		setSelectedSingleClinic(data.indexValue);
	};

	const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setDisplayValue(event.target.value);
	};

	const transformKeys = () => {
		if (displayValue === 'avg') return ['adultavg', 'childavg'];
		else if (displayValue === 'duration')
			return ['adultduration', 'childduration'];
		else if (displayValue === 'income') return ['adultincome', 'childincome'];

		return null;
	};

	const legendText = () => {
		if (displayValue === 'avg') return 'Kr/tim';
		else if (displayValue === 'duration') return 'Antal timmar';
		else if (displayValue === 'income') return 'Kr';
		return '';
	};

	if (clinics.length) {
		const sharedGraphDataCommonProps = {
			keys: transformKeys(),
			leftLegendText: legendText(),
			markerText: displayValue === 'avg' ? 'Riktlinje' : '',
		};

		return (
			<div style={styles.mainContent}>
				<PageHeader title={t('income')} />

				<div id="gridColSplit">
					<div id="mainGrid">
						<GraphContainer
							id="graph"
							title={'Tandläkare'}
							subheader={'Klinikvis'}
						>
							{mainGraphData.tdl.length && selectedClinics.length ? (
								<BarChart
									data={mainGraphData.tdl}
									markerValue={2300}
									{...sharedGraphDataCommonProps}
									onClick={callbackSingleClinic}
									palette={graphPalettes[palette.paletteName].tdl}
								/>
							) : (
								HelpText('Välj klinik')
							)}
						</GraphContainer>
						<Fade in={selectedClinics.length > 0}>
							<GraphContainer
								id="graph"
								title={'Tandhygienist'}
								subheader={'Klinikvis'}
							>
								{mainGraphData.tdl.length ? (
									<BarChart
										data={mainGraphData.hyg}
										markerValue={1350}
										{...sharedGraphDataCommonProps}
										onClick={callbackSingleClinic}
										palette={graphPalettes[palette.paletteName].hyg}
									/>
								) : (
									HelpText('Välj klinik')
								)}
							</GraphContainer>
						</Fade>
						<Fade
							in={
								singleClinicGraphData.tdl.length > 0 &&
								selectedClinics.length > 0
							}
						>
							<GraphContainer
								id="graph"
								title={`Tandläkare ATV ${selectedSingleClinic}`}
								subheader={'Personal'}
							>
								{singleClinicGraphData.tdl.length ? (
									<BarChart
										data={singleClinicGraphData.tdl}
										markerValue={2300}
										{...sharedGraphDataCommonProps}
										indexBy={'abbrev'}
										palette={graphPalettes[palette.paletteName].tdl}
									/>
								) : (
									HelpText('Välj klinik')
								)}
							</GraphContainer>
						</Fade>

						<Fade
							in={
								singleClinicGraphData.hyg.length > 0 &&
								selectedClinics.length > 0
							}
						>
							<GraphContainer
								id="graph"
								title={`Tandhygienist ATV ${selectedSingleClinic}`}
								subheader={'Personal'}
							>
								{singleClinicGraphData.hyg.length ? (
									<BarChart
										data={singleClinicGraphData.hyg}
										markerValue={1350}
										{...sharedGraphDataCommonProps}
										indexBy="abbrev"
										palette={graphPalettes[palette.paletteName].hyg}
									/>
								) : (
									HelpText('Välj klinik')
								)}
							</GraphContainer>
						</Fade>
					</div>

					<div id="settings">
						<Card>
							<CardHeader title={t('settings')} />
							<CardContent>
								<FormControl>
									<FormLabel required>{'Välj år och månad'}</FormLabel>
									<DatePicker
										openTo="year"
										views={['year', 'month']}
										label="År och månad"
										value={selectedDate}
										renderInput={(params) => <TextField {...params} />}
										inputFormat="yyyy MMMM"
										onChange={(d) => d && handleDateChange(d)}
									/>

									<FormLabel required>{t('clinics')}</FormLabel>
									<ClinicSelector
										options={clinics}
										value={selectedClinics}
										updateSelection={setSelectedClinics}
										placeholder={t('select clinics')}
									/>

									<FormLabel required>{'Patientåldrar'}</FormLabel>
									<Select
										options={[
											{ label: '3-23', value: '3-23' },
											{ label: '24+', value: '24+' },
										]}
										isMulti={true}
										closeMenuOnSelect={false}
										menuPortalTarget={document.querySelector('body')}
									/>

									<FormLabel required>{'Enhet'}</FormLabel>
									<RadioGroup value={displayValue} onChange={handleChange}>
										<FormControlLabel
											value="avg"
											control={<Radio color="primary" />}
											label="Timintäkt"
										/>
										<FormControlLabel
											value="duration"
											control={<Radio color="primary" />}
											label="Totaltid"
										/>
										<FormControlLabel
											value="income"
											control={<Radio color="primary" />}
											label="Totalintäkt"
										/>
									</RadioGroup>
								</FormControl>
							</CardContent>
						</Card>
					</div>
				</div>
			</div>
		);
	}
	return null;
}

export default StaffIncome;
