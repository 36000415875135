import { withTranslation } from 'react-i18next';
import { ResponsiveBar } from '@nivo/bar';

import { graphTheme } from '../../common/GraphGlobalSettings';
import chroma from 'chroma-js';
import { CartesianMarkerProps } from '@nivo/core';

/**
 * Simple tooltip function with black background
 * @param {object} data used by Nivo
 */
const toolTip = (data: any) => {
	const clinicName = data.data.clinic;
	return (
		<div
			style={{
				fontSize: 14,
				backgroundColor: '#444',
				padding: '0.3rem',
				color: '#fff',
			}}
		>
			<h4 style={{ marginTop: 0 }}>{`FTV ${clinicName}`}</h4>
			<div
				style={{
					display: 'grid',
					gridTemplateColumns: '30px 160px auto',
					gridRowGap: 4,
				}}
			>
				<div style={{ width: 20, height: 20, backgroundColor: data.color }} />
				<div>
					<strong>{data.id}</strong>
				</div>
				<div>{data.value.toFixed(2)} månad(er).</div>
			</div>
		</div>
	);
};

// commonly used prop values for all graphs in this file
const commonProps = {
	indexBy: 'clinic',
	margin: { top: 8, right: 180, bottom: 80, left: 100 },
	borderWidth: 1,
	labelSkipWidth: 24,
	labelSkipHeight: 24,
	axisBottom: {
		tickSize: 5,
		tickPadding: 5,
		tickRotation: 45,
	},
	axisLeft: {
		tickSize: 5,
		tickPadding: 5,
		tickRotation: 0,
		legend: 'Antal månader',
		legendOffset: -50,
	},
	padding: 0.3,
	animate: true,
	theme: graphTheme,
	tooltip: (d: any) => toolTip(d),
};

/**
 * Creates a (partial) marker object used in graphs
 * @param {number} yValue
 * @param {string} color
 */
const createMarker = (yValue: any, color: any): CartesianMarkerProps => {
	return {
		axis: 'y',
		value: yValue,
		lineStyle: { stroke: color, strokeWidth: 1, strokeDasharray: '12, 8' },
		legendOrientation: 'horizontal',
	};
};

interface GraphData {
	clinic: string;
	[key: string]: any;
}

interface PatientRecallGraphProps {
	data: Array<GraphData>;
	keys: Array<string>;
	palette: Array<string>;
	group: string;
	onClick: (data: any, group: string) => void;
}

/**
 * Draws a bar chart with tooltip
 * @param {Array} data An array of data used to draw the graph
 * @param {Array} keys array of string describes the data-keys
 * @param {Array} palette array of strings in hex, representing color
 * @param {func} onClick callback function
 */
const PatientRecallGraph = (props: PatientRecallGraphProps) => {
	const { data, keys, palette, group, onClick } = props;

	return (
		<ResponsiveBar
			{...commonProps}
			data={data}
			keys={keys}
			groupMode="grouped"
			borderColor={{ from: 'color', modifiers: [['darker', 0.7]] }}
			colors={palette}
			axisRight={{
				tickValues: [12, 18, 24],
				tickSize: 5,
			}}
			axisLeft={{
				legendPosition: 'middle',
				legend: 'Antal månader',
				legendOffset: -50,
			}}
			labelTextColor={(d) => {
				return chroma.contrast(d.color, '#ffffff') < 4.5 ? '#000' : '#fff';
			}}
			label={(d) => (d.value as number).toFixed(1)}
			legends={[
				{
					dataFrom: 'keys',
					anchor: 'bottom-right',
					direction: 'column',
					justify: false,
					translateX: 150,
					translateY: 0,
					itemsSpacing: 2,
					itemWidth: 100,
					itemHeight: 20,
					itemDirection: 'left-to-right',
					itemOpacity: 0.85,
					symbolSize: 20,
					effects: [
						{
							on: 'hover',
							style: {
								itemOpacity: 1,
							},
						},
					],
				},
			]}
			markers={[
				createMarker(12, '#444'),
				createMarker(18, '#444'),
				createMarker(24, '#444'),
			]}
			onClick={(d) => onClick(d.id, group)}
		/>
	);
};

// Common marker props
const markerProps: CartesianMarkerProps = {
	axis: 'y',
	value: 0,
	lineStyle: { stroke: '#888', strokeWidth: 1 },
	legendOrientation: 'vertical',
	// legendOffsetX: -10,
	textStyle: { fill: '#888' },
};

interface DivergingGraphProps {
	data: Array<GraphData>;
	keys: Array<string>;
	selectedKey: string;
	palette: Array<string>;
}

/**
 * Draws a bar chart on both sides of y = 0
 * @param {String} selectedKey key
 * @param {Array} data array consisting of data used for drawing graph
 * @param {Array} palette array of colors
 */
export const DivergingGraph = (props: DivergingGraphProps) => {
	const { selectedKey, data, palette } = props;

	const getOffsetMonth = (key: string) => {
		if (key === 'riskgrupp19+0') return 24;
		else if (key === 'riskgrupp0') return 24;
		else if (key === 'riskgrupp1') return 18;
		else if (key === 'riskgrupp2') return 12;
		else return 0;
	};
	const offsetMonth = getOffsetMonth(selectedKey);

	const offsetData = data.map((item: any) => {
		return {
			clinic: item.clinic,
			[selectedKey]: item[selectedKey] - offsetMonth,
		};
	});

	const maxValue = offsetData.reduce((max: any, item: any) => {
		return Math.max(item[selectedKey], max);
	}, offsetData[0][selectedKey]);
	const minValue = offsetData.reduce((min: any, item: any) => {
		return Math.min(item[selectedKey], min);
	}, offsetData[0][selectedKey]);
	const absMax = Math.max(Math.abs(maxValue), Math.abs(minValue));

	const offsetAverage =
		offsetData
			.map((item: any) => item[selectedKey])
			.reduce((a: any, b: any) => a + b, 0) / offsetData.length;

	return (
		<ResponsiveBar
			{...commonProps}
			groupMode="grouped"
			data={offsetData}
			borderColor={{ from: 'color', modifiers: [['darker', 0.7]] }}
			keys={[selectedKey]}
			maxValue={absMax * 1.1}
			minValue={absMax * -1.1}
			enableGridX={true}
			enableGridY={false}
			innerPadding={1}
			colors={palette}
			axisLeft={{
				legendPosition: 'middle',
				legendOffset: -50,
				legend: 'Antal månader',
			}}
			labelTextColor={(d) => {
				return chroma.contrast(d.color, '#ffffff') < 4.5 ? '#000' : '#fff';
			}}
			label={(d) => (d.value ? d.value.toFixed(1) : d.formattedValue)}
			markers={[
				{
					...markerProps,
					legend: 'För tidigt',
					legendPosition: 'bottom-right',
				},
				{
					...markerProps,
					legend: 'För sent',
					legendPosition: 'top-right',
				},
				{
					axis: 'y',
					value: 0,
					lineStyle: { stroke: '#888', strokeWidth: 1 },
					// legendOrientation: 'vertical',
					textStyle: { fill: '#888' },
					legend: `Referens ${offsetMonth}`,
					legendPosition: 'right',
				},
				{
					axis: 'y',
					value: offsetAverage,
					lineStyle: {
						stroke: '#333',
						strokeWidth: 1,
						strokeDasharray: '12, 8',
					},
					legendOrientation: 'horizontal',
					textStyle: { fill: '#333', fontStyle: 'italic' },
					legend: 'Genomsnitt',
					legendPosition: 'top-right',
				},
			]}
		/>
	);
};

export default withTranslation()(PatientRecallGraph);
