import { useState, useContext } from 'react';

import {
	Card,
	CardContent,
	Paper,
	CardHeader,
	FormControl,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { addMonths } from 'date-fns';
import chroma from 'chroma-js';

import './gridLayout.scss';
import { PageHeader, FormLabel } from '../common/AppLayout';
import {
	PatientSurveyGraph,
	AnsHistoryChart,
} from '../page-specific/patient-survey/PatientSurveyGraph';
import { styles, baseColors, altColors } from '../../theme';
import { DateRangeSelector } from '../common/DateRangeSelector';
import { ClinicSelector, ClinicSelectorProps } from '../common/ClinicSelector';
import {
	AppThemeContext,
	ClinicInformationContext,
} from '../../contexts/Providers';
import { ClinicOption } from '../common/types/Page';

const ftvPalette = [
	baseColors.lightBlue,
	chroma.mix(baseColors.darkBlue, '#000').hex(),
];

const sownderPalette = [
	// altColors.light_green.hex(),
	altColors.blue.hex(),
	altColors.yellow.hex(),
];

const data = [
	{
		id: 'Q1',
		yes: 186,
		no: 11,
		description:
			'Är du delaktig i besluten beträffande din tandvård/behandling i den utsträckning du önskar?',
	},
	{
		id: 'Q2',
		yes: 170,
		no: 27,
		description:
			'Fick du i samband med din undersökning information om vad hela din tandbehandling skulle kosta?',
	},
	{
		id: 'Q3',
		yes: 152,
		no: 45,
		description: 'Kände du dig bemött med respekt',
	},
	{
		id: 'Q4',
		yes: 177,
		no: 20,
		description:
			'Fick du tillräckigt med information i din tandvård/behandling',
	},
	{
		id: 'Q5',
		yes: 132,
		no: 65,
		description: 'Fick du råd om hur du ska sköta dina tänder?',
	},
];

const ansHistory = [
	{
		id: 'history',
		data: [
			{ x: '2019-01-01', y: 2 },
			{ x: '2019-01-02', y: 3 },
			{ x: '2019-01-03', y: 4 },
			{ x: '2019-01-04', y: 5 },
			{ x: '2019-01-05', y: 6 },
			{ x: '2019-01-06', y: 3 },
			{ x: '2019-01-07', y: 2 },
			{ x: '2019-01-08', y: 2 },
			{ x: '2019-01-09', y: 2 },
			{ x: '2019-01-10', y: 5 },
			{ x: '2019-01-11', y: 6 },
			{ x: '2019-01-12', y: 8 },
			{ x: '2019-01-13', y: 9 },
			{ x: '2019-01-14', y: 10 },
			{ x: '2019-01-15', y: 11 },
			{ x: '2019-01-16', y: 13 },
			{ x: '2019-01-17', y: 14 },
			{ x: '2019-01-18', y: 16 },
			{ x: '2019-01-19', y: 12 },
			{ x: '2019-01-20', y: 10 },
			{ x: '2019-01-21', y: 9 },
			{ x: '2019-01-22', y: 8 },
			{ x: '2019-01-23', y: 11 },
			{ x: '2019-01-24', y: 10 },
			{ x: '2019-01-25', y: 12 },
			{ x: '2019-01-26', y: 13 },
			{ x: '2019-01-27', y: 13 },
			{ x: '2019-01-28', y: 12 },
			{ x: '2019-01-29', y: 10 },
			{ x: '2019-01-30', y: 9 },
			{ x: '2019-01-31', y: 9 },
		],
	},
	{
		id: 'answered',
		data: [
			{ x: '2019-01-01', y: 1 },
			{ x: '2019-01-02', y: 2 },
			{ x: '2019-01-03', y: 3 },
			{ x: '2019-01-04', y: 3 },
			{ x: '2019-01-05', y: 3 },
			{ x: '2019-01-06', y: 2 },
			{ x: '2019-01-07', y: 1 },
			{ x: '2019-01-08', y: 1 },
			{ x: '2019-01-09', y: 1 },
			{ x: '2019-01-10', y: 3 },
			{ x: '2019-01-11', y: 5 },
			{ x: '2019-01-12', y: 5 },
			{ x: '2019-01-13', y: 4 },
			{ x: '2019-01-14', y: 3 },
			{ x: '2019-01-15', y: 6 },
			{ x: '2019-01-16', y: 5 },
			{ x: '2019-01-17', y: 6 },
			{ x: '2019-01-18', y: 5 },
			{ x: '2019-01-19', y: 8 },
			{ x: '2019-01-20', y: 6 },
			{ x: '2019-01-21', y: 4 },
			{ x: '2019-01-22', y: 1 },
			{ x: '2019-01-23', y: 6 },
			{ x: '2019-01-24', y: 7 },
			{ x: '2019-01-25', y: 6 },
			{ x: '2019-01-26', y: 7 },
			{ x: '2019-01-27', y: 8 },
			{ x: '2019-01-28', y: 8 },
			{ x: '2019-01-29', y: 6 },
			{ x: '2019-01-30', y: 6 },
			{ x: '2019-01-31', y: 3 },
		],
	},
];

function PatientSurvey() {
	const { t } = useTranslation();
	const clinics = useContext(ClinicInformationContext);
	const { palette } = useContext(AppThemeContext);

	const [dateRange, setDateRange] = useState({
		start: addMonths(new Date(), -3),
		end: new Date(),
	});
	const [selectedClinics, setSelectedClinics] =
		useState<Array<ClinicOption>>(clinics);

	const avg =
		ansHistory[0].data.reduce((acc, item) => acc + item.y, 0) /
		ansHistory[0].data.length;

	if (clinics && clinics.length) {
		const clinicProps: ClinicSelectorProps = {
			options: clinics,
			value: selectedClinics,
			updateSelection: setSelectedClinics,
			placeholder: t('select clinics'),
		};

		const graphPalette =
			palette.paletteName === 'sownder' ? sownderPalette : ftvPalette;

		return (
			<div style={styles.mainContent}>
				<PageHeader title={t('patient-survey')} />

				<div id="gridColSplit">
					<div id="mainGrid">
						<Paper id="graph" style={{ height: 400 }}>
							<PatientSurveyGraph
								data={data}
								palette={graphPalette}
								avg={avg}
							/>
						</Paper>

						<Card style={{ height: '20rem', gridColumn: '1 / 3' }}>
							<CardHeader title="Svarsfrekvens" />
							<CardContent
								style={{ margin: '1rem', padding: '0', height: '12rem' }}
							>
								<AnsHistoryChart data={ansHistory} palette={graphPalette} />
							</CardContent>
						</Card>
					</div>

					<div id="settings">
						<Card>
							<CardHeader title={t('settings')} />
							<CardContent>
								<FormControl>
									<FormLabel required>{t('date-interval')}</FormLabel>
									<DateRangeSelector
										onChange={(s) =>
											setDateRange({ start: s.start, end: s.end })
										}
										startDate={dateRange.start}
										endDate={dateRange.end}
									/>

									<FormLabel required>{t('clinics')}</FormLabel>
									<ClinicSelector {...clinicProps} />
								</FormControl>
							</CardContent>
						</Card>
					</div>
				</div>
			</div>
		);
	}
	return null;
}

export default PatientSurvey;
