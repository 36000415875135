import {
	ApolloClient,
	NormalizedCacheObject,
	HttpLink,
	from,
	InMemoryCache,
	ServerParseError,
	ServerError,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

type ApolloConfigProps = {
	keycloak: Keycloak.KeycloakInstance;
	setApolloClient: (client: ApolloClient<NormalizedCacheObject>) => void;
};

export function ApolloConfig(props: ApolloConfigProps) {
	let navigate = useNavigate();
	const [initialized, setInitialized] = useState(false);

	useEffect(() => {
		if (!initialized) {
			const link = new HttpLink({
				uri: process.env.REACT_APP_HASURA_ENDPOINT + '/v1/graphql',
			});

			const authLink = setContext((_, { headers }) => {
				return {
					headers: {
						...headers,
						Authorization: props.keycloak.token
							? `Bearer ${props.keycloak.token}`
							: '',
					},
				};
			});

			const errorLink = onError((error) => {
				if (error.graphQLErrors) {
					let navigated = false;
					for (let err of error.graphQLErrors) {
						if (
							!navigated &&
							err.message.includes('JWTExpired') &&
							err.extensions.code === 'invalid-jwt'
						) {
							navigate('apollo401');
							navigated = true;
							break;
						}
					}

					if (!navigated) navigate('apollo500');
				}

				if (error.networkError) {
					const err = error.networkError as ServerParseError | ServerError;
					if (err.statusCode !== undefined) {
						if (err.statusCode === 404) navigate('apollo404');
					}
				}
			});

			// SetInterval is used for now because of limitations in the way apollo currently handles errors,
			// (RetryLink works for network errors but not for GraphQL errors, and GraphQL always returning a status code of 200 even on errors)
			// making it too time consuming as of this moment to implementent a proper refresh of the access token.
			setInterval(() => props.keycloak.updateToken(60), 45000);

			props.setApolloClient(
				new ApolloClient({
					link: from([authLink, errorLink, link]),
					cache: new InMemoryCache(),
				})
			);

			setInitialized(true);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return <></>;
}
