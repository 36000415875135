import { useState, useContext, useEffect } from 'react';

import {
	Card,
	CardContent,
	CardHeader,
	Fade,
	FormControl,
	Paper,
	Switch,
	TableHead,
	TableRow,
	RadioGroup,
	FormControlLabel,
	Radio,
	Table,
	TableBody,
	TableCell,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { addMonths } from 'date-fns';
import Select from 'react-select';
import gql from 'graphql-tag';
import { useLazyQuery } from '@apollo/client';

import './gridLayout.scss';
import { PageHeader, FormLabel } from '../common/AppLayout';
import { styles } from '../../theme';
import { DateRangeSelector } from '../common/DateRangeSelector';
import { ClinicSelector, ClinicSelectorProps } from '../common/ClinicSelector';
import {
	AppThemeContext,
	ClinicInformationContext,
} from '../../contexts/Providers';
import { ClinicOption } from '../common/types/Page';
import {
	PatientProcedureGraph,
	TreatmentTimelineChart,
} from '../page-specific/patient-procedure/PatientProcedureGraph';
import { PieChart } from '../common/CommonGraphs';

const TREATMENTS_QUERY = gql`
	query MyQuery($start: date!, $end: date!) {
		ftv_get_treatmentstatview(
			args: { end_in: $end, start_in: $start }
			order_by: { clinicid: asc, codegroup: asc }
		) {
			clinicid
			clinicname
			codegroup
			freq
		}
	}
`;

const TREATMENTS_DETAILED_QUERY = gql`
	query TreatmentView(
		$start: date!
		$end: date!
		$clinicid: Int!
		$treatmenttypecode: String!
	) {
		ftv_treatmentview_grouped(
			where: {
				treatmentdate: { _gte: $start, _lte: $end }
				clinicid: { _eq: $clinicid }
				treatmenttypecode: { _like: $treatmenttypecode }
			}
		) {
			clinicid
			freq
			treatmentdate
			treatmenttypecode
		}
		incl_p: ftv_treatmentview_grouped_incl_p(
			where: {
				treatmentdate: { _gte: $start, _lte: $end }
				clinicid: { _eq: $clinicid }
				treatmenttypecode: { _like: $treatmenttypecode }
			}
		) {
			clinicid
			freq
			treatmentdate
			treatmenttypecode
		}
		types: ftv_treatmentview_grouped(
			where: {
				treatmentdate: { _gte: $start, _lte: $end }
				clinicid: { _eq: $clinicid }
				treatmenttypecode: { _like: $treatmenttypecode }
			}
			distinct_on: treatmenttypecode
		) {
			treatmenttypecode
		}
		types_incl_p: ftv_treatmentview_grouped_incl_p(
			where: {
				treatmentdate: { _gte: $start, _lte: $end }
				clinicid: { _eq: $clinicid }
				treatmenttypecode: { _like: $treatmenttypecode }
			}
			distinct_on: treatmenttypecode
		) {
			treatmenttypecode
		}
	}
`;

const treatmentTypeGroup = [
	{
		value: '100',
		label: '100 Undersökning, riskbedömning och hälsofrämjande åtgärder',
	},
	{ value: '200', label: '200 Sjukdomsförebyggande åtgärder' },
	{ value: '300', label: '300 Sjukdomsbehandlande åtgärder' },
	{ value: '400', label: '400 Kirurgiska åtgärder' },
	{ value: '500', label: '500 Rotbehandlingsåtgärder' },
	{ value: '600', label: '600 Bettfysiologiska åtgärder' },
	{ value: '700', label: '700 Reparativa åtgärder' },
	{ value: '800', label: '800 Protestiska åtgärder' },
	{ value: '900', label: '900 Tandreglering och utbytesåtgärder' },
	{ value: 'FB', label: 'FB Lokala åtgärder utan pris' },
	{ value: 'Ovriga', label: 'Övrigt' },
];

const customStyles = {
	control: (provided: any, state: any) => ({
		...provided,
		width: 268, // todo: remove hard-coded width, column size is 300
	}),
};

function PatientProcedure() {
	const clinics = useContext(ClinicInformationContext);
	const { t } = useTranslation();
	const { palette } = useContext(AppThemeContext);

	const [dateRange, setDateRange] = useState({
		start: addMonths(new Date(), -3),
		end: new Date(),
	});
	const [selectedClinics, setSelectedClinics] =
		useState<Array<ClinicOption>>(clinics);
	const [selectedTreatmentTypes, setSelectedTreatmentTypes] =
		useState<Array<any>>(treatmentTypeGroup);
	const [graphData, setGraphData] = useState<{
		main: Array<any>;
		timeline: Array<any>;
	}>({ main: [], timeline: [] });
	const [settings, setSettings] = useState<{
		isRelative: boolean;
		selectedClinic: any;
		selectedTreatmentCode: any;
	}>({
		isRelative: false,
		selectedClinic: null,
		selectedTreatmentCode: null,
		// include_p: true
	});
	const [include_p, setInclude_p] = useState(true);

	// Fetching treatments divided in series (100, 200, ...) for selected clinics, treatmentseries and dateRange
	const [getTreatment, { data: treatmentData, loading: treatmentDataLoading }] =
		useLazyQuery(TREATMENTS_QUERY, {
			onCompleted: (treatmentData) => {
				// for each clinic
				const tmpArr: Array<any> = [];
				selectedClinics.forEach((clinic) => {
					const sClinic = treatmentData.ftv_get_treatmentstatview.filter(
						(item: any) => item.clinicid === clinic.id
					);
					let localSum = 0;
					const tmpObject: { [key: string]: string | number } = {
						clinic: clinic.label,
						clinicid: clinic.id,
					};
					sClinic.forEach((item: any) => {
						tmpObject[item.codegroup] = item.freq;
						localSum += item.freq;
					});
					tmpObject.sum = localSum;
					tmpArr.push(tmpObject);
				});
				tmpArr.sort((a, b) => {
					if (a.clinic < b.clinic) return -1;
					else return 1;
				});
				setGraphData({ ...graphData, main: tmpArr });
			},
			fetchPolicy: 'cache-and-network',
		});

	const [getDetailedTreatment] = useLazyQuery(TREATMENTS_DETAILED_QUERY, {
		onCompleted: (treatmentData) => {
			const tmpArr: Array<any> = [];
			const tData =
				include_p === true ? treatmentData.types_incl_p : treatmentData.types;
			tData.forEach((type: any) => {
				const dataArr =
					include_p === true
						? treatmentData.incl_p
						: treatmentData.ftv_treatmentview_grouped;
				const sF = dataArr.filter(
					(item: any) => item.treatmenttypecode === type.treatmenttypecode
				);
				const tmpObj: { id: any; data: Array<any> } = {
					id: type.treatmenttypecode,
					data: [],
				};
				sF.forEach((item: any) => {
					tmpObj.data.push({ x: item.treatmentdate, y: item.freq });
				});
				tmpArr.push(tmpObj);
			});
			setGraphData({ ...graphData, timeline: tmpArr });
		},
	});

	// getting treatment info based on user-selections
	useEffect(() => {
		if (selectedTreatmentTypes && selectedTreatmentTypes.length) {
			getTreatment({
				variables: {
					start: dateRange.start,
					end: dateRange.end,
				},
			});
		}
	}, [selectedTreatmentTypes, selectedClinics, dateRange, getTreatment]);

	// Getting every treatment type for a specific series
	useEffect(() => {
		if (settings.selectedClinic && settings.selectedTreatmentCode) {
			const treatmentgroup = `${
				parseInt(settings.selectedTreatmentCode) / 100.0
			}%`;
			const clinicId = clinics.filter(
				(item: any) => item.value === settings.selectedClinic
			)[0].id;
			getDetailedTreatment({
				variables: {
					clinicid: clinicId,
					treatmenttypecode: treatmentgroup,
					start: dateRange.start,
					end: dateRange.end,
				},
			});
		}
	}, [
		settings.selectedTreatmentCode,
		settings.selectedClinic,
		dateRange,
		include_p,
		clinics,
		getDetailedTreatment,
	]);

	// Converts data to relative data (maxvalue is 100)
	const graphDataToRelative = () => {
		return graphData.main.map((item) => {
			const tmpObj: { [key: string]: string | number } = {
				clinic: item.clinic,
				clinicid: item.clinicid,
			};
			selectedTreatmentTypes.forEach((tt) => {
				const multiplier = item[tt.value] ? 100 / item.sum : 0;
				tmpObj[tt.value] = item[tt.value] * multiplier;
			});
			return tmpObj;
		});
	};

	const onClickMaingraph = (data: any) => {
		setSettings({
			...settings,
			selectedClinic: data.indexValue,
			selectedTreatmentCode: data.id,
		}); // clinicName
	};

	const onClickPieChart = (data: any) => {
		setSettings({ ...settings, selectedTreatmentCode: data.id }); // treatmentcode
	};

	if (clinics && clinics.length) {
		const clinicProps: ClinicSelectorProps = {
			options: clinics,
			value: selectedClinics,
			updateSelection: setSelectedClinics,
			placeholder: t('select clinics'),
		};

		return (
			<div style={styles.mainContent}>
				<PageHeader title={t('measure-statistics')} />

				<div id="gridColSplit">
					<div id="mainGrid">
						<Paper id="graph">
							<div style={{ display: 'flex', justifyContent: 'flex-end' }}>
								<FormLabel>Relativa staplar</FormLabel>
								<Switch
									checked={settings.isRelative}
									onChange={() =>
										settings.isRelative
											? setSettings({
													...settings,
													isRelative: false,
											  })
											: setSettings({ ...settings, isRelative: true })
									}
									value={settings.isRelative}
								/>
							</div>
							<div style={{ height: '33rem' }}>
								{graphData.main.length && selectedTreatmentTypes ? (
									<PatientProcedureGraph
										data={
											settings.isRelative
												? graphDataToRelative()
												: graphData.main
										}
										keys={selectedTreatmentTypes.map((item) => item.value)}
										isRelative={settings.isRelative}
										cb={onClickMaingraph}
										palette={palette.colors}
									/>
								) : (
									<em>Välj klinik och åtgärdstyp</em>
								)}
							</div>
						</Paper>

						<Fade
							in={settings.selectedClinic !== null && treatmentData !== null}
						>
							<Card>
								<CardHeader
									title={settings.selectedClinic ? settings.selectedClinic : ''}
								/>
								<CardContent style={{ height: '22rem' }}>
									{settings.selectedClinic && treatmentData && (
										<PieChart
											data={treatmentData.ftv_get_treatmentstatview
												.filter(
													(item: any) =>
														item.clinicname === settings.selectedClinic
												)
												.map((item: any) => {
													return {
														id: item.codegroup,
														label: item.codegroup,
														value: item.freq,
													};
												})}
											cb={onClickPieChart}
											palette={palette.colors}
										/>
									)}
								</CardContent>
							</Card>
						</Fade>

						{/* Table */}
						<Fade
							in={settings.selectedClinic !== null && !treatmentDataLoading}
						>
							<Card>
								<CardHeader title={`${settings.selectedClinic}`} />
								<CardContent style={{ height: '28rem' }}>
									<Table size="small">
										<TableHead>
											<TableRow>
												<TableCell>Åtgärdsserie</TableCell>
												<TableCell>Antal</TableCell>
											</TableRow>
										</TableHead>
										<TableBody>
											{treatmentData &&
												treatmentData.ftv_get_treatmentstatview
													.filter(
														(item: any) =>
															item.clinicname === settings.selectedClinic
													)
													.map((item: any) => (
														<TableRow key={item.codegroup}>
															<TableCell>{item.codegroup}</TableCell>
															<TableCell>{item.freq}</TableCell>
														</TableRow>
													))}
										</TableBody>
									</Table>
								</CardContent>
							</Card>
						</Fade>

						{/* Timeline chart */}
						<Card id="graph">
							<CardHeader
								title={
									settings.selectedClinic && settings.selectedTreatmentCode
										? `ATV ${settings.selectedClinic} - ${settings.selectedTreatmentCode}`
										: null
								}
							/>
							<CardContent style={{ height: '30rem' }}>
								{graphData.timeline.length && (
									<TreatmentTimelineChart
										data={graphData.timeline}
										palette={palette.colors}
										dateRange={dateRange}
									/>
								)}
							</CardContent>
						</Card>
					</div>

					<div id="settings">
						<Card>
							<CardHeader title={t('settings')} />
							<CardContent>
								<FormControl>
									<FormLabel required>{t('date-interval')}</FormLabel>
									<DateRangeSelector
										onChange={(s) =>
											setDateRange({ start: s.start, end: s.end })
										}
										startDate={dateRange.start}
										endDate={dateRange.end}
									/>

									<FormLabel required>{t('clinics')}</FormLabel>
									<ClinicSelector {...clinicProps} />

									<FormLabel required>Åtgärdsserier</FormLabel>
									<Select
										styles={customStyles}
										options={treatmentTypeGroup}
										value={selectedTreatmentTypes}
										onChange={(opts) => setSelectedTreatmentTypes(opts as any)}
										isMulti={true}
										closeMenuOnSelect={false}
										menuPortalTarget={document.querySelector('body')}
									/>

									<FormLabel required>
										<em>p</em>-postfix
									</FormLabel>
									<RadioGroup
										value={include_p}
										onChange={() => setInclude_p(!include_p)}
									>
										<FormControlLabel
											value={false}
											control={<Radio color="primary" />}
											label="p-separation"
											labelPlacement="start"
										/>
										<FormControlLabel
											value={true}
											control={<Radio color="primary" />}
											label="p-inkludering"
											labelPlacement="start"
										/>
									</RadioGroup>
								</FormControl>
							</CardContent>
						</Card>
					</div>
				</div>
			</div>
		);
	}
	return null;
}

export default PatientProcedure;
