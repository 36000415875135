import { useState, useEffect, useContext } from 'react';
import {
	FormControl,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
	Card,
	CardContent,
	CardHeader,
	Fade,
} from '@mui/material';
import { useQuery, useMutation, useLazyQuery } from '@apollo/client';
import gql from 'graphql-tag';
import Select from 'react-select';
import { addMonths } from 'date-fns';

import {
	ClinicSelector,
	ClinicSelectorProps,
	labelSort,
} from '../common/ClinicSelector';
import {
	PageHeader,
	FormLabel,
	GraphContainer,
	HelpText,
} from '../common/AppLayout';
import {
	BarChart,
	BarChartData,
} from '../page-specific/patient-number/PatientNumberGraph';
import { PieChart, PieChartData } from '../common/CommonGraphs';
import './gridLayout.scss';
import { styles } from '../../theme';
import { DateRangeSelector } from '../common/DateRangeSelector';
import AgerangeDialog from '../common/AgerangeDialog';
import {
	APPOINTMENTCAUSE_QUERY,
	PATIENTAGEGROUP_QUERY,
	PAYMENTCLASS_QUERY,
	VISITS_QUERY as VQ,
} from '../queries/DataQueries';
import {
	AppThemeContext,
	ClinicInformationContext,
} from '../../contexts/Providers';
import { ClinicOption } from '../common/types/Page';
import { useTranslation } from 'react-i18next';
import { BaseOptionID, AgeRangeSelection } from '../common/types/Page';

const SETTINGS_QUERY = gql`
  query multiQuery {
    ${PATIENTAGEGROUP_QUERY}
    ${APPOINTMENTCAUSE_QUERY}
    ${PAYMENTCLASS_QUERY}
  }
`;

const AGERANGE_MUTATION = gql`
	mutation agerangeMutation($from: Int!, $to: Int!, $label: String!) {
		insert_ftv_patientagegroup(
			objects: { lowerlimit: $from, upperlimit: $to, name: $label }
		) {
			returning {
				patientagegroupid
				lowerlimit
				upperlimit
			}
		}
	}
`;

const VISITS_QUERY = gql`
	${VQ}
`;

/**
 * Process GQL data from backend to selector
 * @param {Array} arr
 */
const allPatientAgeGroups = (arr: Array<any>) => {
	return arr
		.map((item) => {
			const name =
				`${item.lowerlimit}` +
				(item.upperlimit >= 100 ? '+' : `-${item.upperlimit}`);
			return {
				id: item.patientagegroupid,
				lowerlimit: item.lowerlimit,
				upperlimit: item.upperlimit,
				label: name,
				value: name,
			};
		})
		.sort(labelSort);
};

// Add button
const addPatientAgeGroup = {
	label: '+ Add',
	value: 'add',
	isFunc: true,
	id: -1,
};

interface PatientNumberTableData {
	name: string;
	visit: number;
}

function PatientNumber() {
	const { t } = useTranslation();
	const clinics = useContext(ClinicInformationContext);
	const { palette } = useContext(AppThemeContext);

	// All options
	const [options, setOptions] = useState<{
		clinic: Array<ClinicOption>;
		appointment: Array<BaseOptionID>;
		patientagegroup: Array<AgeRangeSelection>;
		paymentclass: Array<BaseOptionID>;
	}>({
		clinic: [],
		appointment: [],
		patientagegroup: [],
		paymentclass: [],
	}); // Combines all available options
	const [settingsDataLoaded, setSettingsDataLoaded] = useState(false); // if all settings-data has been loaded
	const [visitData, setVisitData] = useState<Array<BarChartData>>([]);
	const [tableData, setTableData] = useState<Array<PatientNumberTableData>>([]);

	// User selections
	const [selectedClinics, setSelectedClinics] = useState<Array<ClinicOption>>(
		[]
	);
	const [dateRange, setDateRange] = useState({
		start: new Date(),
		end: addMonths(new Date(), 3),
	});
	const [selectedPatientagegroups, setSelectedPatientagegroups] = useState<
		Array<AgeRangeSelection>
	>([]);
	const [selectedAppointmentcause, setSelectedAppointmentcause] = useState<
		Array<BaseOptionID>
	>([]);
	const [selectedPaymentclass, setSelectedPaymentclass] = useState<
		Array<BaseOptionID>
	>([]);

	// Age dialog window
	const [ageDialog, setAgeDialog] = useState(false);

	const [pieChart, setPieChart] = useState<{
		data: Array<PieChartData>;
		title: string;
	}>({ data: [], title: '' });

	// Update, this function will not run unless `doAgerangeMutation` is called
	const [doAgerangeMutation] = useMutation(AGERANGE_MUTATION, {
		onCompleted: (data) => {
			const retOpt = data.insert_ftv_patientagegroup.returning[0]; // item that was updated/added
			const name =
				`${retOpt.lowerlimit}` +
				(retOpt.upperlimit > 999 ? '+' : `-${retOpt.upperlimit}`);
			const newOpt = {
				id: Number(retOpt.patientagegroupid),
				lowerlimit: retOpt.lowerlimit,
				upperlimit: retOpt.upperlimit,
				label: name,
				value: name,
			};
			// adding the new item last
			setOptions({
				...options,
				patientagegroup: options.patientagegroup.concat([newOpt]),
			});
		},
	});

	// Loading SETTINGS_QUERY data, used for Settings
	useQuery(SETTINGS_QUERY, {
		onCompleted: (multiquery_data) => {
			if (multiquery_data) {
				// Managing multiquery data and set initial selections and options
				// const allClinics = multiquery_data.ftv_clinic.map(item => {
				// return { id: item.id, label: item.name, value: item.name, region: item.region }
				// });

				const allAppointmentCauses = multiquery_data.ftv_appointmentcause
					.map((item: { id: string; name: string }) => {
						return { id: item.id, value: item.name, label: item.name };
					})
					.sort(labelSort);

				const allpatientagegroups = allPatientAgeGroups(
					multiquery_data.ftv_patientagegroup
				);

				const allPaymentclass = multiquery_data.ftv_paymentclass
					.map((item: { id: string; name: string }) => {
						return { id: item.id, value: item.name, label: item.name };
					})
					.sort(labelSort);

				setSettingsDataLoaded(true);
				setOptions({
					clinic: clinics,
					appointment: allAppointmentCauses,
					// patientagegroup: allpatientagegroups.concat([addPatientAgeGroup]),
					patientagegroup: allpatientagegroups.concat({
						id: addPatientAgeGroup.id,
						value: addPatientAgeGroup.value,
						label: addPatientAgeGroup.label,
						lowerlimit: 0,
						upperlimit: 100,
					}),
					paymentclass: allPaymentclass,
				});

				// Setting defaults
				setSelectedClinics(clinics);
				setSelectedAppointmentcause(
					allAppointmentCauses.filter(
						(item: { label: string }) => item.label === 'Revision'
					)
				);
				setSelectedPatientagegroups(allpatientagegroups);
				setSelectedPaymentclass(
					allPaymentclass.filter(
						(item: { label: string }) => item.label === 'Taxa 2008'
					)
				);
			}
		},
	});

	// Loading visitation data
	const [getVisitData, { data: visitsQuery_data }] = useLazyQuery(
		VISITS_QUERY,
		{
			onCompleted: () => {
				if (visitsQuery_data) {
					// Managing visitQuery data and data process for graph
					// Process into graph data
					const tmp: Array<any> = [];
					visitsQuery_data.ftv_get_plannedvisit.forEach(
						(item: { clinic_id: any; agegroupid: any; count: any }) => {
							const findIndex = tmp.findIndex(
								(v) => v.clinic_id === item.clinic_id
							);
							const ageLabel = options.patientagegroup.find(
								(p) => p.id === item.agegroupid
							)?.label;
							const clinicLabel = options.clinic.find(
								(c) => c.id === item.clinic_id
							)?.label;

							if (findIndex !== -1 && ageLabel) {
								// update old
								tmp[findIndex] = { ...tmp[findIndex], [ageLabel]: item.count };
							} else {
								// append new
								if (ageLabel)
									// this will always exist?
									tmp.push({
										clinic_id: item.clinic_id,
										clinic: clinicLabel,
										[ageLabel]: item.count,
									});
							}
						}
					);
					setVisitData(tmp.sort((a, b) => (a.clinic < b.clinic ? -1 : 0)));
				}
			},
		}
	);

	const [getTableData, { data: table_data }] = useLazyQuery(VISITS_QUERY, {
		onCompleted: () => {
			if (table_data) {
				const tmp: Array<PatientNumberTableData> = [];
				options.clinic.forEach((c) => {
					// const tm = table_data.ftv_get_plannedvisit.filter(clinic => c.id === clinic.clinic_id);
					const partialSum = table_data.ftv_get_plannedvisit
						.filter(
							(clinic: { clinic_id: number }) => c.id === clinic.clinic_id
						)
						.reduce((acc: number, s: { count: number }) => acc + s.count, 0);
					tmp.push({
						name: c.label,
						visit: partialSum,
					});
				});

				setTableData(tmp);
			}
		},
	});

	// Will re-fetch graph data
	useEffect(() => {
		const getDataParam = {
			variables: {
				start: dateRange.start,
				end: dateRange.end,
				clinics: selectedClinics.map((item) => item.id),
				agegroups: selectedPatientagegroups
					? selectedPatientagegroups.map((item) => item.id)
					: [],
				paymentclasses: selectedPaymentclass
					? selectedPaymentclass.map((item) => item.id)
					: [],
				appointmentcauses: selectedAppointmentcause
					? selectedAppointmentcause.map((item) => item.id)
					: [],
			},
		};
		getVisitData(getDataParam);
	}, [
		selectedClinics,
		dateRange,
		selectedPatientagegroups,
		selectedAppointmentcause,
		selectedPaymentclass,
		getVisitData,
	]);

	// Updating table data
	useEffect(() => {
		const getTableDataParam = {
			variables: {
				start: dateRange.start,
				end: dateRange.end,
				clinics: options.clinic.map((item) => item.id),
				agegroups: selectedPatientagegroups
					? selectedPatientagegroups.map((item) => item.id)
					: [],
				paymentclasses: selectedPaymentclass
					? selectedPaymentclass.map((item) => item.id)
					: [],
				appointmentcauses: selectedAppointmentcause
					? selectedAppointmentcause.map((item) => item.id)
					: [],
			},
		};
		getTableData(getTableDataParam);
	}, [
		options.clinic,
		dateRange,
		selectedPatientagegroups,
		selectedAppointmentcause,
		selectedPaymentclass,
		getTableData,
	]);

	function showPieChart(data: { data: { [x: string]: any; clinic: any } }) {
		const res: Array<{ id: string; value: string; label: string }> = [];
		options.patientagegroup.forEach((item) => {
			if (item.id !== -1 && item.label in data.data)
				res.push({
					id: item.label,
					value: data.data[item.label],
					label: item.label,
				});
		});
		setPieChart({ ...pieChart, data: res, title: data.data.clinic });
	}

	if (settingsDataLoaded) {
		// Props for Select components
		const clinicProps: ClinicSelectorProps = {
			options: options.clinic,
			value: selectedClinics,
			updateSelection: setSelectedClinics,
			placeholder: t('select clinics'),
		};

		const appointmentProps = {
			isMulti: true,
			options: options.appointment,
			value: selectedAppointmentcause,
			onChange: (opts: any) => setSelectedAppointmentcause(opts),
			defaultValue: selectedAppointmentcause,
			placeholder: t('select causes for call'),
			noOptionsMessage: () => t('no options'),
			menuPortalTarget: document.querySelector('body'),
		};

		const patientagegroupProps = {
			isMulti: true,
			options: options.patientagegroup,
			value: selectedPatientagegroups,
			onChange: (opts: any, event: any) => onSelection(opts, event),
			placeholder: t('select age-groups'),
			noOptionsMessage: () => t('no options'),
			menuPortalTarget: document.querySelector('body'),
		};

		const paymentclassProps = {
			isMulti: true,
			options: options.paymentclass,
			value: selectedPaymentclass,
			onChange: (opts: any) => setSelectedPaymentclass(opts),
			placeholder: t('select charging-classes'),
			noOptionsMessage: () => t('no options'),
			menuPortalTarget: document.querySelector('body'),
		};

		/**
		 * Checks if user presses a selection containing a "function", and opens a dialog
		 * @param {Array} opts new options used by selector
		 * @param {Object} event event
		 */
		const onSelection = (
			opts: any,
			event: { action: string; option: { isFunc: any } }
		) => {
			if (event.action === 'select-option' && event.option.isFunc)
				setAgeDialog(true);
			else setSelectedPatientagegroups(opts);
		};

		/**
		 * GQL mutation/add `patientagegroup` to backend, and closes the window
		 * @param {Object} obj A "date"-object with keys: `from` and `to`
		 */
		const updatePatientAge = (obj: {
			from: number;
			unlimitedTo: any;
			to: number;
		}) => {
			if ('to' in obj && 'from' in obj) {
				doAgerangeMutation({
					variables: {
						from: obj.from,
						to: obj.unlimitedTo ? 999 : obj.to,
						label: `${obj.from}-${obj.to}`,
					},
				});
			}
			setAgeDialog(false);
		};

		// Render
		return (
			<div style={{ ...styles.mainContent }}>
				<AgerangeDialog enabled={ageDialog} return={updatePatientAge} />

				<PageHeader title={t('patient')} />

				<div id="gridColSplit">
					<div id="mainGrid">
						<GraphContainer
							id="graph"
							title={undefined}
							subheader={undefined}
							height={undefined}
							contentPadding={0}
							style={undefined}
						>
							<BarChart
								data={visitData}
								keys={
									selectedPatientagegroups
										? selectedPatientagegroups.map((item) => item.label)
										: []
								}
								onClick={showPieChart}
								palette={palette.colors}
							/>
						</GraphContainer>

						<Fade in={tableData ? true : false} timeout={1000}>
							<Card style={{ height: '100%' }}>
								<CardHeader
									title="Klinik"
									subheader={'Tabelldata för alla kliniker'}
								/>
								<CardContent>
									<Table>
										<TableHead>
											<TableRow>
												<TableCell padding="none">Klinik</TableCell>
												<TableCell padding="none">Patienter</TableCell>
											</TableRow>
										</TableHead>
										<TableBody>
											{tableData.map((item) => (
												<TableRow key={item.name}>
													<TableCell padding="none">{item.name}</TableCell>
													<TableCell padding="none" align="right">
														{item.visit}
													</TableCell>
												</TableRow>
											))}
										</TableBody>
									</Table>
								</CardContent>
							</Card>
						</Fade>

						<GraphContainer
							title={pieChart ? `FTV ${pieChart.title}` : 'Fördelning'}
							subheader={
								pieChart
									? 'Alla patientåldrar, kallelseorsaker och debiteringsklasser'
									: undefined
							}
							id={'123'}
						>
							{pieChart ? (
								<PieChart
									data={pieChart.data}
									palette={palette.colors}
									showLegend
								/>
							) : (
								HelpText(
									'Tryck på valfri stapel ovan för att få detaljerad vy av åldersfördelningen'
								)
							)}
						</GraphContainer>
					</div>

					<div id="settings">
						<Card>
							<CardHeader title={t('settings')} />
							<CardContent>
								<FormControl style={{ width: '100%' }}>
									<FormLabel required>{t('date-interval')}</FormLabel>
									<DateRangeSelector
										onChange={(s) =>
											setDateRange({ start: s.start, end: s.end })
										}
										startDate={dateRange.start}
										endDate={dateRange.end}
									/>

									<FormLabel required>{t('clinics')}</FormLabel>
									<ClinicSelector {...clinicProps} />

									<FormLabel required>{t('age-groups')}</FormLabel>
									<Select {...patientagegroupProps} />

									<FormLabel required>{t('causes for call')}</FormLabel>
									<Select {...appointmentProps} />

									<FormLabel required>{t('charging-classes')}</FormLabel>
									<Select {...paymentclassProps} />
								</FormControl>
							</CardContent>
						</Card>
					</div>
				</div>
			</div>
		);
	} else return <p>Loading</p>;
}

export default PatientNumber;
