import i18n from 'i18next';

import englishTranslation from './translations/english';
import swedishTranslation from './translations/swedish';

import { initReactI18next } from 'react-i18next';

const translations = {
	en: {
		translation: englishTranslation,
	},
	sv: {
		translation: swedishTranslation,
	},
};

i18n
	.use(initReactI18next) // passes i18n down to react-i18next
	.init({
		resources: translations,
		lng: 'sv',
		interpolation: {
			escapeValue: false, // React already escapes XSS by default
		},
	});

export default i18n;
