const WIDTH: number = 1200;

interface TimelineProps {
	now: Date;
	created: Date;
	deadline: Date;
	scheduled: Date;
}

export function Timeline(props: TimelineProps) {
	const { now, created, deadline, scheduled } = props;

	// Convert distances between 0 - 800 pixels
	const days_conv = 1000 * 3600 * 24;
	const smallest = Math.min(
		now.getTime(),
		created.getTime(),
		deadline.getTime(),
		scheduled.getTime()
	);
	const largest = Math.max(
		now.getTime(),
		created.getTime(),
		deadline.getTime(),
		scheduled.getTime()
	);
	const distance = largest - smallest;
	const conv = WIDTH / distance;

	const created_x: number = 0;
	const scheduled_x: number = (scheduled.getTime() - smallest) * conv;
	const deadline_x: number = (deadline.getTime() - smallest) * conv;
	const now_x: number = (now.getTime() - smallest) * conv;

	const dotStyle = {
		fill: '#000',
		r: 3,
		cy: 50,
	};

	const textStyle = {
		textAnchor: 'middle',
		y: 50 + 15,
		// fontSize: 'smaller',
	};

	return (
		<svg
			width={`${WIDTH}`}
			height="100%"
			viewBox={`-25 -50 ${WIDTH + 100} 250`}
			xmlns="http://www.w3.org/2000/svg"
		>
			<defs>
				<marker
					id="arrowhead"
					markerWidth="10"
					markerHeight="7"
					refX="0"
					refY="3.5"
					orient="auto"
				>
					<polygon points="0 0, 10 3.5, 0 7" />
				</marker>
			</defs>
			<line
				x1="-5%"
				y1={50}
				x2={WIDTH + 10}
				y2={50}
				stroke="#000"
				strokeWidth={1.5}
				markerEnd="url(#arrowhead)"
			/>
			<text {...textStyle} x={WIDTH + 10} y={50 + 25} textAnchor="start">
				Tidslinje
			</text>

			<line
				x1={now_x}
				x2={now_x}
				y1={100}
				y2={-50}
				strokeDasharray={5}
				stroke="#0000ee"
				opacity={0.5}
				strokeWidth={1.5}
			/>
			<circle
				{...dotStyle}
				cx={now_x}
				cy={100}
				fill="#0000ee"
				r={14}
				opacity={0.8}
			/>
			<text
				{...textStyle}
				x={now_x}
				y={100}
				alignmentBaseline="middle"
				fill="#fff"
			>
				Nu
			</text>

			<circle cx={created_x} {...dotStyle} />
			<text {...textStyle} x={created_x}>
				Inkom
			</text>

			<circle cx={scheduled_x} {...dotStyle} />
			<text {...textStyle} x={scheduled_x} fill="#000">
				Schemalagd
			</text>

			<circle {...dotStyle} cx={deadline_x} fill="#ee0000" />
			<text {...textStyle} x={deadline_x} y={50 - 15} fill="#ee0000">
				Deadline
			</text>

			<path
				d={`M 0,25 h ${scheduled_x - 10}`}
				stroke="#000"
				fill="none"
				markerEnd="url(#arrowhead)"
				opacity={0.5}
			/>
			<text
				{...textStyle}
				x={`${scheduled_x / 2}`}
				y={20}
				fontStyle="italic"
			>{`${Math.ceil(
				(scheduled.getTime() - created.getTime()) / days_conv
			)} dagar`}</text>

			<path
				d={`M 0,0 h ${deadline_x - 10}`}
				stroke="#000"
				fill="none"
				markerEnd="url(#arrowhead)"
				opacity={0.5}
			/>
			<text
				{...textStyle}
				x={`${deadline_x / 2}`}
				y={-10}
				fontStyle="italic"
			>{`${Math.ceil(
				(deadline.getTime() - created.getTime()) / days_conv
			)} dagar`}</text>
		</svg>
	);
}
