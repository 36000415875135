import { Paper, Typography, Button } from '@mui/material';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { styles } from '../../theme';

const PageError = () => {
	const { t } = useTranslation();
	const navigate = useNavigate();

	let { code } = useParams();
	let error = 'something went wrong';

	if (code !== undefined) {
		if (code === '404') error = 'the requested page does not exist';
		else if (code.length > 0 && code[0] === '5')
			error = 'internal server error';
		else if (code.includes('apollo')) {
			error = code;
			code = code.substring(6);
		}
	}

	return (
		<div style={styles.mainContent}>
			<Paper
				style={{ width: 400, padding: 32, paddingTop: 16, margin: 'auto' }}
			>
				<Typography variant="h2">{t('oops!')}</Typography>
				{code !== undefined && (
					<Typography variant="h5">
						{t('error')}: {code}
					</Typography>
				)}
				<p>{t(error)}.</p>

				<div style={{ textAlign: 'center' }}>
					<Button
						variant="contained"
						color="secondary"
						onClick={() => navigate(-1)}
					>
						{t('go back')}
					</Button>

					<Link style={{ textDecoration: 'none', marginLeft: '16px' }} to="/">
						<Button variant="contained" color="primary">
							{t('start page')}
						</Button>
					</Link>
				</div>
			</Paper>
		</div>
	);
};

export default PageError;
