const englishTranslation = {
	// Spinner
	loading: 'Loading',
	// // Sidebar
	patient: 'Patient',
	patients: 'Patients',
	visits: 'Visits',
	'queue-patients': 'Queue-patients',
	recall: 'Recall',
	'measure-statistics': 'Measure-statistics',
	epidemiology: 'Epidemiology',
	'dental-healthcare': 'Dental healthcare',
	'dental-healthcare-contract': 'Dental healthcare contract',
	'healthcare-patients': 'Healthcare-patients',
	poll: 'Poll',
	'risk-groups': 'Risk groups',
	'medical-procedures': 'medical procedures statistics',

	staff: 'Staff',
	'time worked': 'Time worked',
	'time worked clinic-based': 'time worked clinic',
	'schedulable care-time': 'Schedulable care-time',
	'performed care-time': 'Performed care-time',
	administration: 'Administration',
	meeting: 'Meeting',
	'therapy meeting': 'Therapy meeting',
	income: 'Income',

	'ATV-work': 'ATV time worked',
	'ATV-work2': 'ATV time worked tdl/hyg',

	economy: 'Economy',
	'production revenue': 'Production revenue',
	'treatment revenue': 'Treatment revenue',

	planning: 'Planning',
	scheduler: 'Scheduler',
	simulation: 'Simulation',
	settings: 'Settings',

	'log out': 'Log out',

	// UserInfo
	name: 'Name',

	// // Page Specific
	// Patients
	'patient-view': 'Patient-view',
	'patients per clinic': 'Patients per clinic',

	// Patient Visit
	'patient-visit': 'Patient visit',

	// Queue-patients
	'patient-queue': 'Queue patient',
	'region average': 'Region average',
	'queue size': 'Queue size',
	'queue length': 'Queue length',

	// Staff-view
	'staff-view': 'Staff-view',
	'choose occupation': 'Choose occupation',
	adults: 'adults',
	bookable: 'bookable',
	chefadmin: 'manage administration',
	children: 'children',
	clinicdevelopment: 'clinic development',
	education: 'education',
	misc: 'misc',
	patienttime: 'patient time',
	supervisetime: 'supervise time',
	therapymeeting: 'therapy meeting',
	workedtime: 'worked time',

	'patient-survey': 'Patient survey',

	// Economy-view
	'economy-view': 'Economy-view',

	// Settings-view
	'settings-view': 'Settings-view',

	// // Generic components
	// Graph
	graph: 'Graph',
	'number-of-patients': 'number of patients',
	sum: 'Sum',

	// Settings
	regions: 'Regions',
	'select regions': 'Select regions',
	clinics: 'Clinics',
	'select clinics': 'Select clinics',
	'select clinic': 'Select clinic',
	'age-groups': 'Age-groups',
	'select age-groups': 'Select age-groups',
	'causes for call': 'Causes for call',
	'select causes for call': 'Select causes for call',
	'charging-classes': 'Charging-classes',
	'select charging-classes': 'Select charging-classes',
	west: 'West',
	east: 'East',
	'add age-group': 'Add age-group',

	// Trend
	trend: 'Trend',

	// CurrentNumber
	'current number': 'Current number',

	// CurrentDistribtuion
	'current distribution': 'Current distribution',

	// RadioButtonsGroup
	'graph style': 'Graph style',
	cumulative: 'Cumulative',
	comparative: 'Comparative',

	// DateRangeSelector
	'date-interval': 'Date interval',
	date: 'Date',
	start: 'Start',
	end: 'End',

	// MultiSelectField
	'no options': 'No options',

	// AgerangeDialog.js
	from: 'From',
	to: 'To',
	cancel: 'Cancel',
	add: 'Add',
	unlimited: 'Unlimited',

	// NivoCharts.js
	clinic: 'Clinic',
	total: 'Total',

	// PageError
	'oops!': 'Oops!',
	error: 'Error',
	'the requested page does not exist': 'The requested page does not exist',
	'something went wrong': 'Something went wrong',
	'internal server error': 'Internal server error',
	'go back': 'Go back',
	'start page': 'Start page',
	apollo401: 'The access token has expired',
	apollo404: 'Could not connect to the apollo server',
	apollo500: 'Internal apollo server error',

	// simulation
	postop: 'PostOP simulation',
	'waiting list': 'Waiting list',
	'surgery program': 'Surgery program',
	'load graph': 'Load graph',
	'patient flow graph': 'Patient flow graph',
	gynecology: 'Gynecology',
	urology: 'Urology',
	surgery: 'Surgery',
	orthopedics: 'Orthopedics',
	maintenance: 'Maintenance',
	'operating room': 'Operating room',
	minutes: 'Minutes',
	'minutes end': 'Minutes, ends',
	'number of beds': 'Number of beds',
	frequency: 'Frequency',
	time: 'Time',
	'max load': 'Max load',
	below: 'Below',

	// referral
	referral: 'Referral',
	referrals: 'Referrals',

	// examination
	examination: 'Examination',
	examinations: 'Examinations',

	// referral
	EMERGENCY: 'Emergency',
	DOUBLE_PRIORITY: 'Double priority',
	SINGLE_PRIORITY: 'Single Priority',
	NO_PRIORITY: 'No prioritet',
	week: 'Week',

	// scheduler queue
	'overview referrals': 'Overview referrals',

	'incoming referrals': 'Incoming referrals',
	'unscheduled referrals': 'Unscheduled referrals',
	'waiting time': 'Waiting time',

	'care guarantee 90 days': 'Care guarantee 90 days',

	'number of referrals': 'Number of referrals',
	'number of days': 'Number of days',

	Combined: 'Combined',
	'No Priority': 'No priority',
	'Single Priority': 'Single priority',
	'Double Priority': 'Double priority',
	Emergency: 'Emergency',
};

export default englishTranslation;
