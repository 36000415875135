import { Typography, TextField, Tooltip, Button } from '@mui/material';
import Select from 'react-select';
import './Scheduling.scss';
import { altColors as palette } from '../../../theme';
import { makeStyles } from '@mui/styles';

export interface ActivitiesOpts {
	bmas: Array<Option>;
	doctor: Array<Option>;
	rooms: Array<Option>;
}

export interface ActivitiesSelection {
	bmas: Array<Option | undefined>;
	doctor: Option | null;
	rooms: Array<Option>;
}

export interface Option {
	title?: string;
	label: string;
	value: string;
}

interface SidePanelProps {
	readonly opts: ActivitiesOpts;
	readonly selection: ActivitiesSelection;
	requestedOptimization: boolean;
	setSelection: (value: React.SetStateAction<ActivitiesSelection>) => void;
	setRequestedOptimization: (value: React.SetStateAction<boolean>) => void;
}

export default function SidePanel(props: SidePanelProps) {
	const useStyles = makeStyles((theme) => ({
		roomSelect: {
			width: '300px',
		},
	}));

	const classes = useStyles();

	const numSelectedBMA = props.selection.bmas.reduce<number>(
		(prev, current) => {
			if (current) return prev + 1;
			return prev;
		},
		0
	);
	return (
		<div className="side-pane">
			<div>
				<Typography variant="subtitle2">Antal Remisser</Typography>
				<TextField
					sx={{ margin: 0 }}
					margin="dense"
					id="outlined-number"
					size="small"
					defaultValue={16}
					type="number"
					fullWidth
					disabled
				/>
			</div>
			<div>
				<Typography variant="subtitle2">Läkare</Typography>
				<Select
					value={props.selection.doctor}
					options={props.opts.doctor}
					placeholder="Välj en läkare"
					onChange={(opts) =>
						opts && props.setSelection({ ...props.selection, doctor: opts })
					}
					theme={(theme) => ({
						...theme,
						borderRadius: 2,
						colors: {
							...theme.colors,
							primary: palette.blue.css(),
							primary25: palette.blue.alpha(0.4).css(),
						},
					})}
				/>
			</div>

			<div>
				<Typography variant="subtitle2">Rum</Typography>
				<Select
					className={classes.roomSelect}
					isMulti
					options={props.selection.rooms.length === 3 ? [] : props.opts.rooms}
					value={props.selection.rooms}
					placeholder="Välj flera rum"
					onChange={(opts) =>
						opts &&
						props.setSelection({
							...props.selection,
							rooms: opts.map((item) => item),
						})
					}
				/>
			</div>
			<Tooltip title="Välj en läkare och 3 BMA." placement="right">
				<span>
					<Button
						variant="contained"
						sx={{ marginTop: '1rem' }}
						onClick={() => props.setRequestedOptimization(true)}
						disabled={
							!(numSelectedBMA === 3 && props.selection.rooms.length === 3) ||
							props.requestedOptimization
						}
					>
						Produktionsplanera
					</Button>
				</span>
			</Tooltip>
		</div>
	);
}
