import { Fragment } from 'react';
import { ResponsiveBar } from '@nivo/bar';
import { ResponsiveChord } from '@nivo/chord';
import { ResponsiveSankey } from '@nivo/sankey';
import { graphTheme } from '../../common/GraphGlobalSettings';
import chroma from 'chroma-js';
import { useTranslation } from 'react-i18next';

interface PatientRiskGraphProps {
	data: Array<{ clinic: string; [key: string]: any }>;
	keys: Array<string>;
	palette: Array<string>;
}

export function PatientRiskGraph(props: PatientRiskGraphProps) {
	const { t } = useTranslation();

	const sums = props.data.map((item) => {
		return item.riskgrupp0 + item.riskgrupp1 + item.riskgrupp2;
	});

	const processData = (data: any) => {
		return data.map((item: any, index: number) => {
			const multiplier = 100.0 / sums[index];
			return {
				clinic: item.clinic,
				riskgrupp0: item.riskgrupp0 * multiplier,
				riskgrupp1: item.riskgrupp1 * multiplier,
				riskgrupp2: item.riskgrupp2 * multiplier,
			};
		});
	};

	const tooltip = (data: any) => {
		const clinicName = data.data.clinic;
		const dataIndex = data.index;
		return (
			<div
				style={{
					fontSize: 14,
					backgroundColor: '#444',
					padding: '0.3rem',
					color: '#fff',
				}}
			>
				<h4 style={{ marginTop: 0, color: '#eee' }}>FTV {clinicName}</h4>
				<div
					style={{
						display: 'grid',
						gridTemplateColumns: '30px 1fr 1fr 1fr',
						gridRowGap: 4,
					}}
				>
					{props.keys.map((key, index) => {
						const main = props.data.find((item) => item.clinic === clinicName);
						return (
							<Fragment key={`${dataIndex}-${key}`}>
								<div
									style={{
										width: 20,
										height: 20,
										backgroundColor:
											props.palette[index % props.palette.length],
									}}
								/>
								<div>{key}</div>
								<div>{data.data[key].toFixed(2)}%</div>
								<div>{main ? main[key] : 0} stycken</div>
							</Fragment>
						);
					})}
				</div>
				<div style={{ marginTop: '1rem' }}>{`${t('sum')}: ${
					sums[dataIndex]
				}`}</div>
			</div>
		);
	};

	return (
		<ResponsiveBar
			data={processData(props.data)}
			keys={props.keys}
			indexBy="clinic"
			margin={{ top: 50, right: 130, bottom: 80, left: 100 }}
			padding={0.3}
			groupMode="grouped"
			colors={props.palette}
			borderColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
			axisBottom={{
				tickSize: 5,
				tickPadding: 5,
				tickRotation: 45,
			}}
			axisLeft={{
				tickSize: 5,
				tickPadding: 5,
				tickRotation: 0,
				legend: 'Riskgruppsfördelning i procent',
				legendPosition: 'middle',
				legendOffset: -50,
			}}
			labelSkipWidth={24}
			labelSkipHeight={24}
			labelTextColor={(d) => {
				return chroma.contrast(d.color, '#ffffff') < 4.5 ? '#000' : '#fff';
			}}
			// labelFormat={(label) => `${label.toFixed(2)}`}
			label={(d) => (d.value ? d.value?.toFixed(2) : d.formattedValue)}
			legends={[
				{
					dataFrom: 'keys',
					anchor: 'bottom-right',
					direction: 'column',
					justify: false,
					translateX: 120,
					translateY: 0,
					itemsSpacing: 2,
					itemWidth: 100,
					itemHeight: 20,
					itemDirection: 'left-to-right',
					itemOpacity: 0.85,
					symbolSize: 20,
					effects: [
						{
							on: 'hover',
							style: {
								itemOpacity: 1,
							},
						},
					],
				},
			]}
			animate={true}
			// motionStiffness={90}
			// motionDamping={15}
			tooltip={tooltip}
			theme={graphTheme}
		/>
	);
}

const matrixLocal: number[][] = [
	[
		206, 73, 83,
		// 483
	],
	[
		235, 103,
		// 968,
		549,
	],
	[
		310, 94,
		// 103,
		410,
	],
];

const sankeyData = {
	nodes: [
		{
			id: 'riskgrupp0',
		},
		{
			id: 'riskgrupp1',
		},
		{
			id: 'riskgrupp2',
		},
	],
	links: [
		{
			source: 'riskgrupp0',
			target: 'riskgrupp1',
			value: 84,
		},
		{
			source: 'riskgrupp0',
			target: 'riskgrupp2',
			value: 12,
		},
		{
			source: 'riskgrupp1',
			target: 'riskgrupp2',
			value: 49,
		},
	],
};

/**
 * Draws a ChordGraph
 * @param {Array} matrix data used for drawing graph
 * @param {Array} palette color-array
 * @param {Array} keys array of keys
 */
export const ChordGraph = (props: {
	palette: Array<string>;
	keys: Array<string>;
}) => {
	const { palette, keys } = props;
	return (
		<ResponsiveChord
			matrix={matrixLocal}
			keys={keys}
			layers={['ribbons', 'arcs', 'labels', 'legends']}
			margin={{ top: 40, right: 40, bottom: 40, left: 40 }}
			valueFormat=".2f"
			padAngle={0.02}
			innerRadiusRatio={0.96}
			innerRadiusOffset={0.02}
			arcOpacity={1}
			arcBorderWidth={1}
			arcBorderColor={{ from: 'color', modifiers: [['darker', 0.4]] }}
			ribbonOpacity={0.4}
			ribbonBorderWidth={1}
			ribbonBorderColor={{ from: 'color', modifiers: [['darker', 0.4]] }}
			enableLabel={true}
			label="id"
			labelOffset={12}
			labelRotation={-90}
			labelTextColor={{ from: 'color', modifiers: [['darker', 1]] }}
			colors={palette}
			isInteractive={true}
			arcHoverOpacity={1}
			arcHoverOthersOpacity={0.25}
			ribbonHoverOpacity={1.0}
			ribbonHoverOthersOpacity={0.15}
			animate={true}
			motionStiffness={90}
			motionDamping={7}
		/>
	);
};

/**
 * Draws a SanKey graph
 * @param {Array} data Array of data used for drawing graph
 * @param {Array} palette Array of colors
 */
export const SankeyGraph = (props: { palette: Array<string> }) => {
	const { palette } = props;
	return (
		<ResponsiveSankey
			data={sankeyData}
			margin={{ top: 40, right: 40, bottom: 40, left: 40 }}
			align="justify"
			colors={palette}
			nodeOpacity={1}
			nodeThickness={18}
			nodeInnerPadding={3}
			nodeSpacing={24}
			nodeBorderWidth={0}
			nodeBorderColor={{ from: 'color', modifiers: [['darker', 0.8]] }}
			linkOpacity={0.5}
			linkHoverOthersOpacity={0.1}
			enableLinkGradient={true}
			labelPosition="outside"
			labelOrientation="vertical"
			labelPadding={16}
			labelTextColor={{ from: 'color', modifiers: [['darker', 1]] }}
			animate={true}
			// motionStiffness={140}
			// motionDamping={13}
		/>
	);
};
