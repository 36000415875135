import axios from 'axios';
import Keycloak from 'keycloak-js';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

type AxiosConfigurationProps = {
	keycloak: Keycloak;
};

axios.defaults.baseURL = 'https://ftv-scheduler.sownderdev.se';

const RETRY_WAIT_TIME = 10000;

function AxiosConfiguration(props: AxiosConfigurationProps) {
	let navigate = useNavigate();

	const [initialized, setInitialized] = useState(false);
	if (!initialized) {
		axios.interceptors.request.use(function (config) {
			props.keycloak.updateToken(30).then();

			if (config.headers !== undefined) {
				config.headers['Authorization'] = `Bearer ${props.keycloak.token}`;
			}

			return config;
		});

		axios.interceptors.response.use(
			function (response) {
				return response;
			},
			async function (error) {
				if (
					error.response &&
					error.response.status === 401 &&
					!error.response.retry
				) {
					error.response.retry = true;
					await new Promise((resolve) => setTimeout(resolve, RETRY_WAIT_TIME));
					await props.keycloak.updateToken(0);
					error.config.headers['Authorization'] = props.keycloak.token;
					return axios.request(error.config);
				} else {
					// TODO: add a whitelist for urls that should not be redirected
					navigate(
						error.response === undefined ? '404' : error.response.status
					);
				}
			}
		);

		setInitialized(true);
	}

	return <></>;
}

export default AxiosConfiguration;
