import { useState } from 'react';

import {
	Button,
	TextField,
	Checkbox,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

/**
 *
 * @param {Boolean} enabled : Whether or not the dialog should show
 * @param {Function} return : Callback indicating that the user has finished using the dialog and that it should close,
 *                            should contain object with age-range information if new age-range was specified, {from: Number, to: Number, unlimitedTo: Boolean}
 */
export default function AgerangeDialog(props: {
	enabled: boolean;
	return: (a: any) => void;
}) {
	const [fromAge, setFromAge] = useState('');
	const [toAge, setToAge] = useState('');
	const [unlimitedChecked, setUnlimitedChecked] = useState(false);
	const [previouslyEnabled, setPreviouslyEnabled] = useState(false);

	const { t } = useTranslation();

	const inputContainerStyle = {
		background: '#eee',
		borderRadius: '0.4rem',
		padding: '0 1rem',
	};

	/**
	 * Resets the dialogue input fields to default values
	 * @returns {nothing}
	 */
	function resetInput() {
		setFromAge('');
		setToAge('');
		setUnlimitedChecked(false);
	}

	if (props.enabled !== previouslyEnabled) {
		if (props.enabled) resetInput();

		setPreviouslyEnabled(props.enabled);
	}

	return (
		<Dialog open={props.enabled} onClose={props.return}>
			<DialogTitle style={{ color: '#333', padding: '1rem 0 0.8rem 1.7rem' }}>
				{t('add age-group')}
			</DialogTitle>

			<DialogContent style={{ display: 'flex' }}>
				<div style={inputContainerStyle}>
					<TextField
						autoFocus
						margin="dense"
						value={fromAge}
						label={t('from')}
						type="number"
						onChange={(eve) => setFromAge(eve.target.value)}
					/>
				</div>

				<div style={{ width: '1.3rem' }} />

				<div style={{ ...{ display: 'grid' }, ...inputContainerStyle }}>
					<TextField
						margin="dense"
						value={toAge}
						label={t('to')}
						type="number"
						disabled={unlimitedChecked}
						onChange={(eve) => setToAge(eve.target.value)}
					/>

					<div style={{ display: 'flex', margin: '0 auto 0.4rem auto' }}>
						<div style={{ margin: 'auto' }}>{t('unlimited')}:</div>
						<Checkbox
							color="primary"
							checked={unlimitedChecked}
							onClick={() => setUnlimitedChecked(!unlimitedChecked)}
						/>
					</div>
				</div>
			</DialogContent>

			<DialogActions style={{ marginRight: '0.7rem' }}>
				<Button onClick={props.return} color="primary">
					{t('cancel')}
				</Button>
				<Button
					onClick={() =>
						props.return({
							from: Number(fromAge),
							to: Number(toAge),
							unlimitedTo: unlimitedChecked,
						})
					}
					disabled={
						!(
							fromAge &&
							((toAge && Number(fromAge) <= Number(toAge)) || unlimitedChecked)
						)
					}
					color="primary"
				>
					{t('add')}
				</Button>
			</DialogActions>
		</Dialog>
	);
}
