import { useContext, useState } from 'react';
import { NodeData, ResponsiveHeatMap } from '@nivo/heatmap';
import { useQuery } from '@apollo/client';
import gql from 'graphql-tag';
import chroma from 'chroma-js';

import { baseColors } from '../../../theme';
import { tooltipTheme, graphTheme } from '../../common/GraphGlobalSettings';
import { AppThemeContext } from '../../../contexts/Providers';

const weekDaysEng = [
	'monday',
	'tuesday',
	'wednesday',
	'thursday',
	'friday',
	'saturday',
	'sunday',
];

const weekDaysSweShort = ['mån', 'tis', 'ons', 'tor', 'fre', 'lör', 'sön'];

/**
 * Converts english weekdays to swedish (short form)
 * @param {String} weekDayEng
 */
const weekDayTranslate = (weekDayEng: string) => {
	const fIndex = weekDaysEng.findIndex((item) => item === weekDayEng);
	return fIndex >= 0 ? weekDaysSweShort[fIndex] : undefined;
};

interface VisitMonthHeatmapVisitData {
	weeknumber: string;
	[key: string]: string | number;
}

/**
 * Displays 4 weeks of visits
 * Week always starts on monday and ends on sunday.
 */
export default function VisitMonthHeatmap() {
	const [visitData, setVisitData] = useState<Array<VisitMonthHeatmapVisitData>>(
		[]
	);

	const { palette } = useContext(AppThemeContext);

	const GET_VISITS = gql`
		query day($start: date!, $end: date!, $clinic_ids: [Int!]) {
			ftv_plannedvisitbydayview(
				where: {
					timestamp: { _gte: $start, _lte: $end }
					clinic_id: { _in: $clinic_ids }
				}
				order_by: { timestamp: asc }
			) {
				clinic_id
				weekday
				duration
				freq
				timestamp
				weeknumber
			}
		}
	`;
	/**
	 * Current month
	 */
	const startAndEnd = () => {
		const today = new Date();
		const thirtyDaysAgo = new Date();
		thirtyDaysAgo.setDate(thirtyDaysAgo.getDate() - 30); // using today as reference will alter today
		return {
			start: thirtyDaysAgo.toISOString().split('T')[0],
			end: today.toISOString().split('T')[0],
		};
	};

	// Fetching data for calendar heatmap
	const info = startAndEnd();
	const {
		loading: query_loading,
		error: query_error,
		data: heatmapData,
	} = useQuery(GET_VISITS, {
		variables: {
			start: info.start,
			end: info.end,
			clinic_ids: [401],
		},
	});

	if (
		visitData.length === 0 &&
		!query_loading &&
		!query_error &&
		heatmapData.ftv_plannedvisitbydayview &&
		heatmapData.ftv_plannedvisitbydayview.length > 0
	) {
		const visitArray = heatmapData.ftv_plannedvisitbydayview;
		const newData: Array<VisitMonthHeatmapVisitData> = [];

		let curWeek = -1;
		let newDataIndex = -1;
		visitArray.forEach((item: any) => {
			const weekday = item.weekday.trim();

			const itemData = {
				[weekday]: item.freq,
				[`${weekday}_date`]: item.timestamp,
				[`${weekday}_duration`]: item.duration,
			};

			// new week
			if (curWeek !== item.weeknumber) {
				curWeek = item.weeknumber;
				newData.push({ weeknumber: curWeek.toString(), ...itemData });
				newDataIndex = newDataIndex + 1;
			}
			// same week
			else {
				newData[newDataIndex] = { ...newData[newDataIndex], ...itemData };
			}
		});

		// fill missing days with 0, week-wise
		newData.forEach((item) => {
			weekDaysEng.forEach((weekday) => {
				if (!(weekday in item)) {
					item[weekday] = 0;
				}
			});
		});

		setVisitData(newData);
	}

	/**
	 * Custom tooltip function for displaying tooltip in Nivocharts
	 * @param {Object} value Nivo-parameters
	 */
	const toolTipDisplay = (value: NodeData) => {
		const findWeekIndex = visitData.findIndex(
			(item: any) => item.weeknumber === value.yKey
		);
		const date = visitData[findWeekIndex][`${value.xKey}-date`];
		const duration = visitData[findWeekIndex][`${value.xKey}-duration`];

		return (
			<div style={tooltipTheme}>
				<div>{date}</div>
				<div>
					{weekDayTranslate(value.xKey as string)} vecka: {value.yKey}
				</div>
				<div>Antal besök: {value.value}</div>
				<div>Patienttid: {duration} minuter</div>
			</div>
		);
	};

	const graphPalette =
		palette.paletteName === 'sownder'
			? ['#ccc'].concat(
					chroma
						.scale([palette.colors[0], palette.colors[1]])
						.mode('lch')
						.colors(6)
			  )
			: ['#ccc']
					.concat(chroma.scale(['#fff', baseColors.main]).colors(10))
					.concat([baseColors.darkBlue]);

	return (
		<ResponsiveHeatMap
			data={visitData}
			keys={weekDaysEng}
			indexBy="weeknumber"
			margin={{ top: 20, right: 20, bottom: 60, left: 20 }}
			// forceSquare={true}
			axisTop={{
				tickSize: 0,
				tickPadding: 5,
				tickRotation: 0,
				legend: '',
				legendOffset: 36,
				format: (d) => weekDayTranslate(d),
			}}
			axisLeft={{ tickSize: 0, tickPadding: 3, format: (d) => `v${d}` }} // adding v. suffix to week-number
			cellOpacity={1}
			colors={graphPalette}
			cellBorderColor={{ from: 'color', modifiers: [['darker', 0.4]] }}
			labelTextColor={(d) =>
				chroma.contrast(d.color, '#000') > 4.5 ? '#000' : '#fff'
			}
			padding={1}
			animate={true}
			motionStiffness={80}
			motionDamping={9}
			hoverTarget="cell"
			cellHoverOthersOpacity={0.25}
			tooltip={toolTipDisplay}
			theme={graphTheme}
		/>
	);
}
