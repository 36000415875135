import { forwardRef } from 'react';
import {
	Typography,
	CardHeader as MCardHeader,
	Divider,
	FormLabel as FLabel,
	Card,
	CardContent,
} from '@mui/material';
import { styled } from '@mui/styles';

export const PageHeader = (props: { title: string }) => (
	<Typography
		variant="h4"
		style={{ margin: '20px auto 2px 0.4em', color: '#333' }}
	>
		{props.title}
	</Typography>
);

export const CardHeader = (props: { title: string }) => (
	<div>
		<MCardHeader
			style={{ color: '#666', margin: '3px 0 -6px 12px' }}
			title={props.title}
		/>
		<Divider
			style={{ color: '#000', width: 'calc(100% - 44px)', margin: '0 auto' }}
		/>
	</div>
);

export const FormLabel = styled(FLabel)({
	root: {
		color: '#000',
		marginTop: '1.5rem',
	},
});

const GraphCard = styled(Card)({
	height: '100%',
});

const GraphCardHeader = styled(MCardHeader)({
	color: '#2D2E33',
	paddingBottom: 0,
});

/**
 * Styled component
 */
export const GraphContainer = forwardRef(
	(
		props: {
			id?: string;
			title?: string;
			subheader?: string | null;
			height?: number | string;
			contentPadding?: number;
			style?: React.CSSProperties;
			children: React.ReactNode;
		},
		ref: any
	) => {
		const { id, title, subheader, height, contentPadding, style, children } =
			props;

		return (
			<GraphCard id={id} style={{ ...style, overflow: 'visible' }} ref={ref}>
				<GraphCardHeader title={title} subheader={subheader} />
				<CardContent
					style={{
						height: height ? height : 400,
						padding: contentPadding ? contentPadding : 0,
					}}
				>
					{children}
				</CardContent>
			</GraphCard>
		);
	}
);

export const HelpText = (text: string) => (
	<p style={{ fontStyle: 'italic', color: '#888', padding: 12 }}>{text}</p>
);
