import ReactDOM from 'react-dom';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import './index.css';
import './i18n';
import App from './App';
import { AppThemeProvider } from './contexts/Providers';

ReactDOM.render(
	<AppThemeProvider>
		<LocalizationProvider dateAdapter={AdapterDateFns}>
			<App />
		</LocalizationProvider>
	</AppThemeProvider>,
	document.getElementById('root')
);
