import { useState, useEffect, useContext } from 'react';
import {
	FormControl,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
	Card,
	CardContent,
	CardHeader,
	Fade,
} from '@mui/material';
import { useQuery, useMutation, useLazyQuery } from '@apollo/client';
import gql from 'graphql-tag';
import Select from 'react-select';
import { useTranslation } from 'react-i18next';
import { addMonths } from 'date-fns';

import {
	ClinicSelector,
	ClinicSelectorProps,
	labelSort,
} from '../common/ClinicSelector';
import { PageHeader, FormLabel, GraphContainer } from '../common/AppLayout';
import { BarChart } from '../page-specific/patient-number/PatientNumberGraph';
import './gridLayout.scss';
import { styles } from '../../theme';
import { DateRangeSelector } from '../common/DateRangeSelector';
import AgerangeDialog from '../common/AgerangeDialog';
import VisitMonthHeatmap from '../page-specific/patient-visit/VisitMonthHeatmap';
import {
	APPOINTMENTCAUSE_QUERY,
	PATIENTAGEGROUP_QUERY,
	PAYMENTCLASS_QUERY,
	VISITS_QUERY as VQ,
} from '../queries/DataQueries';
import {
	AppThemeContext,
	ClinicInformationContext,
} from '../../contexts/Providers';
import { AgeRangeSelection, BaseOptionID } from '../common/types/Page';
import { ClinicOption } from '../common/types/Page';

const SETTINGS_QUERY = gql`
  query multiQuery {
    ${PATIENTAGEGROUP_QUERY}
    ${APPOINTMENTCAUSE_QUERY}
    ${PAYMENTCLASS_QUERY}
  }
`;

const AGERANGE_MUTATION = gql`
	mutation agerangeMutation($from: Int!, $to: Int!, $label: String!) {
		insert_ftv_patientagegroup(
			objects: { lowerlimit: $from, upperlimit: $to, name: $label }
		) {
			returning {
				patientagegroupid
				lowerlimit
				upperlimit
			}
		}
	}
`;

const VISITS_QUERY = gql`
	${VQ}
`;

/**
 * Process GQL data from backend to selector
 * @param {Array} arr
 */
const allPatientAgeGroups = (arr: any) => {
	return arr
		.map((item: any) => {
			const name =
				`${item.lowerlimit}` +
				(item.upperlimit >= 100 ? '+' : `-${item.upperlimit}`);
			return {
				id: item.patientagegroupid,
				lowerlimit: item.lowerlimit,
				upperlimit: item.upperlimit,
				label: name,
				value: name,
			};
		})
		.sort(labelSort);
};

// Add button
const addPatientAgeGroup = {
	label: '+ Add',
	value: 'add',
	isFunc: true,
	id: -1,
};

// baseProps used for selectors
const baseProps = {
	isMulti: true,
	closeOnMenuSelect: true,
};

interface SelectOptions {
	clinic: Array<ClinicOption>;
	appointment: Array<BaseOptionID>;
	patientagegroup: Array<AgeRangeSelection>;
	paymentclass: Array<BaseOptionID>;
}

function PatientVisit() {
	const { t } = useTranslation();
	const { palette } = useContext(AppThemeContext);

	// All options
	const [options, setOptions] = useState<SelectOptions>({
		clinic: [],
		appointment: [],
		patientagegroup: [],
		paymentclass: [],
	}); // Combines all available options
	const [settingsDataLoaded, setSettingsDataLoaded] = useState(false); // if all settings-data has been loaded
	const [visitData, setVisitData] = useState<Array<any>>([]);
	const [tableData, setTableData] = useState<Array<any>>([]);

	// User selections
	const [selectedClinics, setSelectedClinics] = useState<Array<ClinicOption>>(
		[]
	);
	const [dateRange, setDateRange] = useState({
		start: new Date(),
		end: addMonths(new Date(), 3),
	});
	const [selectedPatientagegroups, setSelectedPatientagegroups] = useState<
		Array<AgeRangeSelection>
	>([]);

	// Age dialog window
	const [ageDialog, setAgeDialog] = useState(false);

	const clinics = useContext(ClinicInformationContext);

	// Update, this function will not run unless `doAgerangeMutation` is called
	const [doAgerangeMutation] = useMutation(AGERANGE_MUTATION, {
		onCompleted: (data) => {
			const retOpt = data.insert_ftv_patientagegroup.returning[0]; // item that was updated/added
			const name =
				`${retOpt.lowerlimit}` +
				(retOpt.upperlimit > 999 ? '+' : `-${retOpt.upperlimit}`);
			const newOpt = {
				id: retOpt.patientagegroupid,
				lowerlimit: retOpt.lowerlimit,
				upperlimit: retOpt.upperlimit,
				label: name,
				value: name,
			};
			// adding the new item last
			setOptions({
				...options,
				patientagegroup: options.patientagegroup.concat([newOpt]),
			});
		},
	});

	// Loading SETTINGS_QUERY data, used for Settings
	useQuery(SETTINGS_QUERY, {
		onCompleted: (multiquery_data) => {
			// Todo: this if-statement should not be necessary
			if (multiquery_data) {
				// Managing multiquery data and set initial selections and options

				const allAppointmentCauses = multiquery_data.ftv_appointmentcause
					.map((item: any) => {
						return { id: item.id, value: item.name, label: item.name };
					})
					.sort(labelSort);

				const allpatientagegroups = allPatientAgeGroups(
					multiquery_data.ftv_patientagegroup
				);

				const allPaymentclass = multiquery_data.ftv_paymentclass
					.map((item: any) => {
						return { id: item.id, value: item.name, label: item.name };
					})
					.sort(labelSort);

				setOptions({
					clinic: clinics,
					appointment: allAppointmentCauses,
					patientagegroup: allpatientagegroups.concat([addPatientAgeGroup]),
					paymentclass: allPaymentclass,
				});

				// Setting defaults
				setSelectedClinics(clinics);
				setSelectedPatientagegroups(allpatientagegroups);

				setSettingsDataLoaded(true);
			}
		},
	});

	// Loading visitation data
	const [getVisitData, { data: visitsQuery_data }] = useLazyQuery(
		VISITS_QUERY,
		{
			onCompleted: () => {
				if (visitsQuery_data) {
					// Managing visitQuery data and data process for graph
					// Process into graph data
					const tmp: Array<any> = [];
					visitsQuery_data.ftv_get_plannedvisit.forEach((item: any) => {
						const findIndex = tmp.findIndex(
							(v) => v.clinic_id === item.clinic_id
						);
						const ageLabel = options.patientagegroup.find(
							(p) => p.id === item.agegroupid
						)?.label;
						const clinicLabel = options.clinic.find(
							(c) => c.id === item.clinic_id
						)?.label;

						if (findIndex !== -1 && ageLabel) {
							// update old
							tmp[findIndex] = { ...tmp[findIndex], [ageLabel]: item.count };
						} else {
							// append new
							if (ageLabel)
								// remove this?
								tmp.push({
									clinic_id: item.clinic_id,
									clinic: clinicLabel,
									[ageLabel]: item.count,
								});
						}
					});
					setVisitData(tmp.sort((a, b) => (a.clinic < b.clinic ? -1 : 0)));
				}
			},
		}
	);

	const [getTableData, { data: table_data }] = useLazyQuery(VISITS_QUERY, {
		onCompleted: () => {
			if (table_data && table_data.ftv_get_plannedvisit) {
				const tmp: Array<any> = [];
				options.clinic.forEach((c) => {
					// const tm = table_data.ftv_get_plannedvisit.filter(clinic => c.id === clinic.clinic_id);
					const partialSum = table_data.ftv_get_plannedvisit
						.filter((clinic: any) => c.id === clinic.clinic_id)
						.reduce((acc: number, s: any) => acc + s.count, 0);
					tmp.push({
						name: c.label,
						visit: partialSum,
					});
				});

				setTableData(tmp);
			}
		},
	});

	// Will re-fetch graph data
	useEffect(() => {
		const getDataParam = {
			variables: {
				start: dateRange.start,
				end: dateRange.end,
				clinics: selectedClinics.map((item) => item.id),
				agegroups: selectedPatientagegroups
					? selectedPatientagegroups.map((item) => item.id)
					: [],
				paymentclasses: options.paymentclass.map((item) => item.id),
				appointmentcauses: options.appointment.map((item) => item.id),
			},
		};
		getVisitData(getDataParam);
	}, [
		selectedClinics,
		dateRange,
		selectedPatientagegroups,
		options.paymentclass,
		options.appointment,
		getVisitData,
	]);

	// Updating table data
	useEffect(() => {
		const getTableDataParam = {
			variables: {
				start: dateRange.start,
				end: dateRange.end,
				clinics: options.clinic.map((item) => item.id),
				agegroups: selectedPatientagegroups
					? selectedPatientagegroups.map((item) => item.id)
					: [],
				paymentclasses: options.paymentclass.map((item) => item.id),
				appointmentcauses: options.appointment.map((item) => item.id),
			},
		};
		getTableData(getTableDataParam);
	}, [
		options.clinic,
		dateRange,
		selectedPatientagegroups,
		options.paymentclass,
		options.appointment,
		getTableData,
	]);

	if (settingsDataLoaded) {
		// Props for Select components
		const clinicProps: ClinicSelectorProps = {
			...baseProps,
			options: options.clinic,
			value: selectedClinics,
			updateSelection: setSelectedClinics,
			placeholder: t('select clinics'),
		};

		const patientagegroupProps = {
			...baseProps,
			options: options.patientagegroup,
			value: selectedPatientagegroups,
			onChange: (opts: any, event: any) => onSelection(opts, event),
			placeholder: t('select age-groups'),
			noOptionsMessage: () => t('no options'),
			menuPortalTarget: document.querySelector('body'),
		};

		/**
		 * Checks if user presses a selection containing a "function", and opens a dialog
		 * @param {Array} opts new options used by selector
		 * @param {Object} event event
		 */
		const onSelection = (opts: any, event: any) => {
			if (event.action === 'select-option' && event.option.isFunc)
				setAgeDialog(true);
			else setSelectedPatientagegroups(opts);
		};

		/**
		 * GQL mutation/add `patientagegroup` to backend, and closes the window
		 * @param {Object} obj A "date"-object with keys: `from` and `to`
		 */
		const updatePatientAge = (obj: any) => {
			if ('to' in obj && 'from' in obj) {
				doAgerangeMutation({
					variables: {
						from: obj.from,
						to: obj.unlimitedTo ? 999 : obj.to,
						label: `${obj.from}-${obj.to}`,
					},
				});
			}
			setAgeDialog(false);
		};

		// Render
		return (
			<div style={{ ...styles.mainContent }}>
				<AgerangeDialog enabled={ageDialog} return={updatePatientAge} />

				<PageHeader title={t('patient-visit')} />

				<div id="gridColSplit">
					<div id="mainGrid">
						<Paper id="graph" style={{ height: 400 }}>
							<BarChart
								data={visitData}
								keys={
									selectedPatientagegroups
										? selectedPatientagegroups.map((item) => item.label)
										: []
								}
								palette={palette.colors}
							/>
						</Paper>

						<Fade in={tableData ? true : false} timeout={1000}>
							<Card>
								<CardHeader
									title="Klinik"
									subheader="Tabelldata för alla kliniker"
								/>
								<CardContent>
									<Table>
										<TableHead>
											<TableRow>
												<TableCell padding="none">Klinik</TableCell>
												<TableCell padding="none">Patienter</TableCell>
											</TableRow>
										</TableHead>
										<TableBody>
											{tableData.map((item) => (
												<TableRow key={item.name}>
													<TableCell padding="none">{item.name}</TableCell>
													<TableCell padding="none" align="right">
														{item.visit}
													</TableCell>
												</TableRow>
											))}
										</TableBody>
									</Table>
								</CardContent>
							</Card>
						</Fade>

						<GraphContainer
							title={t('visits')}
							subheader="Senaste 4 veckorna"
							height={300}
							contentPadding={12}
						>
							<VisitMonthHeatmap />
						</GraphContainer>
					</div>

					<div id="settings">
						<Card>
							<CardHeader title={t('settings')} />
							<CardContent>
								<FormControl style={{ width: '100%' }}>
									<FormLabel required>{t('date-interval')}</FormLabel>
									<DateRangeSelector
										onChange={(s) =>
											setDateRange({ start: s.start, end: s.end })
										}
										startDate={dateRange.start}
										endDate={dateRange.end}
									/>

									<FormLabel required>{t('clinics')}</FormLabel>
									<ClinicSelector {...clinicProps} />

									<FormLabel required>{t('age-groups')}</FormLabel>
									<Select {...patientagegroupProps} />
								</FormControl>
							</CardContent>
						</Card>
					</div>
				</div>
			</div>
		);
	}
	return null;
}

export default PatientVisit;
