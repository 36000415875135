import Chroma from 'chroma-js';

export const baseColors = {
	main: '#00A6E2',
	shiffer: '#706f6f',
	concrete: '#b2b2b2',
	darkBlue: '#003d7c',
	lightBlue: '#29b8cc',
	lightGreen: '#98c21d',
	darkGreen: '#2d9d46',
	purple: '#6e368c',
	pink: '#d7007f',
	red: '#e31836',
	orange: '#f18700',
	dark: '#2D2E33',
};

export const altColors = {
	light_green: Chroma(185, 219, 173, 'rgb'),
	dark_green: Chroma(105, 130, 122, 'rgb'),
	yellow: Chroma(203, 138, 54),
	blue: Chroma(109, 119, 141),
	brown: Chroma(119, 103, 90),
	red: Chroma(159, 63, 63),
	purple: Chroma(134, 101, 154),
	cyan: Chroma(172, 218, 215),
};

export const styles = {
	mainContent: {
		marginLeft: 'auto',
		marginRight: 'auto',
		marginTop: '110px',
		width: '1440px',
	},
};
