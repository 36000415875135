import { useState, useContext, useEffect, Fragment } from 'react';

import {
	Card,
	CardContent,
	CardHeader,
	FormControl,
	Fade,
	Switch,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

import './gridLayout.scss';
import {
	PageHeader,
	FormLabel,
	GraphContainer,
	HelpText,
} from '../common/AppLayout';
import { styles } from '../../theme';
import { ClinicSelector, ClinicSelectorProps } from '../common/ClinicSelector';
import { DateSelector } from '../common/DateRangeSelector';
import {
	AppThemeContext,
	ClinicInformationContext,
} from '../../contexts/Providers';
import { ClinicOption } from '../common/types/Page';
import {
	PatientFrisktandvardGraph,
	PatientFrisktandvardTimeLine,
	ClinicData,
} from '../page-specific/patient-frisktandvard/PatientFrisktandvardGraph';
import { PieChart, PieChartData } from '../common/CommonGraphs';
import { Serie } from '@nivo/line';
import { clinicsGroup } from '../page-specific/patient-frisktandvard/PatientFriskvardData';

const clinicData: Array<ClinicData> = clinicsGroup.map((item) => {
	let sum = 0;
	Object.entries(item).forEach(([key, value]) => {
		if (key !== 'clinic') sum += value as number;
	});
	return { ...item, sum: sum };
});

const randNewPatients = (prev: number) => {
	const rand = Math.random();
	if (rand >= 0.95) return Math.floor(0.3 * prev);
	else if (rand >= 0.9) return Math.floor(0.5 * prev);
	else if (rand >= 0.86) return Math.floor(-0.2 * prev);
	else return 0;
};

const clinicsTimeLineData = clinicData.map((item) => {
	const now = new Date();
	let base = item.sum;
	const tmpObj: {
		id: string;
		data: Array<{ x: string | number; y: string | number }>;
	} = { id: item.clinic, data: [] };

	for (let d = new Date(2019, 1, 1); d <= now; d.setDate(d.getDate() + 30)) {
		const newPatients = randNewPatients(base);
		tmpObj.data.push({
			y: base + newPatients,
			x: d.toISOString().split('T')[0],
		});
		base += newPatients;
	}
	return tmpObj;
});

const keys = ['A1', 'A2', 'A3', 'A4', 'A5', 'A6', 'A7', 'A8', 'A9', 'A10'];

function PatientFriskTandvard() {
	const clinics = useContext(ClinicInformationContext);
	const [graphData, setGraphData] = useState<{
		main: Array<any>;
		timeline: Array<Serie>;
		chart: Array<PieChartData>;
	}>({ main: [], timeline: [], chart: [] });
	const [selectedClinics, setSelectedClinics] =
		useState<Array<ClinicOption>>(clinics);
	const [selectedDate, setSelectedDate] = useState(new Date());
	const [selections, setSelections] = useState({
		singleClinic: null,
		isRelative: false,
	});
	const { t } = useTranslation();
	const { palette } = useContext(AppThemeContext);

	useEffect(() => {
		const tmpArrMain: Array<any> = [];
		const tmpArrTimeLine: Array<Serie> = [];
		selectedClinics.forEach((clinic) => {
			const partial = clinicData.find((item) => item.clinic === clinic.label);
			tmpArrMain.push(partial);
			const fc = clinicsTimeLineData.find((item) => item.id === clinic.label);
			if (fc) tmpArrTimeLine.push(fc);
		});
		tmpArrMain.sort((a, b) => {
			if (a.clinic < b.clinic) return -1;
			else return 1;
		});

		tmpArrTimeLine.sort((a, b) => {
			if (a.id < b.id) return 1;
			else return -1;
		}); // sorted in reverse order

		setGraphData({ ...graphData, main: tmpArrMain, timeline: tmpArrTimeLine });

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedClinics]);

	const onClickSingleClinic = (data: any) => {
		const tmpArr = [];
		for (let i = 1; i < 11; i++) {
			tmpArr.push({
				id: `A${i}`,
				label: `A${i}`,
				value: clinicsGroup[data.index][`A${i}`],
			});
		}

		setGraphData({ ...graphData, chart: tmpArr });
		setSelections({ ...selections, singleClinic: data.indexValue });
	};

	const handleRelativeChange = (event: any, isToggled: boolean) => {
		setSelections({ ...selections, isRelative: isToggled });
	};

	// Render
	if (clinics && clinics.length) {
		// clinic selector props
		const clinicProps: ClinicSelectorProps = {
			options: clinics,
			updateSelection: setSelectedClinics,
			placeholder: t('select clinics'),
			value: selectedClinics,
		};

		const mainGraphProps = {
			id: 'graph',
			title: graphData.main.length ? t('dental-healthcare-contract') : '',
			subheader: graphData.main.length
				? selectedDate.toISOString().split('T')[0]
				: '',
			height: 450,
		};

		return (
			<div style={styles.mainContent}>
				<PageHeader title={t('dental-healthcare')} />

				<div id="gridColSplit">
					<div id="mainGrid">
						<GraphContainer {...mainGraphProps}>
							{graphData.main.length ? (
								<Fragment>
									<Switch
										color="primary"
										value="relative"
										onChange={handleRelativeChange}
									/>
									<PatientFrisktandvardGraph
										data={graphData.main}
										keys={selections.isRelative ? keys : ['sum']}
										// palette={selections.isRelative ? altPalette : ftvPalette}
										palette={palette.colors}
										cb={onClickSingleClinic}
										isRelative={selections.isRelative}
									/>
								</Fragment>
							) : (
								HelpText('Ingen klinik vald')
							)}
						</GraphContainer>

						<Fade in={selections.singleClinic !== null} timeout={1000}>
							<GraphContainer
								title={'Avgiftsklasser'}
								subheader={selections.singleClinic}
							>
								<PieChart data={graphData.chart} palette={palette.colors} />
							</GraphContainer>
						</Fade>

						<Fade in={selections.singleClinic !== null} timeout={1000}>
							<Card>
								<CardHeader title={selections.singleClinic} />
								<CardContent>
									<Table size="small">
										<TableHead>
											<TableRow>
												<TableCell>Avgiftsklass</TableCell>
												<TableCell>Antal</TableCell>
											</TableRow>
										</TableHead>
										<TableBody>
											{graphData.chart.map((item) => {
												return (
													<TableRow key={item.label}>
														<TableCell>{item.label}</TableCell>
														<TableCell>{item.value}</TableCell>
													</TableRow>
												);
											})}
										</TableBody>
									</Table>
								</CardContent>
							</Card>
						</Fade>

						<Fade in={graphData.timeline.length > 0}>
							<GraphContainer
								id="graph"
								title={'Frisktandvårdsavtal över tid'}
								height={400}
							>
								<PatientFrisktandvardTimeLine
									data={graphData.timeline}
									palette={palette.colors}
								/>
							</GraphContainer>
						</Fade>
					</div>

					<div id="settings">
						<Card>
							<CardHeader title={t('settings')} />
							<CardContent>
								<FormControl style={{ width: '100%' }}>
									<FormLabel required>{t('date')}</FormLabel>

									<DateSelector
										date={selectedDate}
										onChange={setSelectedDate}
									/>

									<FormLabel required>{t('clinics')}</FormLabel>
									<ClinicSelector {...clinicProps} />
								</FormControl>
							</CardContent>
						</Card>
					</div>
				</div>
			</div>
		);
	}
	return null;
}

export default PatientFriskTandvard;
