import { useState, useEffect, useContext } from 'react';
import {
	Card,
	CardContent,
	CardHeader,
	FormControl,
	Fade,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
	TextField,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';
import { DatePicker } from '@mui/lab';
import './gridLayout.scss';
import {
	PageHeader,
	FormLabel,
	GraphContainer,
	HelpText,
} from '../common/AppLayout';
import { styles } from '../../theme';
import { useLazyQuery } from '@apollo/client';
import { STVChart, STVPie } from '../page-specific/staff-stv/StaffSTVChart';
import { AppThemeContext } from '../../contexts/Providers';
import {
	StaffQuery,
	STVVIEW_QUERY,
	STV_CLINICS_QUERY,
} from '../queries/StaffQueries';

const graphKeys = [
	'barn',
	'vuxna',
	'utbildning',
	'handledning',
	'klinikutveckling',
	'administration',
	'chefsadm',
	'kons',
	'kursgivning',
	'samverkan',
	'utv',
];

type StaffSTVGraphData = {
	main: Array<any>;
	pie: Array<any>;
	staff: Array<any>;
};

function StaffSTV() {
	const { t } = useTranslation();
	const { palette } = useContext(AppThemeContext);

	const [graphData, setGraphData] = useState<StaffSTVGraphData>({
		main: [],
		pie: [],
		staff: [],
	});
	const [STVClinics, setSTVClinics] = useState<Array<any>>([]);
	const [selectedDate, handleDateChange] = useState(new Date());
	const [selectedClinics, setSelectedClinics] = useState<Array<any>>([]);
	const [selectedSingleClinic, setSelectedSingleClinic] = useState({
		name: '',
		id: '',
	});

	const [getSTVClinics] = useLazyQuery(STV_CLINICS_QUERY, {
		onCompleted: (data) => {
			const tmp = data.ftv_clinic.map((item: any) => {
				return { id: item.id, label: item.name, value: item.name };
			});
			setSTVClinics(tmp);
			setSelectedClinics(tmp);
		},
		fetchPolicy: 'network-only',
	});

	const [getSTVAggreg] = useLazyQuery(STVVIEW_QUERY, {
		onCompleted: (data) => {
			setGraphData({ ...graphData, main: data.ftv_stvview });
		},
		fetchPolicy: 'network-only',
	});

	const [getStaff] = useLazyQuery(StaffQuery, {
		onCompleted: (data) => {
			setGraphData({ ...graphData, staff: data.ftv_stv });
		},
		fetchPolicy: 'network-only',
	});

	useEffect(() => {
		getSTVClinics();
	}, [getSTVClinics]);

	useEffect(() => {
		getSTVAggreg({
			variables: {
				month: selectedDate.getUTCMonth() + 1,
				clinics: selectedClinics.map((item: any) => item.id),
			},
		});
		if (selectedSingleClinic.id) {
			getStaff({
				variables: {
					year: selectedDate.getUTCFullYear(),
					month: selectedDate.getUTCMonth() + 1,
					clinic: selectedSingleClinic.id,
				},
			});
		}
	}, [
		getSTVAggreg,
		selectedClinics,
		selectedDate,
		getStaff,
		selectedSingleClinic.id,
	]);

	const onClickBar = (data: any) => {
		const tmpArr: Array<any> = [];
		// index based
		Object.entries(graphData.main[data.index]).forEach(([key, value]) => {
			if (graphKeys.indexOf(key) !== -1) {
				tmpArr.push({
					id: key,
					label: key,
					value: value,
				});
			}
		});
		setSelectedSingleClinic({
			...selectedSingleClinic,
			name: data.indexValue,
			id: data.data.klinik,
		});
		setGraphData({ ...graphData, pie: tmpArr });
	};

	if (STVClinics.length > 0) {
		return (
			<div style={styles.mainContent}>
				<PageHeader title="Arbetad tid STV" />

				<div id="gridColSplit">
					<div id="mainGrid">
						<GraphContainer id="graph" height="32rem">
							{graphData.main.length > 0 ? (
								<STVChart
									data={graphData.main}
									keys={graphKeys}
									palette={palette.colors}
									cb={onClickBar}
									indexBy="clinic"
								/>
							) : (
								HelpText('Välj klinik, år och månad')
							)}
						</GraphContainer>

						<Fade in={graphData.pie.length > 0}>
							<GraphContainer title={selectedSingleClinic.name}>
								<STVPie data={graphData.pie} palette={palette.colors} />
							</GraphContainer>
						</Fade>

						<Fade in={graphData.pie.length > 0}>
							<Card>
								<CardHeader title={selectedSingleClinic.name} />
								<CardContent>
									<Table>
										<TableHead>
											<TableRow>
												<TableCell padding="none">Tidsart</TableCell>
												<TableCell padding="none">Antal timmar</TableCell>
											</TableRow>
										</TableHead>
										<TableBody>
											{graphData.pie.map((item) => (
												<TableRow key={item.id}>
													<TableCell padding="none">{item.label}</TableCell>
													<TableCell padding="none" align="right">
														{item.value}
													</TableCell>
												</TableRow>
											))}
										</TableBody>
									</Table>
								</CardContent>
							</Card>
						</Fade>

						<Fade in={graphData.staff.length > 0}>
							<GraphContainer
								id="graph"
								title={selectedSingleClinic.name}
								subheader={'Personaltimmar'}
							>
								<STVChart
									data={graphData.staff}
									keys={graphKeys}
									palette={palette.colors}
									indexBy="namn"
								/>
							</GraphContainer>
						</Fade>
					</div>

					<div id="settings">
						<Card>
							<CardHeader title={t('settings')} />
							<CardContent>
								<FormControl>
									<FormLabel required>{'Välj år och månad'}</FormLabel>
									<DatePicker
										openTo="year"
										views={['year', 'month']}
										label="År och månad"
										value={selectedDate}
										onChange={(d) => d && handleDateChange(d)}
										renderInput={(params) => <TextField {...params} />}
									/>

									<FormLabel required>{t('clinics')}</FormLabel>
									<Select
										isMulti={true}
										closeMenuOnSelect={false}
										options={STVClinics}
										value={selectedClinics}
										onChange={(value) => setSelectedClinics([...value])}
										menuPortalTarget={document.querySelector('body')}
									/>
								</FormControl>
							</CardContent>
						</Card>
					</div>
				</div>
			</div>
		);
	}
	return null;
}

export default StaffSTV;
