import { ResponsiveLine } from '@nivo/line';

import { graphTheme } from '../../common/GraphGlobalSettings';
import { addMonths } from 'date-fns';

const LASTPERIODPOSTFIX = '-fp';

// Styles for dashed and non-dashed lines
// const styleById = {
//   dashed: {
//     strokeDasharray: '12, 8',
//     strokeWidth: 2
//   },
//   default: {
//     strokeWidth: 2
//   }
// };

/**
 * Draws either a dashed or non-dashed line depending on id-name
 * @param {object} series used by Nivo
 * @param {function} lineGenerator used by Nivo
 * @param {function} xScale used by Nivo
 * @param {function} yScale used by Nivo
 */
// const DashedLine = (series: Array<any>, lineGenerator: any, xScale: any, yScale: any ) => {
//   return series.map(({ id, data, color }) => {
//     return <path
//       key={id}
//       d={lineGenerator(
//         data.map( (d: any) => ({
//           x: xScale(d.data.x),
//           y: yScale(d.data.y)
//         }))
//       )}
//       fill='none'
//       stroke={color}
//       style={id.includes(LASTPERIODPOSTFIX) ? styleById.dashed : styleById.default}
//     />
//   })
// };

interface PatientQueueGraphProps {
	data: Array<any>;
	yLabel: string;
	palette: Array<any>;
}
/**
 * Line graph
 * @param {Array} data data-array for output
 * @param {String} yLabel y-Axis title
 * @param {Array} palette Array consisting of colors in hex-string
 */
export const PatientQueueGraph = (props: PatientQueueGraphProps) => {
	const { data, yLabel, palette } = props;
	/**
	 * Draws tooltip
	 * @param {object} data data sent by Nivo
	 */
	function toolTip(data: any) {
		const pointData = data.point;
		const title = pointData.serieId.split(LASTPERIODPOSTFIX)[0];
		const isLastPeriod = pointData.serieId.includes(LASTPERIODPOSTFIX);

		return (
			<div
				style={{ color: '#fff', backgroundColor: '#333', padding: '0.3rem' }}
			>
				<h4 style={{ marginTop: 0 }}>
					{`FTV ${title}`} {isLastPeriod ? <em>förra perioden</em> : null}
				</h4>
				<div>
					{isLastPeriod
						? addMonths(new Date(pointData.data.day), -12)
								.toISOString()
								.split('T')[0]
						: pointData.data.day}
				</div>
				<div>{`Antal patienter: ${pointData.data.count} stycken.`}</div>
				<div>{`Kölängd (medelvärde): ${Math.round(
					pointData.data.avg_wait
				)} antal dagar.`}</div>
			</div>
		);
	}

	return (
		<ResponsiveLine
			data={data}
			// layers={['grid', 'markers', 'areas', DashedLine, 'crosshair', 'slices', 'points', 'axes', 'legends', 'mesh']}
			margin={{ top: 8, right: 150, bottom: 100, left: 100 }}
			xScale={{
				type: 'time',
				format: '%Y-%m-%d',
				precision: 'day',
			}}
			xFormat="time:%Y-%m-%d"
			yScale={{ type: 'linear', stacked: false, min: 'auto', max: 'auto' }}
			curve="linear"
			axisBottom={{
				tickSize: 5,
				tickPadding: 15,
				tickRotation: 45,
				format: '%d %b',
				tickValues: 'every 1 week',
			}}
			axisLeft={{
				tickSize: 5,
				tickPadding: 5,
				tickRotation: 0,
				legend: yLabel,
				legendPosition: 'middle',
				legendOffset: -60,
			}}
			pointSize={1}
			pointColor={{ from: 'color', modifiers: [] }}
			pointBorderWidth={2}
			pointBorderColor={{ from: 'serieColor' }}
			pointLabel="y"
			pointLabelYOffset={-12}
			areaOpacity={0.3}
			useMesh={true}
			legends={[
				{
					anchor: 'bottom-right',
					direction: 'column',
					justify: false,
					translateX: 100,
					translateY: 0,
					itemsSpacing: 0,
					itemDirection: 'left-to-right',
					itemWidth: 80,
					itemHeight: 20,
					itemOpacity: 0.75,
					symbolSize: 12,
					symbolShape: 'circle',
					symbolBorderColor: 'rgba(0, 0, 0, .5)',
					effects: [
						{
							on: 'hover',
							style: {
								itemBackground: 'rgba(0, 0, 0, .03)',
								itemOpacity: 1,
							},
						},
					],
				},
			]}
			colors={palette}
			tooltip={(data) => toolTip(data)}
			theme={graphTheme}
		/>
	);
};
