import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import {
	AppBar,
	Toolbar,
	IconButton,
	Typography,
	Tooltip,
	Menu,
	MenuItem,
	useScrollTrigger,
	Slide,
	Button,
	Icon,
	List,
	ListItemButton,
	ListItemText,
	Fade,
	Box,
} from '@mui/material';

import { makeStyles } from '@mui/styles';
import { useTranslation } from 'react-i18next';
import { AccountCircle, ColorLens, Home } from '@mui/icons-material';

import { baseColors } from '../../theme';
import LogoutDialog from './LogoutDialog';
import ThemeDialog from './ThemeDialog';
import { styled } from '@mui/material/styles';
import chroma from 'chroma-js';

const useStyles = makeStyles({
	toolbar: {
		marginLeft: 'auto',
		marginRight: 'auto',
		display: 'flex',
		justifyContent: 'space-between',
		color: '#fff',
		width: '100%',
		maxWidth: '1440px',
	},
	otherToolBarContainer: {
		width: '100%',
		padding: '0 !important',
		backgroundColor: '#2D2E33',
	},
	otherToolbar: {
		boxSizing: 'border-box',
		marginLeft: 'auto',
		marginRight: 'auto',
		paddingLeft: '0.8rem',
		width: '100%',
		maxWidth: '1440px',
	},
	hideOtherToolbar: {
		opacity: 0,
		height: 0,
	},
	root: {
		width: '100%',
	},
});

enum NavHeader {
	Home = -1,
	Patient = 0,
	Staff = 1,
	Economy = 2,
	Planning = 3,
}

interface AppbarProps {
	window?: () => Window;
	children?: React.ReactElement;
}
/**
 * Used to hide Topbar when scrolling down
 */
function HideOnScroll(props: {
	children: React.ReactElement;
	window?: () => Window;
}) {
	const { children, window } = props;
	const trigger = useScrollTrigger({ target: window ? window() : undefined });

	return (
		<Slide appear={false} direction="down" in={!trigger}>
			{children}
		</Slide>
	);
}

interface LinkItem {
	link: string;
	text: string;
}

const ALL_LINKS: Array<{
	header: string;
	baseUrl: string;
	items: Array<LinkItem>;
}> = [
	{
		header: 'patient',
		baseUrl: 'patient',
		items: [
			{ link: 'patient', text: 'patients' },
			{ link: 'visit', text: 'visits' },
			{ link: 'queue-patient', text: 'queue-patients' },
			{ link: 'recall', text: 'recall' },
			{ link: 'care', text: 'measure-statistics' },
			{
				link: 'frisktandvard',
				text: 'dental-healthcare',
			},
			{ link: 'risk', text: 'risk-groups' },
			{ link: 'survey', text: 'poll' },
		],
	},
	{
		header: 'staff',
		baseUrl: 'staff',
		items: [
			{ link: 'worktime', text: 'ATV-work' },
			{ link: 'schedule', text: 'ATV-work2' },
			{ link: 'stv', text: 'STV-work' },
		],
	},
	{
		header: 'income',
		baseUrl: 'economy',
		items: [{ link: 'income', text: 'income' }],
	},
	{
		header: 'scheduler',
		baseUrl: 'planning',
		items: [
			{ link: 'scheduler', text: 'scheduler' },
			{ link: 'simulation', text: 'simulation' },
			{ link: 'scheduler-queue', text: 'referrals' },
			{
				link: 'scheduler-activities',
				text: 'examinations',
			},
			{ link: 'referral', text: 'referral' },
		],
	},
];

const StyledListItem = styled(ListItemButton)(({ theme }) => ({
	backgroundColor: baseColors.lightBlue,
	color: '#fff',
	'&.Mui-selected': {
		backgroundColor: baseColors.dark,
	},
	'&.Mui-selected:hover': {
		backgroundColor: chroma(baseColors.dark).brighten().hex(),
	},
	':disabled': {
		backgroundColor: chroma('#333').brighten().hex(),
	},
}));

interface TopBarProps extends AppbarProps {
	keycloak: Keycloak.KeycloakInstance;
}

function TopBar(props: TopBarProps) {
	const { t, i18n } = useTranslation();

	const [selectedNavHeader, setSelectedNavHeader] = useState(NavHeader.Home);
	const [selectedNavItem, setSelectedNavItem] = useState<{
		header: number;
		item: number | undefined;
	}>({ header: -1, item: undefined });
	const [navbarLinks, setNavbarLinks] = useState<LinkItem[]>([]);

	const [dialogs, setDialogs] = useState({
		logout: false,
		theme: false,
	});

	const [langSelected, setLangSelected] = useState('Svenska');
	const [langAnchorEl, setLangAnchorEl] = useState(null);

	const classes = useStyles();

	useEffect(() => {
		setNavbarLinks(
			selectedNavHeader === -1 ? [] : ALL_LINKS[selectedNavHeader].items
		);
	}, [selectedNavHeader]);

	const handleHomeClick = () => {
		setSelectedNavHeader(-1);
		setNavbarLinks([]);
		setSelectedNavItem({ header: selectedNavHeader, item: -1 });
	};

	const handleLangClick = (event: any) => {
		setLangAnchorEl(event.currentTarget);
	};
	const handleLangClose = () => {
		setLangAnchorEl(null);
	};

	const changeLanguage = (langShort: string) => {
		i18n.changeLanguage(langShort);
		if (langShort === 'en') setLangSelected('English');
		else if ('sv') setLangSelected('Svenska');

		handleLangClose();
	};

	if (props.keycloak) {
		const scrollObj = {
			children: props.children,
			window: props.window,
		};
		return (
			<>
				<ThemeDialog
					open={dialogs.theme}
					closeWindow={() => setDialogs({ ...dialogs, theme: false })}
				/>
				<LogoutDialog
					open={dialogs.logout}
					closeWindow={() => setDialogs({ ...dialogs, logout: false })}
					keycloak={props.keycloak}
				/>

				<HideOnScroll {...scrollObj}>
					<AppBar
						style={{ backgroundColor: baseColors.lightBlue }}
						elevation={8}
						// position='static'
						sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
					>
						<Toolbar variant="dense" className={classes.toolbar}>
							<List
								disablePadding={true}
								dir="row"
								component="nav"
								style={{ display: 'flex', marginLeft: '0.8rem' }}
							>
								<StyledListItem
									selected={selectedNavHeader.valueOf() === -1}
									onClick={handleHomeClick}
									style={{ padding: '0 1rem' }}
								>
									<Link to={'/'} key={'home'} style={{ color: 'white' }}>
										<IconButton color="inherit">
											<Home />
										</IconButton>
									</Link>
								</StyledListItem>
								{ALL_LINKS.map((link, i) => (
									<StyledListItem
										key={link.header}
										onClick={() => setSelectedNavHeader(i)}
										selected={selectedNavHeader.valueOf() === i}
									>
										<ListItemButton>{t(link.header)}</ListItemButton>
									</StyledListItem>
								))}
							</List>
							<Box>
								<IconButton
									color="inherit"
									onClick={() => setDialogs({ ...dialogs, theme: true })}
								>
									<ColorLens />
								</IconButton>

								<Tooltip title="Change language">
									<Button
										size="small"
										color="inherit"
										startIcon={<Icon>language</Icon>}
										endIcon={<Icon>expand_more</Icon>}
										onClick={handleLangClick}
									>
										{langSelected}
									</Button>
								</Tooltip>
								<Menu
									anchorEl={langAnchorEl}
									open={Boolean(langAnchorEl)}
									onClose={handleLangClose}
									sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
								>
									<MenuItem onClick={() => changeLanguage('sv')}>
										Svenska
									</MenuItem>
									<MenuItem onClick={() => changeLanguage('en')}>
										English
									</MenuItem>
								</Menu>

								<Typography
									variant="body1"
									noWrap
									style={{ display: 'inline' }}
								>
									{props.keycloak.userInfo}
								</Typography>
								<Tooltip title="Logga ut">
									<IconButton
										color="inherit"
										onClick={() => setDialogs({ ...dialogs, logout: true })}
									>
										<AccountCircle />
									</IconButton>
								</Tooltip>
							</Box>
						</Toolbar>
						<Box className={classes.otherToolBarContainer}>
							<Toolbar
								variant="dense"
								disableGutters={true}
								className={classes.otherToolbar}
							>
								<Fade in={navbarLinks.length !== 0}>
									<List
										disablePadding={true}
										dir="row"
										component="nav"
										style={{ display: 'flex' }}
									>
										{navbarLinks.map((item, i) => (
											<Link
												to={`/${ALL_LINKS[selectedNavHeader].baseUrl}/${item.link}`}
												key={item.text}
												style={{
													textDecoration:
														selectedNavHeader === selectedNavItem.header &&
														selectedNavItem.item === i
															? 'underline'
															: 'none',
													color: 'white',
												}}
												onClick={() => {
													setSelectedNavItem(() => {
														return { header: selectedNavHeader, item: i };
													});
												}}
											>
												<ListItemButton
													selected={
														selectedNavHeader === selectedNavItem.header &&
														selectedNavItem.item === i
													}
												>
													<ListItemText primary={t(item.text)} />
												</ListItemButton>
											</Link>
										))}
									</List>
								</Fade>
							</Toolbar>
						</Box>
					</AppBar>
				</HideOnScroll>
			</>
		);
	}
	return null;
}

export default TopBar;
