import { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Keycloak from 'keycloak-js';
import Splash from './components/pages/Splash';
import TopBar from './components/common/TopBar';
import PageError from './components/pages/PageError';
import { ClinicsContextProvider } from './contexts/Providers';
import {
	PatientQueue,
	PatientNumber,
	PatientVisit,
	Economy,
	PatientSurvey,
	StaffWork,
	PatientRisk,
	PatientRecall,
	StaffWorkClinic,
	PatientProcedure,
	PatientFrisktandvard,
	StaffIncome,
	StaffSTV,
	Scheduler,
	SchedulerQueue,
	SchedulerActivities,
	Referral,
} from './components/pages/Pages';
import {
	ApolloClient,
	ApolloProvider,
	NormalizedCacheObject,
} from '@apollo/client';
import AxiosConfiguration from './AxiosConfiguration';
import { ApolloConfig } from './ApolloConfig';
import PostOp from './components/pages/Simulation';

function App() {
	const [authenticated, setAuthenticated] = useState(false);
	const [keycloak, setKeycloak] = useState<
		Keycloak.KeycloakInstance | undefined
	>(undefined);
	const [apolloClient, setApolloClient] = useState<
		ApolloClient<NormalizedCacheObject> | undefined
	>(undefined);

	useEffect(() => {
		const kc = Keycloak('/keycloak.json');
		kc.init({
			onLoad: 'login-required',
			checkLoginIframe: false,
		}).then((auth) => {
			setAuthenticated(auth);
			setKeycloak(kc);
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<>
			{keycloak !== undefined && authenticated && (
				<Router>
					<ApolloConfig
						keycloak={keycloak}
						setApolloClient={(client) => setApolloClient(client)}
					/>
					{apolloClient !== undefined && (
						<ApolloProvider client={apolloClient}>
							<ClinicsContextProvider>
								<AxiosConfiguration keycloak={keycloak} />
								<Routes>
									<Route path="*" element={<PageError />}>
										<Route path=":code" element={<PageError />} />
									</Route>
									<Route path="/" element={<Splash />} />
									<Route path="/begin" element={<Splash />} />

									{/* Patient */}
									<Route path="/patient/patient" element={<PatientNumber />} />
									<Route path="/patient/visit" element={<PatientVisit />} />
									<Route path="/patient/recall" element={<PatientRecall />} />
									<Route
										path="/patient/queue-patient"
										element={<PatientQueue />}
									/>
									<Route path="/patient/survey" element={<PatientSurvey />} />
									<Route path="/patient/risk" element={<PatientRisk />} />
									<Route path="/patient/care" element={<PatientProcedure />} />
									<Route
										path="/patient/frisktandvard"
										element={<PatientFrisktandvard />}
									/>

									{/* Staff */}
									<Route path="/staff/worktime" element={<StaffWork />} />
									<Route path="/staff/schedule" element={<StaffWorkClinic />} />
									<Route path="/staff/STV" element={<StaffSTV />} />
									<Route path="/economy/income" element={<StaffIncome />} />

									{/* Economy */}
									<Route path="/economy" element={<Economy />} />

									{/* Scheduler */}
									<Route path="/planning/scheduler" element={<Scheduler />} />
									<Route
										path="/planning/scheduler-queue"
										element={<SchedulerQueue />}
									/>
									<Route
										path="/planning/scheduler-activities"
										element={<SchedulerActivities />}
									/>
									<Route
										path="/planning/simulation"
										element={<PostOp translationBase="" />}
									/>
									<Route path="/planning/referral" element={<Referral />} />
								</Routes>
								<TopBar keycloak={keycloak} />
							</ClinicsContextProvider>
						</ApolloProvider>
					)}
				</Router>
			)}
		</>
	);
}

export default App;
